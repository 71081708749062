.broadcast-modal {
  .ant-modal-content {
    height: 90vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.template-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.template-header-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
  flex-shrink: 0;
}

.whatsapp-account-select {
  width: 100%;
}

.chat-template-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  overflow-y: auto;
  flex-grow: 1;
  padding-right: 8px;

  // Custom scrollbar
  scrollbar-width: thin;
  scrollbar-color: #d1d1d1 #f1f1f1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }
}

.template-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 400px;

  .template-header,
  .template-footer {
    background-color: #fff;
  }

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-color: #1890ff;
  }

  &.selected {
    border-color: #52c41a;
    background-color: #f6ffed;
  }
}

.template-header {
  padding: 10px;
  background-color: #f5f5f5;
  flex-shrink: 0;

  h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .template-status {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 6px;

    span {
      font-size: 11px;
      color: #666;
    }
  }
}

.template-message-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.template-message-body {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  font-size: 12px;
}

.template-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  flex-shrink: 0;

  p {
    margin: 0;
    font-size: 11px;
    color: #666;
  }
}

.template-bottom-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  flex-shrink: 0;

  .selected-count {
    font-size: 14px;
    color: #666;
  }

  .action-buttons {
    display: flex;
    gap: 12px;

    .reset-button {
      color: #ff4d4f;
      border-color: #ff4d4f;
    }

    .continue-button {
      background-color: #1890ff;
      border-color: #1890ff;
    }
  }
}

// Responsive adjustments
@media (max-width: 1800px) {
  .chat-template-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1200px) {
  .chat-template-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .chat-template-list {
    grid-template-columns: 1fr;
  }
}
