.react-multi-email [data-tag] {
    line-height: 1;
    vertical-align: baseline;
    margin: 0.14285714em;
    background-color: #fff;
    border: 1px solid #dadce0;
    background-image: none;
    // padding: 3px;
    padding-left: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    border-radius: 30px !important;
    font-weight: 400 !important;
    border-radius: 0.28571429rem;
    transition: background 0.1s ease;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}
.react-multi-email [data-tag] [data-tag-handle]{
    margin-left: 0px !important;
}
.react-multi-email.focused{
    background-color: transparent !important;
    box-shadow: none !important;
   
}
.react-multi-email > input{
    padding: 0!important;
    &:focus{
        box-shadow: none;
    }
}

.react-multi-email > span[data-placeholder]{
    padding: 0!important;
    color: #cfcfcf!important;
    font-size: 13px!important;
    margin-bottom: 4px;
    font-weight: 300!important;
    line-height: 22px;
}
.react_mail_close_icon{
    margin-left: 15px !important;

}
.profile_circle_email{
    background-color: #2d5743;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
}
