.task_detail_left-container {
  flex-grow: 1;
  width: 25%;
  border-right: 1px solid #dadce0;
}
.task_detail_left-header_section {
  height: 80px;
  border-bottom: 1px solid #efefef;
  // background: transparent linear-gradient(84deg, #005ba6 0%, #6efaec 100%) 0% 0%
  // no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.task_detail_left-list{
  height: calc(100vh - 64px - 80px);
  overflow: auto;
}
.task_detail_left-list-update-section{
  height: calc(100vh - 64px - 80px - 56px);
  overflow: auto;
}
.task_detail_left-list_section {
  // padding: 10px;
  border-bottom: 1px solid #efefef;
  &:hover {
    background-color:  rgba(var(--primary-color), 0.06);
  }
  &__active {
    // padding: 10px 15px;
    background-color: rgba(var(--primary-color), 0.06);
    border-left: 4px solid rgba(var(--primary-color), 1);
  }
}
.task-line-through{
  text-decoration: line-through;
  text-decoration-color: #000;
  text-decoration-thickness: 0.5px;
}