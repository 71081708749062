.ant-btn-default {
  border-radius: 20px !important;
  white-space: nowrap !important;
  font-family: "Superteams-font", sans-serif !important;
  font-weight: 500;
}
.ant-space-compact {
  .ant-btn-default {
    border-radius: 4px !important;
  }
}
.ant-modal {
  top: 26px !important;
  .ant-modal-content {
    max-height: calc(100vh - 60px) !important;

    .ant-modal-body {
      max-height: calc(100vh - 186px) !important;
      overflow-y: auto;
    }
  }
}
.ant-select {
  .ant-select-selection-placeholder {
    font-size: 14px !important;
  }
}

.ant-btn-sm {
  height: 24px !important;
  padding: 2px 7px !important;
}

.ant-btn-lg {
  padding: 0.5rem 1.4rem !important;
  //   height: 100% !important;
}

.ant-btn-primary {
  border-radius: 20px !important;
  // border: 1px solid var(--theme-border-color) !important;
  // font-size: 14px !important;
  white-space: nowrap !important;
  // background-color: rgba(var(--button-primary), 1) !important;
  // color: var(--white-color) !important;
  font-family: "Superteams-font", sans-serif !important;
}

.ant-btn-dangerous {
  background: #ff4d4f !important;
}

.theme-select-button {
  border-radius: 8px !important;
}

.ant-radio-button-wrapper-checked {
  border: 2px solid;
}

.ant-modal-title {
  font-weight: 500 !important;
  font-size: 17px !important;
}

.users-settings-table {
  .ant-table-body {
    max-height: calc(100vh - 362px) !important;
  }
}

.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    color: #1f1f1f !important;
    // color: #ff4d4f !important;
    font-weight: 500;

    .ant-menu-item-selected {
    }
  }
}

.ant-menu-submenu-title {
  font-size: 0.9rem !important;
}

.ant-menu-item-selected {
  background: #d4e4fc !important;

  .antmenu-sub-selected {
    color: #1f1f1f !important;
    font-weight: 500;
  }
}
:where(.css-dev-only-do-not-override-apn68).ant-menu-inline .ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-menu-inline .ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
}

.company-profile-details-wrapper__navigation {
  .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 26px !important;
    height: 26px !important;
    padding-left: 14px !important;
    font-size: 13px !important;
    color: grey;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .ant-menu-item {
    margin-block: 0px !important;
    // background: #fff !important;
    // padding-left: 14px !important;
  }
}
.ant-dropdown-menu-submenu-title {
  display: flex;
  gap: 8px;
}

.ant-modal-confirm-title,
.ant-list-item-meta-title {
  font-weight: 500 !important;
}
.ant-drawer-header {
  background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
    no-repeat padding-box !important;
  padding: 20px 24px;
}
.ant-drawer-title {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
}

.ant-drawer-close {
  color: #fff !important;
  font-weight: 600 !important;
}

.ant-select-selector {
  border-radius: 4px !important;
}

.ant-btn-compact-last-item {
  &::before {
    display: none !important;
  }
}

.ant-select-required {
  .ant-select-selector {
    border-left: 2px solid red !important;
  }
}

.delete-trash-icon {
  transition: all 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: none;
  outline: none;
  border: none;

  &:hover:not(:disabled) {
    .trash-icon {
      color: #ff4d4f !important;
    }
    background: #fff;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      background: none;
    }
  }
}

// .ant-timeline-item {
//   padding-bottom: 0 !important;
// }

.ant-timeline-item-content {
  min-height: 0 !important;
}

.ant-input-search-button {
  border-radius: 0 4px 4px 0 !important;
}
