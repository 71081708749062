@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.media-library-selected-file {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background: #f2f5f7;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--theme-text-color);
}

.error-text {
  color: $red-color;
  font-size: 12px;
  margin-top: 8px;
}
