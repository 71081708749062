@import "@scss/common/variables";
@import "@scss/common/mixins";
@import "@scss/common/extends";

p.show-rows-count {
  color: #5f6368;
  font-size: 14px;
  margin-right: 16px;
}
select.show-select {
  border-top: 1px solid $white-color;
  border-left: 1px solid $white-color;
  border-right: 1px solid $white-color;
  border-bottom: 1px solid $white-color;
  color: $theme-main-text-color;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 10px;
}
.show-count {
  color: #5f6368;
  font-size: 14px;
}
.pagination {
  justify-content: center;

  li {
    @extend %display-inline-block;

    &:nth-child(1),
    &:nth-child(2),
    &:last-child,
    &:nth-last-child(2) {
      a {
        background-color: transparent;
        font-size: 1rem;
        width: initial;
        height: initial;
        font-weight: $bold-font-weight;
      }

      &:hover {
        a {
          background-color: transparent;
          // color: $theme-secondary-color;
          color: $theme-main-text-active-color !important;
          // color: rgba(var(--primary-color), 1);
        }
      }
    }

    &.active {
      a {
        // background-color: rgba(var(--primary-color), 1) !important;
        color: $theme-main-text-color;
        // background-color: #E8F0FE;
        background-color: #edf4ff;
        border: 1px solid $theme-main-text-color;
        font-size: 14px;
        font-weight: 600;
      }
    }

    a {
      @extend %display-inline-block;
      width: 25px;
      height: 30px;
      text-align: center;
      // background-color: #E8F0FE;
      background-color: #edf4ff;
      margin: 0 5px;
      // border-radius: 50%;
      border-radius: 4px;
      line-height: 30px;
      font-size: 12px;
      // @extend %transition;
    }
  }
}


.pagination li a {
  font-size: 12px !important;
  font-weight: 500;
  color: #5f6368;
  &:not(:last-child) {
    margin-right: 16px;
  }
}
// .pagination li:nth-child(3),
// .pagination li:nth-child(4) {
//   display: none;
// }
// .pagination li.active a {
//   background-color: #276ef1 !important;
//   color: #ffffff;
// }
// .pagination li:nth-child(3) a,
// .pagination li:nth-child(4) a {
//   background-color: $theme-border-color;
//   color: #276ef1;
// }

// .pagination li:nth-child(3):active a,
// .pagination li:nth-child(4):active a {
//   background-color: #276ef1;
//   color: #ffffff;
// }
// .pagination li:nth-child(3):hover a,
// .pagination li:nth-child(4):hover a {
//   background-color: #276ef1;
//   color: #ffffff;
// }
// .pagination li:nth-child(1):hover a,
// .pagination li:nth-child(2):hover a,
// .pagination li:last-child:hover a,
// .pagination li:nth-last-child(2):hover a {
//   background-color: transparent;
// }
