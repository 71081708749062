@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.filter-wrapper {
  padding: 8px 0px;
  // border-top: 1px solid #f7f7f7;
  // border-bottom: 1px solid #f7f7f7;
  // margin-top: 10px;
  height: 50px;
  transition: all 500ms ease;
  @media (max-width: $mobile-devices-max-width+px) {
    padding: 8px 12px;
  }
}
.filter-navigation-wrapper {
  @media (max-width: $mobile-devices-max-width+px) {
    display: none !important;
  }
}
.filter-wrapper__add-link {
  background-color: var(--theme-blue-primary-color);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  box-shadow: 0px 3px 6px #00000029;
}
.filter-wrapper__add-link img {
  margin: auto;
}
.filter-nav-wrapper__list {
  margin-left: 16px;
}
.filter-wrapper__filter_image {
  margin-right: 4px;
}
.filter-wrapper__filter-text {
  color: var(--theme-blue-primary-color);
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
}

.filter-wrapper-dropdown-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid var(--theme-border-color);
  border-radius: $base-border-radius;
  background-color: var(--white-color);
  color: var(--theme-font-terciary-color);
  @include transition(0.3s);
  &:hover {
    border-color: var(--theme-button-primary-color);
    background-color: var(--white-color);
  }
}
.filter-wrapper-dropdown {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: var(--theme-border-color);
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.work-wrapper__days {
  border-right: 1px solid var(--theme-border-color);
  padding-right: 10px;
  margin-right: 24px;
  p {
    min-width: 150px;
    &:not(:last-child) {
      padding-bottom: 10px;
    }
  }
}
.work-wrapper {
  padding: 20px;
}
.work-wrapper.dropdown-items:hover {
  background-color: var(--white-color);
}
.work-wrapper__form-control {
  height: 40px;
  border-radius: $base-border-radius;
  border: 1px solid var(--theme-border-color);
  margin: 10px;
  padding: 10px;
}
.work-wrapper__calander-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

//shift-tab
.shift-wrapper__down-arrow {
  position: relative;
  margin-bottom: 4px;
}
.shift-wrapper__button {
  height: 40px;
  line-height: 40px;
  border-radius: 4px 0 0 4px;
  background-color: var(--white-color);
  padding: 0 16px;
  border: 1px solid var(--theme-border-color);
  @include transition(0.3s);
  &:hover {
    border-color: var(--theme-button-primary-color);
  }
}
.shift-wrapper__button--right {
  border-radius: 0px 4px 4px 0px;
}
.shift-wrapper__calender-button {
  height: 40px;
  line-height: 40px;
  background-color: var(--white-color);
  padding: 0 16px;
  border: 1px solid var(--theme-border-color);
  padding: 0 16px;
  border-radius: $base-border-radius;
  margin-left: 32px;
}
.shift-wrapper__list-items {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -16px;
    height: 100%;
    width: 1px;
    background-color: var(--theme-border-color);
  }
}

.create-user-button {
  background-color: rgba(var(--button-primary), 1);
  color: var(--white-color);
  padding: 0px 16px;
  // height: 38px;
  // font-size: 15px;
  border: 1px solid transparent;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-weight: $medium-font-weight;
  &--department {
    padding: 4px 16px;
  }
}
.add-image {
  margin-right: 4px;
}

.filter-wrapper__status {
  margin-left: 24px;
  color: var(--theme-grey-color);
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 15px;
  border: 1px solid var(--theme-border-color);
  border-radius: $base-border-radius;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  @include genearteRemFontSize(16);
  &:hover {
    border-color: var(--theme-button-primary-color);
    color: var(--$theme-button-primary-color);
  }
}
.filter_module {
  display: flex;
  // justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
  // min-width: 150px;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  padding: 5px;
  h2 {
    font-size: 14px;
    font-weight: 800;
    color: #1f618d;
  }
  img {
    // margin-left: 6px;
  }
  &:hover {
    // background-color: #f2f3ff;
  }
}
.filter-wrapper_image_container {
  height: 34px;
  width: 34px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.filter-wrapper_image_container:hover {
  background-color: #e8f0fe;
  border-radius: 50%;
  transition: 500ms ease;
}

.filter-wrapper_image {
  height: 22px;
  width: 22px;
}
.filter-wrapper_text_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f6ff;
  padding: 8px;
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
  .filter-wrapper_text {
    font-size: 14px;
  }
  .filter-wrapper_textimage {
    height: 18px;
    cursor: pointer;
  }
}

.filter-wrapper_header_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a73e8;
  padding: 8px;
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
  .filter-wrapper_text {
    font-size: 14px;
    color: white;
    font-weight: 500;
  }
  .filter-wrapper_textimage {
    height: 18px;
    cursor: pointer;
  }
  &:hover {
    background-color: #005eda;
    transition: all 500ms ease;
  }
}
.upload-image-container {
  .upload-image {
    height: 28px;
  }
}

.absolute-label {
  background-color: white;
  position: absolute;
  top: -7px;
  left: 14px;
  font-size: 12px;
  z-index: 1;
}

.kanban_button{
  background: none;
  border: none;
  outline: none;
}