.template_container {
  border-bottom: 1px solid #efefef !important;
}
.template_container_section {
  &__1 {
    width: 15%;
  }
  &__2 {
    width: 85%;
  }
}
.template_input {
  border: none;
  height: 40px;
  font-size: 14px;
  padding-left: 10px;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &::placeholder {
    font-size: 14px;
  }
}
