.optout_container {
  padding: 20px 20px 0 20px;
  background-color: #f9f9f9 !important;
  width: 100%;
  // height: calc(100vh - 170px);
  // height: calc(100vh - 120px);
  // max-height: calc(100vh - 120px);
  overflow: auto;

  .optout-campaign_container {
    padding: 20px;
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 20px;
    .keywords_container {
    }
  }
}
.chat-settings-modal {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-tabs {
    margin-top: 16px;
  }

  .rules-container {
    padding: 16px;

    .section {
      margin-bottom: 24px;

      h3 {
        font-size: 16px;
        margin-bottom: 16px;
        color: #1f2937;
      }

      h4 {
        font-size: 14px;
        margin-bottom: 12px;
        color: #374151;
      }
    }

    .creation-rules,
    .managed-by,
    .assignment-rules {
      background: #f9fafb;
      border-radius: 8px;

      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .creation-rules,
    .assignment-rules {
      padding: 16px 24px 24px 24px;
    }

    .note {
      display: block;
      margin-top: 8px;
      color: #6b7280;
      font-size: 12px;
      font-style: italic;
    }
  }

  .agents-list {
    padding: 16px;
    max-height: calc(100vh - 400px);
    overflow-y: auto;

    .ant-list-item {
      padding: 16px;
      border-radius: 8px;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f9fafb;
      }
    }

    .status {
      display: inline-block;
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 500;

      &.online {
        background-color: #dcfce7;
        color: #166534;
      }

      &.offline {
        background-color: #fee2e2;
        color: #991b1b;
      }

      &.away {
        background-color: #fef3c7;
        color: #92400e;
      }
    }
  }

  .cancel__button {
    border-color: #d1d5db;

    &:hover {
      border-color: #9ca3af;
      color: #374151;
    }
  }

  .submit__button {
    background-color: #2563eb;

    &:hover {
      background-color: #1d4ed8;
    }
  }
}

.rules-container {
  .section {
    margin-bottom: 24px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
      color: #1f2937;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 12px;
      color: #374151;
    }
  }

  .creation-rules {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .managed-by {
    .note {
      display: block;
      margin-top: 8px;
      color: #6b7280;
      font-size: 14px;
    }
  }

  .assignment-rules {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
