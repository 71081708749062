$primary-color: #00a884;
$border-color: #e5e7eb;
$text-color: #374151;
$bg-color: #ffffff;

.editor {
  max-width: 100%;
  margin: 0 auto;
  background: $bg-color;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

  &__header {
    background: $primary-color;
    color: white;
    padding: 1rem;
    border-radius: 0.5rem 0.5rem 0 0;

    h1 {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
    }
  }

  &__toolbar {
    border-bottom: 1px solid $border-color;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__emoji {
    position: relative;

    .emoji-button {
      padding: 0.5rem;
      border-radius: 0.375rem;
      transition: background-color 0.2s;
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: $text-color;
      }
    }

    .emoji-picker {
      position: absolute;
      z-index: 50;
      // top: 40px;
      bottom: 0px;
      left: 0;
    }
  }

  &__content {
    padding: 1rem;

    textarea {
      width: 100%;
      // height: 18rem;
      padding: 0.75rem 2rem 0.75rem 0.75rem;
      border: 1px solid $border-color;
      border-radius: 0.375rem;
      resize: none;
      outline: none;
      font-size: 1rem;
      line-height: 1.5;

      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
      }
    }

    .editor-textarea-error {
      &:focus {
        border-color: red;
        box-shadow: 0 0 0 2px #f8efef;
      }
    }
  }

  &__preview {
    padding: 1rem;
    border-top: 1px solid $border-color;

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: $text-color;
    }

    .preview-content {
      white-space: pre-wrap;
      padding: 0.75rem;
      background: #f9fafb;
      border-radius: 0.375rem;
      min-height: 5rem;
    }
  }

  &__actions {
    padding: 1rem;
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: flex-end;

    .send-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 0.375rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: darken($primary-color, 5%);
      }

      &:disabled {
        background-color: #9ca3af;
        cursor: not-allowed;
      }

      .send-icon {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  &__guide {
    padding: 1rem;
    background: #f9fafb;
    border-radius: 0 0 0.5rem 0.5rem;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: #4b5563;
      margin-bottom: 0.5rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 0.875rem;
      color: #6b7280;

      li {
        margin-bottom: 0.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.editor textarea {
  caret-color: auto;
  transition: none;
}

.editor__content {
  position: relative;
}

/* Ensure smooth scrolling */
.editor textarea {
  scroll-behavior: auto;
}