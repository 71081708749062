@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.tabs-padding {
  padding: 16px 0 0 16px;
  border-bottom: 1px solid var(--theme-border-color);
}

.add-overflow-y {
  overflow-y: auto !important;
}

//users
.organization-text {
  color: var(--theme-grey-color);
  padding: 24px;
  @include genearteRemFontSize(16);
}

.crm-wrapper {
  width: calc(100vw - var(--global-child-width));
}
.manageFieldRight {
  max-height: calc(100vh - 104px);
  height: calc(100vh - 104px);
  overflow-y: hidden;
}
.ticketsWrapper {
  max-height: calc(100vh - 104px);
  height: calc(100vh - 104px);
  overflow: hidden;
  width: calc(100vw - 64px);
}

//stepper css
:root {
  --circle-size: clamp(1rem, 5vw, 1.2rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}
.c-stepper {
  margin-top: 30px;
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: lightgrey;
    opacity: 0.5;
    margin: 0 auto 1rem;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
      height: 2px;
      background-color: #e0e0e0;
      order: -1;
    }
  }
}

.c-stepper__item_active {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: #30c384;
    opacity: 0.5;
    margin: 0 auto 1rem;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
      height: 2px;
      background-color: #e0e0e0;
      order: -1;
    }
  }
}

.c-stepper__title {
  // font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.c-stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

/*** Non-demo CSS ***/

.wrapper {
  max-width: 100%;
  margin: 2rem auto 0;
}

.table_footer_container {
  width: 100%;
  height: 46px;
  // z-index: 1;
  bottom: 0;
  right: 0;
  // background-color: #f5f8fa !important;
  // background-color: red;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: fixed;
  position: sticky;
}
