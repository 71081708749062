@import '@scss/common/_variables.scss';

.footer-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: ($mobile-devices-max-width+px)) {
    margin-top: 30px;
    position: relative;
  }
}
