@import "../normalize.scss";
@import "../extends.scss";
@import "../mixins.scss";
@import "../variables.scss";

.react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: 1px solid #efefef !important;
  border-top-left-radius: 0.3rem;
  padding: 10px 0;
  position: relative;
}
.react-datepicker__day-names {
  margin-bottom: -8px;
  padding: 10px !important;
  width: 250px !important;
  // width: 300px !important;
}
.react-datepicker__current-month,
// .react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  /* color: #000; */
  font-weight: bold;
  font-size: 0.944rem;
  margin-top: 10px !important;
  display: none !important; //selected month,year date//February 2024
}
.react-datepicker__navigation--next {
  right: -3px;
  top: 20px !important;
}
.react-datepicker__navigation--previous {
  left: -3px;
  top: 20px !important;
}
.react-datepicker__day--selected {
  /* border-radius: 0.3rem; */
  border-radius: 0 !important;
  background-color: #408dfb !important;
  color: #ffffff !important;
  border: 1px solid #408dfb !important;
  font-weight: 800;
  &:hover {
    color: #000 !important;
  }
}
.react-datepicker__day-name {
  color: rgb(81, 111, 144) !important;
  font-weight: 800;
  font-size: 14px;
}
.react-datepicker__day {
  width: 1.8rem !important;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    border-radius: 0px !important;
    /* background-color: #408dfb !important; */
    color: white;
    outline: 1px solid #408dfb !important;
    background-color: transparent !important;
    color: black;
  }
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  display: none !important;
}
.react-datepicker__navigation-icon::before {
  /* display: none !important; */
}
.react-datepicker__month-read-view {
  margin-top: 10px !important;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: white !important;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 45px !important;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.react-datepicker__year-option {
  height: 28px !important;
  &:hover {
    background-color: #f7faff !important;
  }
}
.react-datepicker__month-option {
  /* padding: 5px; */
  height: 28px !important;
  &:hover {
    background-color: #f7faff !important;
  }
}
/*  */

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid #efefef !important;
  width: 70px;
  padding: 5px;
  text-align: center;
  border-radius: 0.3rem;
  position: relative;
  visibility: visible !important;
}
.react-datepicker__year-select,
.react-datepicker__month-select {
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  margin-top: 8px !important;
  // min-width: 80px;
  padding: 5px;
}

//Inline edit datepicker
.form_control-inline_datepicker {
  background-color: transparent;
  // border: 1px solid lightgray;
  border: 1px solid #efefef;
  padding: 8px;
  border-radius: 2px;
  padding-left: 15px;
  width: auto;
  font-size: 14px;
  font-family: $font-family;
  color: #423f3f;
  &:focus {
    border-radius: 5px;
  }
}

// ------------------- react-datepicker -----------------------------//

.react-datepicker {
  border: 1px solid transparent !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  display: flex;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #efefef !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.datepicker_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-date-picker__inputGroup__input {
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  max-width: 312px;
  min-width: 200px;
  padding: 0 10px;
  width: 100%;
}

.react-date-picker__inputGroup__input_400 {
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  max-width: 312px;
  min-width: 200px;
  padding: 0 10px;
  width: 100%;
  font-size: 14px;
  text-align: center;
}

.react-datepicker-wrapper {
  display: block !important;
  padding: 0;
  border: 0;
}

/* custom-datepicker-styles.css */
.custom-datepicker-wrapper {
  z-index: 9999 !important;
  background-color: transparent !important;
  height: 35px;
  margin-left: 8px;
  border: none;
  border-radius: 5px;
  padding-left: 5px;
  width: 180px;
  font-size: 14px;
  &:focus {
    border-radius: 5px;
    border: none;
    background-color: transparent !important;
  }
  &::placeholder {
    color: #dad8d8;
  }
}

.inline_date_active {
  background-color: #e8f0fe;
  // padding: 5px;
  // padding-left: 10px;
  // padding-right: 10px;
  border-radius: 5px;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.react-datepicker__month-container {
  width: 250px;
}
