.n_container {
  width: 95%;
  border: 1px solid #efefef;
  margin: 0 auto;
  height: calc(100vh - 388px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.nodata_container {
  width: 45%;
  margin: 0 auto;
}
.nodata_container_image {
  height: 170px;
}
.nodata_container_main {
  width: 320px;
}

.nodata_container_header {
  // font-family: 'ProductSans-Medium';
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 16px;
  margin-top: 0;
  display: block;
  line-height: normal;
  text-transform: none;
  font-size: 26px;
}

.nodata_container_desc {
  // font-family: 'ProductSans-Medium';
  font-weight: 400;
  font-size: 16px;
  color: #33475b;
  line-height: 26px;
}
