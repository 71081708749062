.main_button {
  &__secondary {
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid #d0d4df;
    padding: 7px 20px;
    color: #313949;
    box-shadow: inset 0 -1px 0 #d0d4df;
    background: transparent linear-gradient(180deg, #fcfdff 0%, #eaeef5 100%);
    outline: 0;
  }
  &__primary {
    color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 8px 20px;
    box-shadow: inset 0 -2px 0 #0061ca;
    background: transparent linear-gradient(0deg, #0279ff 0%, #00a3f3 100%);
    font-size: 14px;
    border: 0;
    outline: 0;
    cursor: pointer;
    text-align: center;
    margin-right: 8px;
    white-space: nowrap;
    margin-left: 10px;
    &:hover {
      transition: all 100ms ease;
      background: transparent linear-gradient(0deg, #2383f2 0%, #13a8f2 100%);
    }
    &:disabled {
      cursor: not-allowed;
      background: #91cbff !important;
    }
  }
}
