@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.form_wrapper-pipeline {
}
.form_inline_builder_field {
  // width: 250px;
  width: 100%;
  position: relative;
  align-items: center;
  cursor: pointer;
}
.form_inline_builder_field:hover .form_inline_builder_field_hover_image {
  opacity: 1;
}
.form_inline_builder_field_hover_image {
  opacity: 0;
  position: absolute;
  right: 20px;
  height: 18px;
  top: 37px;
  cursor: pointer;
}
