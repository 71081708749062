@import "@scss/common/_variables.scss";

.add-template-content {
  width: 100% !important;
  margin-top: 5rem !important;
  border-radius: 0.8rem;
  min-width: 820px;
  max-width: 1100px;
  height: 92vh;

  .close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
}

.add-template {
  height: 92vh;
}

.broadcast-tabs {
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
}

.attribute-popup-content {
  border-radius: 0.6rem;
  .close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
}

.template-wrapper {
  width: 100%;
  height: calc(100vh - 104px);
  padding: 1rem 0;
  overflow-y: auto;

  .warning-container {
    background: #ff939338;
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
    border-radius: 6px;
    margin-top: 0.6rem;
  }

  .border-bottom {
    border-bottom: 2px solid #ebebeb;
    padding: 0 0 2rem 0;
  }

  .optional-text {
    font-size: 0.8rem;
    color: #878787;
    font-weight: 400;
  }

  .input-with-length {
    position: relative;
    // width: fit-content;
    margin-top: 0.5rem;
  }

  .input-length {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 1rem;
    font-size: 13px;
  }

  .requirement-message {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .custom-upload-filename {
    width: -webkit-fill-available;
  }

  .required-file-format {
    color: var(--theme-blue-primary-color);
  }
  .greyed-description {
    font-size: 0.9rem;
    color: #878787;
  }

  .template-heading {
    font-size: 1.2rem;
    // border-bottom: 2px solid #ebebeb;
    padding-bottom: 0.3rem;
    width: 100%;
  }

  .template-container {
    display: flex;
    justify-content: space-around;
    // justify-content: space-evenly;
    overflow-y: auto;

    input[type="file"]::file-selector-button {
      border-radius: 4px;
      padding: 0 16px;
      height: 40px;
      cursor: pointer;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.16);
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
      margin-right: 16px;
      transition: background-color 200ms;
    }

    /* file upload button hover state */
    input[type="file"]::file-selector-button:hover {
      background-color: #f3f4f6;
    }

    /* file upload button active state */
    input[type="file"]::file-selector-button:active {
      background-color: #e5e7eb;
    }

    .template-form-container {
      // height: calc(100vh - 9.3rem);
      // overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 2rem;
      padding-left: 0.5rem;
      // padding-top: 1rem;
      // margin: 1rem 0;

      .template-editor-wrapper {
        // min-width: 30rem;
        max-width: 40rem;
        width: 100%;
        padding-left: 2rem;
        padding-right: 1rem;
        // border-left: 1px solid #ebebeb;

        .sample--template-text {
          text-align: center;
          max-width: 100%;
          font-size: 0.8rem;
          background: #dbf2f2;
          border-radius: 6px;
          padding: 0.5rem 1rem;
          margin: 1.2rem 0;
          font-weight: 500;
        }

        .market-recomendation {
          padding: 1rem;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          border-radius: 0.3rem;
          margin: 1rem 0;
          border-left: 3px solid #4cc793;
        }

        .custom-template-radio {
          .radio-image {
            width: 2.5rem;
          }

          .top-text-wrapper {
            margin: 20px 0 30px 0;
          }
          .top-text-wrapper h4 {
            font-size: 24px;
            margin-bottom: 10px;
          }
          .top-text-wrapper code {
            font-size: 0.85em;
            background: linear-gradient(90deg, #fce3ec, #ffe8cc);
            color: #ff2200;
            padding: 0.1rem 0.3rem 0.2rem;
            border-radius: 0.2rem;
          }
          .tab-section-wrapper {
            padding: 30px 0;
          }

          .grid-wrapper {
            display: grid;
          }

          $primary-color: #3057d5;
          $transition: 200ms linear;

          label.radio-card {
            cursor: pointer;

            .card-content-wrapper {
              background: #fff;
              border-radius: 5px;
              width: 100%;
              // height: 5rem;
              padding: 15px 15px 10px 15px;
              display: grid;
              box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
              transition: $transition;
            }

            .check-icon {
              width: 14px;
              height: 14px;
              display: inline-block;
              border: solid 2px #e3e3e3;
              border-radius: 50%;
              transition: $transition;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                inset: 0;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 8px;
                background-position: center center;
                transform: scale(1.6);
                transition: $transition;
                opacity: 0;
              }
            }

            input[type="radio"] {
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              &:checked {
                + .card-content-wrapper {
                  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5),
                    0 0 0 2px $primary-color;

                  .check-icon {
                    background: $primary-color;
                    border-color: $primary-color;
                    transform: scale(1.2);
                    &:before {
                      transform: scale(1);
                      opacity: 1;
                    }
                  }
                }
              }

              &:focus {
                + .card-content-wrapper {
                  .check-icon {
                    box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
                    border-color: #3056d5;
                  }
                }
              }
            }

            .card-content {
              display: flex;
              gap: 0.6rem;
              align-items: center;
              // img {
              //   margin-bottom: 10px;
              // }

              .custom-radio-description {
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      .carousal-buttons-wrapper {
        display: flex;

        .carousal-button {
          width: 32%;
        }
      }
    }
  }
}

.mobile-template {
  margin: 0 3rem;
  // width: 25%;
  // overflow: auto;
  // height: calc(100vh - 120px);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: -moz-fit-content;
  height: fit-content;
  background: rgb(238 222 200 / 57%);
  border-radius: 2.6rem;

  .whatsapp-link-border {
    border-radius: 2rem !important;
  }

  .mobile-border {
    width: 19rem;
    // min-height: 37rem;
    overflow-y: hidden;
    border: 8px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 2.6rem;
    // background: #e8e2d8;
    background-image: url(./whatsapp-background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .whatsapp-link-header {
      border-radius: 1rem 1rem 0 0 !important;
    }

    .dark-green-header {
      height: 2rem;
      width: 100%;
      background: #044e46;
      border-radius: 2.4rem 2.4rem 0 0;
    }

    .green-header {
      height: 4rem;
      width: 100%;
      background: #035f55;
    }

    .whatsapp-link-screen {
      min-height: 64vh !important;
    }
    .mobile-screen {
      overflow-y: auto;
      height: 100%;
      min-height: 56vh;
      max-height: 27rem;

      .meta-message {
        text-align: center;
        max-width: 80%;
        font-size: 0.6rem;
        background: #dbf2f2;
        border-radius: 6px;
        padding: 0.3rem;
        margin: 0.8rem 0;
        font-weight: 500;
        color: #878787;
      }

      .message-box {
        background: white;
        min-height: 4rem;
        position: relative;
        border-radius: 10px;
        max-width: 82%;
        margin: 0 0 0 0.5rem;
        width: 100%;
        padding: 0.8rem 0.8rem 0.8rem 0.8rem;
        font-size: 0.8rem;
        transition: all 0.3s ease-in-out;

        .message-time {
          font-size: 0.6rem;
          font-weight: 500;
          color: #686868;
          bottom: 12px;
        }

        .media-container {
          background: #ebebeb;
          border-radius: 6px;
          height: 10rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .dummy-image-icon {
            width: 3rem;
          }
        }

        .main_image {
          width: 100%;
          border-radius: 8px;
          max-height: 10rem;
          object-fit: contain;
        }

        .main_video {
          width: 100%;
          border-radius: 8px;
        }

        .message-title {
          font-weight: 500;
        }

        .link-message-button {
          color: #009cf7;
          font-size: 14px;
          font-weight: 500;
          border-top: 1px solid #ebebeb;
          padding-top: 12px;
          margin-top: 5px;
        }

        .phone-message-button {
          color: #009cf7;
          font-size: 14px;
          font-weight: 500;
          padding-top: 12px;
          margin-top: 5px;
        }
      }

      .bottom-user-message {
        width: 100%;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        gap: 8px;

        .message-create-container {
          background: #fff;
          width: 100%;
          padding: 0 16px;
          border-radius: 20px;
          height: 40px;
          display: flex;
          align-items: center;

          .message-placeholder {
            color: #686868;
            font-size: 14px;
          }
        }

        .whatsapp-send-button {
          background: #05aa41;
          padding: 8px;
          border-radius: 50%;
        }
      }

      .whatsapp-user-message {
        background: #fff;
        max-width: 80%;
        margin: 0 10px 6px 0;
        border-radius: 8px;
        padding: 10px;
      }
    }
  }
}

.template_button {
  flex: 0 0 100%; /* Two columns for screens wider than specified width */
  padding: 0.5rem; /* Adjust as needed */
  background-color: white;
  border-radius: 4px;
  margin-right: 1%;
  margin-top: 1%;
  cursor: pointer;
}

.action_div {
  padding: 0.4rem 0.8rem 0.8rem 0.8rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 2px;

  .grid-full-width {
    grid-column: span 2;
  }

  .cta-border {
    color: #16adee;
  }
}

.action_button_container {
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.PhoneInputInput {
  flex: 1 1;
  /* min-width: 0; */
  /* width: auto; */
  /* height: 40px; */
  border: 1px solid #dadce0;
  border-radius: 4px !important;
  background-color: transparent !important;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
}

.ͼ1 .cm-line {
  background-color: white !important;
  font-size: 16px;
  font-family: $font-family;
}
.add_text {
  color: rgba(var(--primary-color), 1);
  font-size: 16px;
}
.template_main_button_container {
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 12px;
  overflow: hidden;
}
.template_main_button_container_item {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.radio_button_container {
  cursor: pointer;
  padding: 2%;
  :hover {
    background-color: #f6f6f6;
    border-radius: 5px;
  }
}
.template_delete {
  position: absolute;
  right: 10px;
}
.carousel_media_container {
  border: 1px solid #efefef;
  height: 200px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_chat_image {
  height: 100%;
  width: 100%;
  max-height: 250px;
  object-fit: contain;
}

.carousal-image-preview {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 120px;
}

.carousal-video-preview {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 120px;
}

.broadcast-carousel-image {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 90px;
}

.broadcast-carousel-video {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 90px;
}

.carousel-swiper_wrapper {
  margin: 10px;
  max-width: 100%;

  .carousel-swiper-card {
    background: #fff;
    border-radius: 12px;
    padding: 5px;
  }
}
.broadcast-carousel-swiper_wrapper {
  max-width: 238px;

  .carousel-swiper-card {
    background: #fff;
    border-radius: 8px;
    padding: 5px;
  }
}

.custom-button-prev {
  position: absolute;
  top: 50%;
  left: 12px;
  z-index: 99;
  transform: translateY(-50%);
  background: #fff;
  outline: none;
  border: none;
  border-radius: 50%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // width: 36px;
  // height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transform: rotate(180deg);
  }
}

.custom-button-next {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 99;
  transform: translateY(-50%);
  background: #fff;
  outline: none;
  border: none;
  border-radius: 50%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // width: 36px;
  // height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button-prev:disabled,
.custom-button-next:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-broadcast-selector {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center !important;
  max-width: 900px;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 630px;
  }

  .ant-radio-button-wrapper {
    height: 100% !important;
    // border: 2px;
    border-width: 2px;
    width: 304px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    span {
      height: 100% !important;
      display: -webkit-box;
      width: 100%;
    }
  }

  .ant-radio-button-wrapper {
    border-radius: 8px !important;
  }

  :where(.css-dev-only-do-not-override-11lehqq).ant-radio-button-wrapper:not(
      :first-child
    )::before {
    display: none;
  }
}

.wmde-markdown {
  background: #fff !important;
  font-size: 13px !important;
  color: #050505 !important;
  // color: #000 !important;
  font-family: "Superteams-font", sans-serif !important;
  font-weight: 400;
  line-height: 1.4;
  word-break: break-word;

  p {
    font-size: 13px !important;
    color: #050505 !important;
    // color: #000 !important;
    font-family: "Superteams-font", sans-serif !important;
    font-weight: 400;
    line-height: 1.4;
    word-break: break-word;
  }
}

.broadcast_button {
  border-top: 1px solid #e8f0fe;
}

@media (max-width: #{$medium-devices-max + px}) {
  .template-selected-preview {
    display: none;
  }
}

.template-selected-preview {
  height: calc(100vh - 64px - 60px);
}

.template-radio-wrapper,
.template-radio-wrapper-selected {
  // display: grid;
  width: 100%;
  // align-content: space-between;
  background: #edf2f7;
  border-radius: 8px;

  .ant-tag {
    width: fit-content !important;
  }

  .template-message-container {
    background: #edf2f7;
    height: 260px;
    overflow-y: auto;

    .template-message-body {
      position: relative;

      &::before {
        width: 0;
        height: 0;
        border-right: 18px solid #ffffff;
        border-left: 5px solid transparent;
        border-bottom: 22px solid transparent;
        position: absolute;
        top: 0;
        left: -17px;
        content: "";
      }
    }
  }

  p,
  span {
    line-height: 18px !important;
  }
}

.template-radio-wrapper-selected {
  height: fit-content;
  max-height: calc(100vh - 230px);
  width: 230px;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .template-message-container {
    // height: 100% !important;
    height: calc(100vh - 319px) !important;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.contacts-upload-wrapper {
  background: #f7fbff;
  border-radius: 4px;
  padding: 40px 24px;
  display: flex;
  align-self: center;
  justify-content: space-between;
}

.broadcast-carousel-card-container {
  display: flex;
  gap: 6px;
  overflow-x: hidden;
  width: 200px;

  .broadcast-carousel-card {
    width: 160px;
  }
}

.broadcast-stepper-wrapper {
  height: fit-content !important;
}

.broadcast-drag-drop > .ant-upload {
  height: fit-content !important;
}
