.workflow-create-container {
  overflow: auto;
  height: calc(100vh - 120px);
}
.workflow-container {
  width: 90%;
  margin: 0 auto;
}
.workflow_detail_container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: transparent;
  border-radius: 0.4rem;
  //   border: solid 2px #30c384;
  //   box-shadow: 0 0 2px 1px rgba(136, 207, 177, 0.6);
  //   padding: 2rem 2.5rem 1rem;
}

.workflow-checkbox-container {
  border: 1px solid #efefef;
  margin-left: 40px;
  padding: 3px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.criteria {
  width: 100px;
  .criteria-count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border: solid 1px #e5ebef;
    background-image: linear-gradient(to top, #f5f6f8, #fff);
    border-radius: 50%;
    margin-right: 1.5rem;
  }
}
.criteria-fit {
  .criteria-count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border: solid 1px #e5ebef;
    background-image: linear-gradient(to top, #f5f6f8, #fff);
    border-radius: 50%;
  }
}
.condtion_container {
  // height: auto;
  width: 10px !important;
}
.workflow_builder_container {
  width: 300px;
}

.workflow_builder_container-criteria {
  min-width: 240px;
}

.workflow_list_container {
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  border: 1px solid #dadce0;
  border-radius: 5px;
  &:hover {
    background-color: rgba(var(--primary-color), 0.06);
  }
}
.workflow_list_value {
  width: 250px;
}
.step-border {
  &__1 {
    width: 50%;
    background-color: transparent;
    border-radius: 0.4rem;
    border: solid 2px #30c384;
    box-shadow: 0 0 2px 1px rgba(136, 207, 177, 0.6);
    padding: 2%;
  }
  &__2 {
    width: 100%;
    background-color: transparent;
    border-radius: 0.4rem;
    border: solid 2px #30c384;
    box-shadow: 0 0 2px 1px rgba(136, 207, 177, 0.6);
    padding: 2%;
  }
}
.vertical-line {
  width: 2px;
  height: 60px; /* Adjust height of the line based on the boxes */
  background-color: #30c384;
  margin-left: 5%;
}

.actions_list_container {
  border-radius: 5px;
  border: 1px solid #dadce0;
  .action_more_container {
    opacity: 0;
    transition: all 500ms ease;
  }
  &:hover .action_more_container {
    opacity: 1;
    transition: all 500ms ease;
  }
}

.workflow_main_container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 120px);
  .workflow_submain_container {
    width: 100%;
    margin: 0 auto;
  }
}
