.templates-meta-container {
  padding: 20px;
  background-color: #fff;

  .templates-header {
    margin-bottom: 24px;

    .search-section {
      label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
      }

      .fetch-button {
        background: #8b3dff;
        border-color: #8b3dff;

        &:hover {
          background: darken(#8b3dff, 10%);
          border-color: darken(#8b3dff, 10%);
        }
      }
    }
  }

  .templates-content {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    .ant-table {
      .ant-table-thead > tr > th {
        background-color: #fff;
        font-weight: 500;
        padding: 16px;
        border-bottom: 1px solid #f0f0f0;

        &::before {
          display: none; // Remove default border
        }
      }

      .ant-table-tbody > tr > td {
        padding: 16px;
        border-bottom: 1px solid #f0f0f0;
      }

      .ant-table-tbody > tr:hover > td {
        background-color: #fafafa;
      }
    }
  }

  .language-subtitle {
    font-size: 12px;
    color: #8c8c8c;
    margin-top: 4px;

    .language-icon {
      color: #52c41a;
      margin-right: 3px;
    }
  }

  .status-approved {
    color: #52c41a;
    font-weight: 500;
  }

  .copy-button {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 4px 15px;
    color: #000;
    background: #fff;

    &:hover {
      background: #fff !important;
      color: #8b3dff !important;
      border-color: #8b3dff !important;
    }
  }

  .info-icon {
    color: #8c8c8c;
    margin-left: 4px;
    font-size: 14px;
  }

  // Pagination styles
  .ant-pagination {
    margin: 16px 0;

    .ant-pagination-item-active {
      border-color: #8b3dff;

      a {
        color: #8b3dff;
      }
    }
  }

  // Table sorting icons
  .ant-table-column-sorter {
    color: #8c8c8c;
  }
}

.copy-button-disabled {
  background: #fbfbfb;
  border-color: #f0f0f0;
  color: #8c8c8c;
}
  