@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.organisations-wrapper {
  // max-width: calc(100% - 257px);
  width: 100%;
  max-height: calc(100vh - 116px);
  height: calc(100vh - 116px);
  overflow-y: auto;
}

.organisations-wrapper__main-section {
  // max-width: 753px;
  // margin: 54px auto;
}

.create-user-form-body__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.create-user-form-body__label {
  font-size: 14px;
}

.create-user-form-body__roles-wrapper {
  margin-top: 8px;
}

.create-user-form-body__roles-wrapper-title {
  margin-bottom: 8px;
}

.create-user-form-body__add-roles-list-cta {
  color: var(--theme-button-primary-color);
  font-weight: $medium-font-weight;
  margin: 12px 0 24px;
  img {
    margin-right: 8px;
  }
}

.create-user-form-body__show-more-roles {
  color: var(--theme-blue-color);
  font-weight: $medium-font-weight;
}

.create-org-loader-wrapper {
  height: calc(100vh - 64px);

  // .create-org-loader-image{
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
}

.create-org-loader-box {
  border: 2px solid #e9e9e9;
  padding: 20px 60px;
  border-radius: 12px;
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    color: #7f939a;
    font-weight: 500;
  }

  p {
    font-weight: 500;
  }

  .dummy-dots-wrapper{
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;

    div:nth-child(3){
      background: #4CAF50;
    }
    .dots{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #d8d8d8;
    }
  }
}
