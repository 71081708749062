.chat-overview-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  align-content: baseline;
}

.team-chart-container {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
}

.team-chart-container.maximized {
  padding: 30px;
}

.team-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.title-icon {
  color: #6b7280;
}

.header-text {
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.team-source-select .ant-select-selector {
  background: #f9fafb !important;
  border-radius: 8px !important;
  border: 1px solid #e5e7eb !important;
  height: 36px !important;
  display: flex;
  align-items: center;
}

.team-source-select .ant-select-selection-item {
  font-size: 14px;
  color: #1f2937;
}

.team-chart-wrapper {
  height: 300px;
  position: relative;
}

@media (max-width: 768px) {
  .team-chart-container {
    padding: 15px;
  }

  .team-chart-wrapper {
    height: 250px;
  }
}
