@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

//fields details

.module-tab-wrapper__filter {
  border: 1px solid #f7f7f7;
  padding: 9px 24px;
  border-radius: $base-border-radius;
  margin-top: 24px;
}

.module-tab-wrapper__text {
  color: var(--theme-grey-color);
  @include genearteRemFontSize(14);
}
.module-tab-wrapper {
  padding: 24px 24px 0;
}

.manageFieldRight {
  max-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
  // overflow-y: scroll;
  // border-left: 1px solid $theme-border-color;
}
.manageFieldRightSettings {
  max-width: calc(100vw - 84px - 231px);
  max-height: calc(100vh - 64px);
  height: calc(100vh - 64px);
  width: 100%;
}
.manageFieldRight__groupbooking {
  max-height: calc(100vh - 160px);
  height: calc(100vh - 160px);
  overflow: auto;
}
.box {
  height: 15px;
  width: 15px;
  &--yellow {
    background-color: #f9bb2d;
  }
  &--blue {
    background-color: #01579b;
  }
  &--light-blue {
    background-color: #1a73e8;
  }
  &--cyan {
    background-color: #21c9af;
  }
  &--red {
    background-color: #fc541f;
  }
}

.status-container {
  border: 1px solid $theme-border-color;
  margin-bottom: 16px;
  border-radius: 5px;
}
.status-container__border {
  border-bottom: 1px solid $theme-border-color;
}
.status-container__code {
  padding: 20px;
  // border-left: 1px solid $theme-border-color;

  .status-edit-button {
    display: none;
  }
  &:hover {
    .status-edit-button {
      display: block;
    }
  }
}
// span {
//   color: #1F1F1F;
// }
.status-title {
  margin-bottom: 8px;
}
.hr-line {
  margin-top: 8px;
  border: 0.5px solid #dadce0;
  margin-bottom: 8px;
}
.stage-input {
  height: 25px;
  border: none !important;
  font-size: 16px;
  color: #1a73e8;
  // width: 400px;
  width: 450px;
  &:focus {
    background-color: transparent;
    box-shadow: none !important;
  }
}
.stage-save-icon {
  background: transparent;
  height: 41px;
  border: 1px solid #1a73e8;
  width: 91px;
  color: #1a73e8;
  border-radius: 5px;
  transition: all 0.5s ease;
  &:hover {
    background-color: #1a73e8;
    color: white;
  }
}
.main_container {
}

.main_container_list {
  height: calc(100vh - 124px);
  overflow: auto;
}
