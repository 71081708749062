.tickets-card-middle-wrapper,
.tickets-table-middle-wrapper {
  padding: 10px 0px 10px 0px;
  width: calc(100vw - 280px - 64px);

  .ticket-card-wrapper {
    height: calc(100vh - 164px);
    overflow: auto;
    // padding: 4px 20px;
    align-content: baseline;
    width: calc(100vw - var(--global-child-width) - 280px);
  }
}

.tickets-sidebar-collapsed {
  .tickets-card-middle-wrapper,
  .ticket-card-wrapper {
    width: calc(100vw - 64px) !important;
  }
}

.tickets-card-middle-wrapper {
  background: #f7fbff;
}

.tickets-table-middle-wrapper {
  height: calc(100vh - 124px);
  overflow: hidden;
}
