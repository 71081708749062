// Variables
$color-primary: #2563eb;
$color-secondary: #6b7280;
$color-success: #10b981;
$color-warning: #f59e0b;
$color-danger: #ef4444;
$color-background: #f9fafb;
$color-white: #ffffff;
$color-gray-100: #f3f4f6;
$color-gray-200: #e5e7eb;
$color-gray-300: #d1d5db;
$color-gray-400: #9ca3af;
$color-gray-500: #6b7280;
$color-gray-600: #4b5563;
$color-gray-700: #374151;

$border-radius: 8px;
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Base styles
.ticket-board {
  height: calc(100vh - 168px);
  overflow-y: hidden;
  background-color: $color-background;
  // padding: $spacing-md;

  &__columns {
    display: flex;
    gap: $spacing-md;
    overflow-x: auto;
    padding: $spacing-md $spacing-sm;
    height: calc(100vh - 170px);

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

// Column styles
.ticket-column {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  background-color: $color-white;
  border-radius: $border-radius;
  padding: $spacing-md;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &__header {
    @include flex-between;
    margin-bottom: $spacing-md;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: $spacing-sm;
    font-weight: 600;
  }

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    &--open {
      background-color: $color-primary;
    }
    &--waiting {
      background-color: $color-warning;
    }
    &--customer {
      background-color: $color-success;
    }
  }

  &__count {
    background-color: $color-gray-100;
    padding: 2px $spacing-sm;
    border-radius: 12px;
    font-size: 0.875rem;
    color: $color-gray-600;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
    min-height: 100px; // Ensure there's always space to drop items
  }
}

// Ticket styles
.ticket {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius;
  padding: $spacing-md;
  transition: transform 0.2s ease, background-color 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    background-color: #f3f4f6;
  }

  &__header {
    @include flex-between;
    margin-bottom: $spacing-md;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: $spacing-sm;
  }

  &__avatar {
    @include flex-center;
    width: 32px;
    height: 32px;
    background-color: $color-primary;
    color: $color-white;
    border-radius: 50%;
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
  }

  &__user-name {
    font-weight: 600;
    color: $color-gray-700;
  }

  &__id {
    font-size: 0.875rem;
    color: $color-gray-500;
  }

  &__content {
    margin-bottom: $spacing-md;
  }

  &__title {
    font-weight: 600;
    color: $color-gray-700;
    margin-bottom: $spacing-xs;
  }

  &__description {
    font-size: 0.875rem;
    color: $color-gray-600;
    line-height: 1.5;
  }

  &__footer {
    @include flex-between;
    padding-top: $spacing-md;
    border-top: 1px solid $color-gray-200;
  }

  &__meta {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
    font-size: 0.875rem;
    color: $color-gray-500;

    &-icon {
      width: 16px;
      height: 16px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $spacing-sm;
  }

  &__action-icon {
    width: 16px;
    height: 16px;
    color: $color-gray-400;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: $color-gray-600;
    }
  }
}

// Button styles
.icon-button {
  @include flex-center;
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  border-radius: $border-radius;
  cursor: pointer;
  color: $color-gray-500;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: $color-gray-100;
    color: $color-gray-700;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

// Add these styles for drag and drop visual feedback
.react-beautiful-dnd-drag-placeholder {
  opacity: 0.5;
}

.react-beautiful-dnd-dragging {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ticket-column__empty {
  color: $color-secondary;
  font-size: 14px;
  text-align: center;
  z-index: 0;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 240px;
}
