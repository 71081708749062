.create_form_container {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #dadce0;
}
.form_leftcontainer {
  width: 32%;
  border-right: 1px solid #dadce0;
}
.form_rightcontainer {
  width: 68%;
  background-color: #eaeef2;
}
.web-form_header {
  height: 60px;
  border-bottom: 1px solid #dadce0;
  position: sticky;
  top: 0;
  background-color: white;
}
.web-form_field_container {
  height: calc(100vh - 126px);
  overflow: auto;
  padding: 0 5px 5px 5px;

  .form-search-container {
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
  }
}
.draggable-wrapper {
  &:hover {
    .settings-container {
      visibility: visible;
    }
  }

  .settings-container {
    visibility: hidden;
  }
}
.web-form_left_container {
  cursor: pointer;
  border: 1px solid #efefef;
  width: 100%;
  padding: 18px;
  border-radius: 5px;
  margin-bottom: 5px;
  &:hover {
    background-color: #efefef;

    .web-form_absolute {
      display: block;
    }
  }

  .web-form_absolute {
    position: absolute;
    right: 15px;
    display: none;
  }
}

.editable_form_contaner {
  // width: 80%;
  max-width: 700px;
  margin: 0 auto;
  padding: 15px;
  min-height: 70vh;
  background-color: white;
  border-radius: 5px;
}

.form_dropcontainer {
}

//----------------------------------------> info-popup css

.form-modal {
  max-width: 650px !important;
}

.choose-form-type-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .choose-form-type {
    border: 1px solid #e5ebef;
    flex: 1 1 0;
    border-radius: 6px;
    transition: all 0.2 ease-in;

    &:hover {
      box-shadow: 0px 0px 8px #0000001a;
    }

    .choose-form-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        width: 100%;
        margin-top: 30px;
        max-width: 50px;
        // max-height: 35px;
        height: 100%;
      }

      span {
        font-size: 1rem;
        color: #212129;
        margin: 20px 0;
        font-weight: 500;
      }

      p {
        font-size: 0.8rem;
        color: #515159;
        padding: 0 30px;
        text-align: center;
      }

      button {
        background-color: #23ae73;
        background-image: linear-gradient(to top, #11995f, #11995f 1%, #27b679);
        white-space: nowrap;
        color: #fff;
        font-weight: 500;
        font-size: 0.9rem;
        border-radius: 20px;
        padding: 7px 15px;
        border: none;
        outline: none;
        margin: 30px 0;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-image: linear-gradient(
            to top,
            #0e9159,
            #0e9159 1%,
            #0e9159
          );
        }
      }
    }

    .choose-form-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fbfcfd;
      border-top: 1px solid #f0f5f8;
      border-radius: 0 0 6px 6px;

      a {
        font-size: 0.8rem;
        color: #1980d8;
        font-weight: 500;
        padding: 11px 0;
      }
    }
  }
}

.responsive-devices {
  background: none !important;
  outline: none !important;
  border: none !important;
  width: 26px;

  img {
    width: 100%;
  }
}

.preview-b-radius {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.responsive-animation {
  transition: all 0.3s ease-in-out;
}

.grayed-background {
  // background: #eaeef2;
  background-color: #f6f6f6;
}

.form-details-width {
  width: 16%;
}

.form-details-container {
  box-shadow: 0 1px 2px #00000033;

  .form-details-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 12px;
    column-gap: 30px;
    width: 60%;
    // align-items: center;
  }
}

.form-social-share {
  background: none;
  outline: none;
  border: none;
  width: 40px;

  img {
    width: 100%;
  }
}

.qr-border-left {
  border-left: 1px solid #dae0e4;
}
