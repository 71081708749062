@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.canned-table-wrapper {
  border-left: 1px solid var(--theme-border-color);
}

.canned-folder-wrapper {
  .canned-folder-top-section {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .canned-folder-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: calc(100vh - 64px);
    overflow-y: auto;

    .canned-new-folder {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: rgba(var(--button-primary), 1);
      }
    }

    .canned-folder {
      width: 100%;
      border-radius: 4px;
      height: 60px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      padding: 10px 54px 10px 10px;
      position: relative;
      &:hover {
        background: #e5f2fd;

        .canned-folder-edit {
          display: flex;
        }
      }

      a {
        color: #212024 !important;
      }

      .canned-folder-edit {
        justify-content: center;
        align-items: center;
        gap: 6px;
        position: absolute;
        right: 0;
        padding: 0 8px;
        // background: #fff;
        height: -webkit-fill-available;
        transition: all 0.3s ease-in-out;
        display: none;
      }
    }

    .canned-folder-selected {
      background: #e5f2fd;
    }
  }
}

.canned-editor {
  min-height: 200px;
}
.canned-attachment {
  .ant-alert-action {
    padding-left: 6px;
    color: #5a5a5a;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #212024;
    }
  }
}

.edit-canned-page {
  width: 100%;
  // height: calc(100vh - 116px);
  overflow-y: auto;
}
