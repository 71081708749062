.chat-wrapper,
.settings-chat-wrapper {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .chat-container,
  .settings-chat-container {
    width: 100%;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .chat-header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid #eee;
      margin: 0;
      gap: 4px;
      width: 100%;

      h1 {
        font-size: 1.25rem;
        font-weight: 500;
      }

      .refresh-button {
        background: none;
        border: none;
        color: #666;
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 50%;
        transition: background-color 0.2s;

        &:hover {
          background: #f5f5f5;
        }
      }
    }

    .messages-container {
      flex: 1;
      overflow-y: auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 90%;
      .message {
        max-width: 80%;
        padding: 0.75rem 1rem;
        border-radius: 1rem;
        font-size: 0.95rem;
        line-height: 1.4;

        &.bot {
          background: #f0f0f0;
          color: #000;
          align-self: flex-start;
          border-bottom-left-radius: 0;
        }

        &.user {
          background: #4285f4;
          color: white;
          align-self: flex-end;
          border-bottom-right-radius: 0;
        }
      }
    }

    .input-container {
      padding: 1rem;
      border-top: 1px solid #eee;
      display: flex;
      gap: 0.5rem;
      width: 90%;
      align-items: center;

      input {
        flex: 1;
        padding: 0.75rem;
        border: 1px solid #eee;
        border-radius: 0.5rem;
        font-size: 0.95rem;

        &:focus {
          outline: none;
          border-color: #4285f4;
        }
      }
    }

    .chat-footer {
      padding: 0 0.75rem 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      color: #666;
      font-size: 0.9rem;
      // border-top: 1px solid #eee;

      img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
      }
    }
  }

  .widget-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: #000;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.chat-wrapper {
  position: relative;
  min-height: 100vh;

  .chat-container {
    height: 90vh;
  }
}

.settings-chat-wrapper {
  height: fit-content;
  position: sticky;
  border-radius: 1rem;
  width: 400px;
  min-width: 400px;
  align-items: center;
  top: 24px;

  .settings-chat-container {
    height: 70vh;
    align-items: center;
  }
}
