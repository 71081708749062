@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
//custom-checkbox
// .custom-checkbox {
//   border: 1px solid $theme-border-color;
//   height: 18px;
//   width: 18px;
//   border-radius: 4px;
//   cursor: pointer;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 2;
//   background-color: var(--white-color);
//   display: flex;
// }
// .default-checkbox {
//   opacity: 0;
//   visibility: hidden;
// }
// .default-checkbox:checked ~ .custom-checkbox {
//   background-color: var(--theme-button-primary-color);
//   border-radius: 4px;

//   .uploaded-tick {
//     display: block;
//   }
// }

.default-checkbox {
  opacity: 0;
  visibility: hidden;
  margin-right: 6px;
}
.uploaded-tick {
  display: none;
}
.default-checkbox:checked ~ .custom-checkbox {
  background-color: var(--white-color);
  border-color: var(--theme-button-primary-color);
  .uploaded-tick {
    display: block;
  }

}
.mandatory-lock{
  margin-left: -20px;
  margin-right: 8px;
}
.default-checkbox:disabled ~ .custom-checkbox {
  cursor: default;
  background-color: var(--white-color);
  border-color: lightgray;
  .uploaded-tick {
    display: block;
  }

}
.custom-checkbox {
  border: 1.5px solid $theme-border-color;
  height: 19px;
  width: 19px;
  border-radius: $base-border-radius;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: var(--white-color);
  display: flex;
}
