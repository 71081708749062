@import "extends.scss";
@import "variables.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.header_image {
  height: 50px;
  width: 50px;
}

body {
  overflow: hidden;
}

.logo_image {
  height: 100px;
  width: 100px;
}
.img-responsive {
  height: 40px;
  width: 180px;
}

.filter-image-container {
  @extend %transition;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  .filter-image {
    height: 28px;
    width: 28px;
  }
  // &:hover {
  //   background-color: #edf4ff;
  // }
}

//---------------------- border styles -------------------//

.vertical_line {
  border-left: 1px solid #dadce0;
  margin-left: 10px;
  height: auto;
}
.horizontal_line {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dadce0;
}

.common_height_width {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.template_button_container {
  background-color: $theme-blue-primary-color;
  padding: 5px;
  border-radius: 5px;
  p {
    font-size: 16px;
    color: white;
    font-weight: 500;
  }
}

.border__dashed {
  border: 2px dashed #dadce0;
}

.word__break {
  word-wrap: break-word;
}
.position-relative {
  position: relative !important;
}
.position-sticky {
  position: sticky !important;
  top: 0;
  z-index: 1;
}
.border_bottom {
  border-bottom: 1px solid $theme-border-color;
}
.border_top {
  border-top: 1px solid $theme-border-color;
}

.profile_circle {
  background-color: #2d5743;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 6px;
  .title {
    color: white;
    font-size: 14px;
  }
}

.active-icon {
  width: 7.5px;
  height: 7px;
  border-radius: 50%;
}

.ai-button-wrapper {
  position: relative;
  width: fit-content;
}

.ai-button {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
  width: fit-content;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 50px;
  border: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 2;
  position: relative;
}

.ai-button-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 125px;
  height: calc(100% + 4px);
  background: linear-gradient(
    90deg,
    #ff8038 0%,
    #ff0099 30.43%,
    #00ebeb 68.23%,
    #db00ff 100%
  );
  background-size: 600% 600%;
  border-radius: 50px;
  animation: AnimateBorder 6s ease infinite;
  -webkit-animation: AnimateBorder 6s ease infinite;
  -moz-animation: AnimateBorder 6s ease infinite;
  z-index: -1;
  transform: translate(-2px, -2px);
  transition: filter 1s ease-in;
  z-index: 1;
}
.ai-rewrite-button-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 148px;
  height: calc(100% + 4px);
  background: linear-gradient(
    90deg,
    #ff8038 0%,
    #ff0099 30.43%,
    #00ebeb 68.23%,
    #db00ff 100%
  );
  background-size: 600% 600%;
  border-radius: 50px;
  animation: AnimateBorder 6s ease infinite;
  -webkit-animation: AnimateBorder 4s ease infinite;
  -moz-animation: AnimateBorder 4s ease infinite;
  z-index: -1;
  transform: translate(-2px, -2px);
  transition: filter 1s ease-in;
  z-index: 1;
}

.ai-button-wrapper:hover .ai-button-bg {
  filter: blur(10px);
  transition: filter 0.4s ease-in;
}

@-webkit-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// .heart-beat-anim {
//   display: inline-block;
//   animation: heartbeat 2s ease-in-out infinite;
// }

// @keyframes heartbeat {
//   0% {
//     transform: scale(0.2);
//     opacity: 0.2;
//   }
//   25% {
//     transform: scale(0.5);
//     opacity: 0.4;
//   }
//   50% {
//     transform: scale(1);
//     opacity: 0.6;
//   }
//   75% {
//     transform: scale(0.5);
//     opacity: 0.8;
//   }
//   100% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }
