@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';
@import '@scss/common/_extends.scss';

.email-notification-subheader-wrapper {
	padding: 8px 24px;
	box-shadow: 0px 3px 6px #00000029;
}
.email-notification-subheader-wrapper__submenu-name {
	color: var(--theme-font-heading-secondary-color);
	@include genearteRemFontSize(16);
	font-weight: $medium-font-weight;
}
.preview-btn {
	color: $theme-button-primary-color;
	background-color: transparent;
	border: none;
}
.Service-text {
	margin-left: 6px;
	color: #707070;
	cursor: pointer;
	font-weight: $medium-font-weight;
	@include genearteRemFontSize(16);
}
