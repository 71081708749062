.sources-container {
  // max-width: 1200px;
  margin-top: 24px;
  // padding: 0 20px;
  width: 100%;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .sources-layout {
    display: grid;
    grid-template-columns: 240px 1fr 300px;
    gap: 24px;
  }

  .sidebar {
    border-right: 1px solid #eee;
    padding-right: 24px;
    min-height: calc(100vh - 200px);

    .menu-item {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      cursor: pointer;
      color: #353535;
      height: 32px;
      line-height: 32px;
      border-radius: 24px;
      font-size: 14px;
      margin-bottom: 4px;

      &:hover,
      &.active {
        background: #0000000f;
      }

      svg {
        margin-right: 8px;
      }

      &.active {
        color: #000;
        background: #d4e4fc;
      }
    }
  }

  .main-content {
    //   background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    height: fit-content;
    margin-bottom: 24px;
    // width: 500px;
    // height: calc(100vh - 260px);
    // overflow: auto;
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .crawl-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      input {
        margin-bottom: 12px;
      }

      p {
        color: #666;
        margin-bottom: 24px;
      }
    }

    .divider {
      text-align: center;
      color: #666;
      margin: 24px 0;
    }

    .sitemap-section {
      margin-bottom: 32px;
    }

    .links-section {
      h3 {
        font-size: 16px;
        // color: #666;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actions {
          display: flex;
          gap: 12px;
        }
      }

      .link-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 0;
        border-radius: 6px;
        margin-bottom: 8px;
        justify-content: space-between;

        .status {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          background: #e6ffe6;
          color: #16a34a;
          border-radius: 4px;
          font-size: 12px;
          width: fit-content;
        }

        .untrained {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          background: #f5e6ff;
          color: #5a16a3;
          border-radius: 4px;
          font-size: 12px;
          width: fit-content;
        }

        .new {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          background: #e6ebff;
          color: #1647a3;
          border-radius: 4px;
          font-size: 12px;
          width: fit-content;
        }
        .error_status {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          background: #ffede6;
          color: rgb(248, 42, 42);
          border-radius: 4px;
          font-size: 12px;
          margin-right: 12px;
        }

        .link-url {
          flex: 1;
          margin-right: 12px;
        }

        .chars {
          color: #666;
          margin-right: 12px;
        }

        .delete {
          color: #666;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: #ef4444;
          }
        }
      }
    }
  }

  .stats-sidebar {
    .stats-card {
      background: white;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      .stat-item {
        margin-bottom: 12px;

        .label {
          color: #666;
          font-size: 14px;
          margin-bottom: 4px;
        }

        .value {
          font-weight: 500;
        }

        .limit {
          color: #666;
          font-size: 14px;
        }
      }
    }

    .retrain-button {
      width: 100%;
      margin-top: 12px;
      background: #18181b;
      color: white;
      padding: 8px;
      border-radius: 6px;
      border: none;
      cursor: pointer;

      &:hover {
        background: #27272a;
      }
    }
  }
}

.chatbot_status__success {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #e6ffe6;
  color: #16a34a;
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
}

.chatbot_status__untrained {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #f5e6ff;
  color: #5a16a3;
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
}

.chatbot_status__new {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #e6ebff;
  color: #1647a3;
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
}
.chatbot_status__error {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #ffede6;
  color: rgb(248, 42, 42);
  border-radius: 4px;
  font-size: 12px;
  margin-right: 12px;
}


.text-container {
  // max-width: 1200px;
  // margin: 20px auto;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  // &:hover {
  //   // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  // }

  h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .text-input-wrapper {
    background: white;
    // border-radius: 12px;
    // padding: 24px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    // border: 1px solid #dadce0;
    width: 100%;
    .ant-input {
      border: none;
      background: transparent;
      font-size: 16px;
      padding: 16px;
      min-height: 400px;
      resize: none;

      &::placeholder {
        color: #9ca3af;
        font-size: 15px;
        font-weight: 400;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.qa-container {
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  height: fit-content;
  margin-bottom: 24px;

  .qa-header {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .qa-actions {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: end;

      .delete-all {
        color: #ff4444;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 0.9rem;

        &:hover {
          text-decoration: underline;
        }
      }

      .add-new {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background: #f5f5f5;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s;

        &:hover {
          background: #eeeeee;
        }
      }
    }
  }

  .qa-pair {
    background: white;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 1rem;

    .input-group {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .label-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        label {
          font-weight: 500;
          color: #333;
        }

        .delete-pair {
          background: none;
          border: none;
          cursor: pointer;
          padding: 0.25rem;
          display: flex;
          align-items: center;
          color: #666;

          &:hover {
            color: #ff4444;
          }

          .trash-icon {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}
