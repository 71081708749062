@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

// .managetable-wrapper {
// 	margin: 0 16px;
// }
.managetable-wrapper__table {
  border-collapse: collapse;
}
.managetable-wrapper__table thead tr {
  cursor: pointer;
  background-color: #f7fbff;
}
.managetable-wrapper__table thead tr th {
  padding: 19px 32px;
}
.managetable-wrapper__table tbody tr {
  // transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 1px;
  border-width: 1px 0px 1px 0;
  border-style: solid;
  border-color: #f7f7f7;
}
.managetable-wrapper__table tbody tr:hover {
  border-top: 1px solid green;
  border-bottom: 1px solid green;
  // td {
  // 	border: 1px solid #1a73e8;
  // }
}
.managetable-wrapper__table tbody tr td {
  padding: 18px 32px;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #f7f7f7;
  &:first-child {
    border-right: transparent;
  }
  &:nth-child(2) {
    border-right: transparent;
  }
}

// .managetable-wrapper__icon-image {
// 	height: 32px;
// 	width: 32px;
// }

.managetable-wrapper__header {
  border-width: 1px;
  border-style: solid;
  border-color: #f7f7f7;
  cursor: pointer;
  background-color: #f7fbff;
  border-bottom: 1px solid transparent;
  position: sticky;
  top: 0;
  // z-index: 100;
  z-index: 3;
}
.managetable-wrapper__body {
  background: #fff;
  border: 1px solid #efefef;
  cursor: pointer;
  height: 60px;
  border-radius: 8px;
  // transition: all 0.3s ease-in-out;
  // width: 95%;
  &:hover {
    // border-color: var(--theme-button-primary-color);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: all 0.3s ease-in-out;
  }
  margin-top: 5px;
}
.managetable-wrapper__body_active {
  border: 1px solid #dadce0;
  cursor: pointer;
  // transition: all 0.3s ease-in-out;
  margin-top: 5px;
}
.managetable-wrapper__icon-image {
  max-width: 56px;
  min-width: 56px;
  width: 100%;
  border-right: 1px solid #f7f7f7;
  padding: 18px 0;
  display: flex;
}

.managetable-wrapper__field-1 {
  max-width: 352px;
  min-width: 352px;
  width: 100%;
  border-right: 1px solid #f7f7f7;
  padding-left: 32px;
  padding: 10px 16px 10px 32px;
}

.managetable-wrapper__field-2 {
  max-width: calc(100% - 760px);
  min-width: calc(100% - 760px);
  width: 100%;
  padding: 10px 16px 10px 32px;
}
.managetable-wrapper__title {
  color: #1f1f1f;
  @include genearteRemFontSize(16);
  font-weight: $medium-font-weight;
  line-height: 1.8;
}
.managetable-wrapper__field-title {
  color: #5f6368;
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
  line-height: 1.8;
}

//manage field popup

// .add-more-rolls-wrapper {
// 	box-shadow: 4px 4px 10px #00000029;
// 	position: relative;
// 	z-index: 2;
// }
// .add-more-rolls-wrapper__header {
// 	background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
// 		no-repeat padding-box;
// 	padding: 20px 24px;
// }
// .add-more-rolls-wrapper__header-title {
// 	color: var(--white-color);
// 	font-weight: $normal-font-weight;
// 	@include genearteRemFontSize(20);
// }
// .add-more-rolls-wrapper__sub-title {
// 	padding: 16px 24px;
// 	color: var(--theme-font-heading-secondary-color);
// 	@include genearteRemFontSize(14);
// 	box-shadow: 4px 4px 10px #00000029;
// 	background-color: var(--white-color);
// }
// .add-more-rolls-wrapper__rolls-list {
// 	padding: 16.5px 0;
// }
// .add-more-rolls-wrapper__rolls-wrapper {
// 	padding: 0 24px;
// }
// .add-more-rolls-wrapper__rolls-list-item {
// 	margin-bottom: 16px;
// }
// .add-more-rolls-wrapper__rolls-list-title {
// 	color: var(--theme-font-heading-secondary-color);
// 	margin-bottom: 4px;
// 	@include genearteRemFontSize(16);
// 	font-weight: $normal-font-weight;
// }
// .add-more-rolls-wrapper__rolls-list-text {
// 	color: var(--theme-grey-color);
// 	@include genearteRemFontSize(14);
// 	font-weight: $light-font-weight;
// }
// .add-more-rolls-wrapper__footer {
// 	position: absolute;
// 	bottom: 0;
// 	right: 0;
// 	padding: 8px 24px;
// 	background-color: #f7fbff;
// 	width: 100%;
// }
// .add-more-rolls-wrapper__close-btn {
// 	height: 36px;
// 	width: 36px;
// 	border-radius: 50%;
// 	display: flex;
// 	background-color: transparent;
// 	&:hover {
// 		background-color: #248fbd;
// 	}
// }
// .add-more-rolls-wrapper__close-icon {
// 	margin: auto;
// 	height: 22px;
// 	width: 22px;
// }

//popup
.tabs-padding {
  padding: 16px 0 0 16px;
}

.edit-fields__wrapper {
  height: 680px;
  padding: 12px 24px;
}

.edit-fileds__form-group {
  margin-bottom: 16px;
}

.edit-fileds__mandatory {
  padding: 12px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  margin-bottom: 16px;
}

.edit-fileds__add {
  color: $theme-button-primary-color;
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
}

.edit-fileds__add-icon {
  height: 24px;
  margin-right: 8px;
  width: 24px;
}
.edit-fields__title {
  margin-bottom: 8px;
  color: #1f1f1f;
  @include genearteRemFontSize(16);
  font-weight: $medium-font-weight;
}

.input_close {
  position: absolute;
  top: 16px;
  right: 20px;
}
