@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.tickets-right-sidebar-wrapper {
  min-width: 350px;
  max-width: 350px;
}

.tickets-right-sidebar-wrapper,
.tickets-details-right-sidebar-wrapper {
  width: 100%;
  border-right: 1px solid $theme-border-color;
  height: calc(100vh - 104px);
  overflow-y: auto;
  background: #fff;

  .search-ticket-view {
    // margin: 10px;
    // width: 100%;
  }

  .ticket-sidebar-tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 8px;
    font-size: 14px;

    &--selected {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 38px;
      background: rgba(var(--button-primary), 0.2);
      border-radius: 4px;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 500;
      border-left: 4px solid rgba(var(--button-primary), 1);
    }
  }
}

// _filter-dropdown.scss
.filter-dropdown {
  margin-bottom: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f8fafc;
      border-color: #cbd5e1;
    }

    &:active {
      background-color: #f1f5f9;
    }
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #334155;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    color: #64748b;
    transition: transform 0.2s ease;

    .filter-dropdown--open & {
      transform: rotate(180deg);
    }
  }
}

// Optional dark mode styles
@media (prefers-color-scheme: dark) {
  .filter-dropdown {
    &__header {
      background-color: #1e293b;
      border-color: #334155;

      &:hover {
        background-color: #334155;
        border-color: #475569;
      }

      &:active {
        background-color: #1e293b;
      }
    }

    &__label {
      color: #e2e8f0;
    }

    &__icon {
      color: #94a3b8;
    }
  }
}
