@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.form_wrapper_builder-pipeline {
  width: 90%;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  margin: 0 auto;
  margin-top: 10px;
  align-items: center;
  // .form-control {
  //   width: 380px !important;
  // }
}
.task_container{
  background-color: white !important;
  width: 100%;
  min-height: 50vh;
  // grid-template-columns: repeat(1, 1fr);
  // grid-gap: 24px;
  margin: 0 auto;
  // margin-top: 10px;
  align-items: center;
  .form-control {
    width: 380px !important;
  }
}
.task_container_body{
  width: 85%;
  // grid-template-columns: repeat(1, 1fr);
  // grid-gap: 24px;
  min-height: 50vh;
  margin: 0 auto;
  align-items: center;
  .form-control {
    width: 380px !important;
  }
}
.form-control-text_task{
  border: none;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250) !important;
  background-color: transparent !important;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  &::placeholder {
    @include genearteRemFontSize(16);
    color: $theme-border-color;
  }
  &:focus {
    box-shadow: none;
    border: none;
    background-color: #f5f8fa !important;
  }
  &::placeholder{
    color: gray;
  }
}

.desktop-size{
  max-width: 600px;
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
}

.preview-t-radius{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}