@import "@scss/common/_variables.scss";

/* timeline.css */

/* Apply styles to all items */
.rct-item {
  transition: background-color 0.3s ease;
}

/* Apply hover effect to items */
.rct-item:hover {
  background-color: #f0f0f0; /* Change this to your desired background color */
}

/* Apply styles to all groups */
.rct-group {
  transition: background-color 0.3s ease;
}

/* Apply hover effect to groups */
.rct-group:hover {
  background-color: #e0e0e0; /* Change this to your desired background color */
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  // border-bottom: none !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-left: 1px solid #e1e1e1 !important;
  border-left: none !important;
  // border: 0.5px solid #000 !important;
  cursor: pointer;
  font-size: 14px;
  // background-color: rgba(var(--primary-color), 1) !important;
}
.react-calendar-timeline .rct-calendar-header {
  border: none !important;
  border-radius: 0 8px 0 0;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background-color: white !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background-color: white !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding: 0 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid #e1e1e1 !important;
  border-top: none !important;
  border-right: none !important;
}
.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  // border-right: 1px solid #e1e1e1 !important;
  border: 1px solid #e1e1e1 !important;
}
.react-calendar-timeline .rct-header-root {
  background-color: transparent !important;
  border: none !important;
  // border: 1px solid #e1e1e1 !important;
  background: none !important;
  border-bottom: 1px solid #bbb;
  border-radius: 8px 8px 0 0;
}
.react-calendar-timeline .rct-dateHeader {
  // background-color: rgba(var(--primary-color), 0.1);
  border-right: none !important;
  border-left: 1px solid #e1e1e1 !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #e1e1e1 !important;
}

.react-calendar-timeline .rct-sidebar {
  border-right: 1px solid #e9e9e9 !important;
}

.react-calendar-timeline .rct-scroll {
  overflow-x: hidden !important;
}

.calender-button-group-left {
  border-radius: 4px 0 0 4px;
  background: none;
  outline: none;
  border: 1px solid rgba(var(--button-primary), 1);
  background: rgba(var(--button-primary), 0.2);
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}
.calender-button-group-center {
  background: none;
  outline: none;
  border: 1px solid rgba(var(--button-primary), 1);
  background: rgba(var(--button-primary), 0.2);
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}
.calender-button-group-right {
  border-radius: 0 4px 4px 0;
  background: none;
  outline: none;
  border: 1px solid rgba(var(--button-primary), 1);
  background: rgba(var(--button-primary), 0.2);
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.selected-calender-button {
  background: rgba(var(--button-primary), 1);
  color: #fff;
}

.rct-outer {
  height: calc(100vh - 320px) !important;
  overflow-y: auto !important;
}

.rct-dateHeader {
  background: #fff !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl {
  background: none !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid #ebebeb !important;
}

.react-calendar-timeline .rct-dateHeader {
  font-weight: 400;
  cursor: pointer;
  font-size: 12px !important;
}

.react-calendar-timeline {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 0 0 8px 8px;
}
.react-calendar-timeline .rct-item .rct-item-content {
  color: white !important;
}
.rct-dateHeader.rct-dateHeader-primary {
  width: 100% !important;
  left: 0 !important;
}
.rct-item {
  border: none !important;
  border-radius: 5px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rct-sidebar-row {
  // margin-top: 10px !important;
  padding-top: 10px !important;
  height: 60px !important;
  line-height: 20px !important;
}

.rct-item {
  border-radius: 30px !important;
  padding: 0 12px !important;
  // padding: !important;
}

.react-calendar-timeline .rct-item .rct-item-content {
  padding: 0 !important;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

.room_booking_container{
  padding: 10px;
  min-width: 50px;
  border: 1px solid #efefef;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  &.active{
    background-color: #1677ff;
    color: white;
  }
}