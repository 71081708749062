.dashboard-container-performace {
    padding: 20px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  
  .filter-section {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
    // flex-wrap: wrap;
  }
  
  .filter-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .filter-item label {
    font-weight: bold;
  }