.business-hours-wrapper {
  overflow: hidden;
  display: flex;

  .business-hours-card-container {
    height: calc(100vh - 64px);
    overflow-y: auto;
    display: grid;
    gap: 1rem;

    .business-hours-card {
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      height: fit-content;

      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
  }

  .business-hours-right-details {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    overflow-y: auto;
    background: #f6f8fc;
    gap: 1rem;
  }
}
