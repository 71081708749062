@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';
@import '@scss/common/_extends.scss';

.pending-task-commondescription {
	margin-bottom: 16px;
	color: var(--theme-grey-color);
	@include genearteRemFontSize(14);
	font-weight: $normal-font-weight;
}
