.summit-data {
  .ant-table {
    @media only screen and (max-width: 991px) {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  .ant-table-body {
    max-height: calc(100vh - 254px) !important;
    @media only screen and (max-width: 991px) {
      max-height: calc(100vh - 440px) !important;
    }
  }
}
