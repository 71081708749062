.color_success {
  color: #58d68d !important;
}
.color_white {
  color: #fff !important;
}
.color_danger {
  // color: #d72e38 !important;
  color: red !important;
}
.color_warning {
  color: #f27914 !important;
}
.color_primary {
  color: #408dfb !important;
}
.color_primary_main {
  color: #276ef1 !important;
}
.color_violation {
  color: #ff8a00 !important;
}
.color_violation_desc {
  color: #b15620 !important;
}
.color_desc {
  color: #999 !important;
}
.color_black {
  color: #000 !important;
}

.color_gray {
  color: #667781 !important;
}
