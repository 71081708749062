@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.add-more-rolls-wrapper_pipeline {
  // box-shadow: 4px 4px 10px #00000029;
  position: relative;
  z-index: 2;
}
// .add-more-rolls-wrapper__header {
//   background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
//     no-repeat padding-box;
//   padding: 20px 24px;
// }
// .add-more-rolls-wrapper__header-title {
//   color: var(--white-color);
//   font-weight: $normal-font-weight;
//   @include genearteRemFontSize(20);
// }
// .add-more-rolls-wrapper__sub-title {
//   padding: 16px 24px;
//   color: var(--theme-font-heading-secondary-color);
//   @include genearteRemFontSize(14);
//   box-shadow: 4px 4px 10px #00000029;
//   background-color: var(--white-color);
// }
// .add-more-rolls-wrapper__rolls-list {
//   padding: 16.5px 0;
// }
// .add-more-rolls-wrapper__rolls-wrapper {
//   padding: 0 24px;
// }
// .add-more-rolls-wrapper__rolls-list-item {
//   margin-bottom: 16px;
// }
// .add-more-rolls-wrapper__rolls-list-title {
//   color: var(--theme-font-heading-secondary-color);
//   margin-bottom: 4px;
//   @include genearteRemFontSize(16);
//   font-weight: $normal-font-weight;
// }
// .add-more-rolls-wrapper__rolls-list-text {
//   color: var(--theme-grey-color);
//   @include genearteRemFontSize(14);
//   font-weight: $light-font-weight;
// }
// .add-more-rolls-wrapper__footer {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   padding: 8px 24px;
//   background-color: #f7fbff;
//   width: 100%;
// }
// .add-more-rolls-wrapper__close-btn {
//   height: 36px;
//   width: 36px;
//   border-radius: 50%;
//   display: flex;
//   background-color: transparent;
//   &:hover {
//     background-color: #248fbd;
//   }
// }
// .add-more-rolls-wrapper__close-icon {
//   margin: auto;
//   height: 22px;
//   width: 22px;
// }

//popup
.tabs-padding {
  padding: 16px 0 0 16px;
}

.edit-fields__wrapper {
  height: 680px;
  padding: 12px 24px;
}

.edit-fileds__form-group {
  margin-bottom: 16px;
}

.edit-fileds__mandatory {
  padding: 12px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  margin-bottom: 16px;
}

.edit-fileds__add {
  color: $theme-button-primary-color;
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
}

.edit-fileds__add-icon {
  height: 24px;
  margin-right: 8px;
  width: 24px;
}
.edit-fields__title {
  margin-bottom: 8px;
  color: #1f1f1f;
  @include genearteRemFontSize(16);
  font-weight: $medium-font-weight;
}
//add emp popup
.create_pipeline-wrapper_pipeline {
  overflow-y: auto;
  // max-height: calc(100vh - 261px);
  height: calc(100vh - 224px);
  max-height: calc(100vh - 224px);
}
.create_pipeline-wrapper__search {
  position: relative;
  margin-bottom: 17px;
}
.create_pipeline-wrapper__input {
  display: block;
  border-width: 0 0 1px 0;
  border-color: $theme-border-color;
  height: 56px;
  line-height: 56px;
  padding: 0 25px 0 50px;
  width: 100%;
  &::placeholder {
    color: #dadce0;
    @include genearteRemFontSize(14);
  }
}
.create_pipeline-wrapper__search-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.create_pipeline-wrapper__footer {
  width: 100%;
  height: 70px;
  z-index: 5;
  bottom: 0;
  position: fixed;
  right: 0;
  background-color: rgb(245, 248, 250) !important;
  border-top: 1px solid rgb(203, 214, 226);
  overflow: hidden;
}
.container {
  // position: absolute;
  // right: 30px;
}
.check-name {
  margin-left: 25px;
  margin-bottom: 16px;
}
.check-name-title {
  font-weight: $medium-font-weight;
  color: #1f1f1f;
}
//fields UI

.container {
  // display: flex;
  // align-items: stretch;
  // height: 100%;
  // .column {
  //   flex: 1;
  //   height: 100%;
  //   margin-left: 10px;
  //   margin-right: 10px;
  //   .column_title {
  //     font-size: 16px;
  //     margin-bottom: 20px;
  //     font-weight: 500;
  //   }
  //   .column_container {
  //     display: flex;
  //     align-items: center;
  //     cursor: pointer;
  //     border: 1px solid #efefef;
  //     margin-top: 5px;
  //     padding: 12px;
  //     border-radius: 5px;
  //     .column_container_title {
  //       font-size: 14px;
  //       margin-left: 10px;
  //     }
  //     &:hover {
  //       border-color: #afd7f8;
  //     }
  //   }
  // }
  // .separator {
  //   width: 1px;
  //   background-color: #efefef;
  //   margin: 3px;
  // }
}

// ---------------------- drag and drop container ------------------ //
.draganddrop_container {
  display: flex;
  justify-content: space-around;
  // position: relative;
  margin: 0 auto;
  width: 100%;
}
.draganddrop_not_selected_container {
  flex-grow: 1;
  width: 50%;
  padding-left: 2%;
  padding-right: 2%;
  .draganddrop_not_selected_container__main {
    overflow: auto;
    // max-height: calc(100vh - 280px);
    // height: calc(100vh - 280px);
    max-height: calc(100vh - 296px);
    height: calc(100vh - 296px);
    .source-item {
      cursor: pointer;
      padding: 10px;
      input[type="checkbox"] {
        width: 18px; /*Desired width*/
        height: 18px; /*Desired height*/
        accent-color: #1483c8;
      }
      &:hover {
        background-color: #f0f6fa;
        border-radius: 5px;
      }
    }
  }
}
.draganddrop_selected_container {
  flex-grow: 1;
  width: 50%;
  .draganddrop_selected_container__main {
    padding-left: 5%;
    padding-right: 5%;
    overflow: auto;
    // max-height: calc(100vh - 283px);
    // height: calc(100vh - 283px);
    max-height: calc(100vh - 296px);
    height: calc(100vh - 296px);
    .draganddrop_list {
      .source-item {
        // top: auto !important;
        // left: auto !important;
        border: 1px solid #dadce0;
        border-radius: 5px;
        padding: 10px;
      }
      &:hover {
        // background-color: #f0f6fa;
        border-radius: 5px;
      }
    }
  }
}

.input-source-item {
  top: auto !important;
  left: auto !important;
}
.step_1_container {
  overflow: auto;
  max-height: calc(100vh - 240px) !important;
  height: calc(100vh - 240px) !important;
}

.step_2_container {
  overflow: auto;
  max-height: calc(100vh - 257px) !important;
  height: calc(100vh - 257px) !important;
  padding-left: 10%;
  padding-right: 10%;
}

//stepper css
:root {
  --circle-size: clamp(1rem, 5vw, 1.2rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}
.c-stepper {
  margin-top: 20px;
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: lightgrey;
    opacity: 0.5;
    // margin: 0 auto 1rem;
    margin: 0 auto 0.5rem;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
      height: 2px;
      background-color: #e0e0e0;
      order: -1;
    }
  }
}

.c-stepper__item_active {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: #41b7cf;
    opacity: 0.5;
    // margin: 0 auto 1rem;
    margin: 0 auto 0.5rem;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
      height: 2px;
      background-color: #e0e0e0;
      order: -1;
    }
  }
}

.c-stepper__title {
  // font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.c-stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}
.stage_color_picker {
  border: none;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
