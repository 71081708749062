@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';

.common-login-wrapper {
  // max-height: calc(100vh - $desktop-header-height);
  // height: calc(100vh - $desktop-header-height);
  overflow-y: auto;
  // min-height: 675px;
  max-height: calc(100vh - 65px);
  height: calc(100vh - 65px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.blue-background {
  // background-color: $sky-blue-color;
  background-color: #f5f8fd;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    display: none;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    display: none;
  }
}
