.training-data-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;

  .training-data-cotnent-container {
    border: 1px solid #dee2e6;
    padding: 1.5rem;
    border-radius: 8px;
    max-height: calc(100vh - 212px);
    overflow-y: auto;
    width: 100%;
  }
}

.actions-card-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // height: calc(100vh - 266px);
  margin: 1rem 0;
  gap: 1rem;

  @media only screen and (max-width: 1439px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .actions-card {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    }
  }
}

.custom-action-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;

  .custom-action-left-side,
  .custom-action-right-side {
    max-height: calc(100vh - 124px);
    padding: 2rem;
    overflow-y: auto;
  }
}

.ai-chatbot-container {
  height: calc(100vh - 124px);
  overflow-y: hidden;

  .contentWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 0 3rem;
    height: calc(100vh - 176px);
    overflow-y: auto;

    // .contentContainer {
    //   width: 75%;
    //   margin: 0 auto;
    // }
    .contentFullContainer {
      width: 100%;
      margin: 0 auto;
    }
  }

  .ai-chatbot-settings {
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 176px);
    overflow-y: auto;
  }
}

.tabWrapper {
  position: sticky;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  // border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: center;

  .tabContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .tabs {
      padding: 0.5rem 1.25rem;
    }
  }
}

// JSONEditorWithLineNumbers.scss
.json-editor {
  font-family: Arial, sans-serif;
  padding: 20px;

  &__code-container {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    background-color: #f8f8f8;

    // Custom scrollbar styles
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__error {
    color: #dc3545;
    margin-bottom: 16px;
    padding: 8px;
    border-radius: 4px;
    background-color: rgba(#dc3545, 0.1);
  }

  &__textarea {
    width: 100%;
    padding: 12px;
    font-family: monospace;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    resize: vertical;

    &:focus {
      outline: none;
      border-color: #0066cc;
      box-shadow: 0 0 0 2px rgba(#0066cc, 0.2);
    }
  }

  &__save-button {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: darken(#0066cc, 10%);
    }

    &:active {
      background-color: darken(#0066cc, 15%);
    }
  }
}

// Override CodeBlock styles if needed
:global {
  .react-code-blocks {
    font-family: "Fira Code", monospace !important;

    pre {
      margin: 0 !important;
    }
  }
}

.ai-settings-button {
  position: absolute;
  right: 0;
  top: 0;
}
