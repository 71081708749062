.chat-logs {
  // padding: 2rem;
  padding-top: 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;

  .chat-logs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .actions {
      display: flex;
      gap: 0.75rem;

      .action-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border: 1px solid #e0e0e0;
        border-radius: 0.5rem;
        background: white;
        color: #333;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background: #f5f5f5;
        }

        &.primary {
          background: #000;
          color: white;
          border: none;

          &:hover {
            background: #333;
          }
        }
      }
    }
  }

  .ai-chat-conversations {
    max-height: calc(100vh - 260px);
  }

  .chat-logs-container {
    display: grid;
    grid-template-columns: 380px 1fr;
    gap: 1.5rem;
    // height: calc(100vh - 100px);
    overflow: hidden;

    .chat-logs-list {
      // border-right: 1px solid #e0e0e0;
      overflow-y: auto;
      max-height: calc(100vh - 308px);
      border-right: 1px solid #e0e0e0;

      // padding-right: 1rem;

      .chat-item {
        &.active {
          background: #f8f8f8;
        }
        margin-right: 1.4rem;
        padding: 1rem 1rem 1rem 1rem;
        border-bottom: 1px solid #e0e0e0;
        // border-radius: 0.5rem;
        // background: #f8f8f8;
        // margin-bottom: 0.75rem;
        cursor: pointer;
        transition: background 0.2s;

        &:hover {
          background: #f0f0f0;

          .delete-button {
            opacity: 1;
          }
        }

        .chat-content {
          h3 {
            font-size: 0.9rem;
            margin-bottom: 0.25rem;
            color: #333;
          }

          .short-query {
            font-size: 0.85rem;
            color: #666;
          }
        }

        .chat-meta {
          display: flex;
          justify-content: end;
          align-items: center;
          margin-top: 0.5rem;

          .time-ago {
            font-size: 0.85rem;
            color: #666;
          }

          .delete-button {
            opacity: 0;
            background: none;
            border: none;
            color: #ff4444;
            cursor: pointer;
            padding: 0.25rem;
            transition: opacity 0.2s;

            &:hover {
              color: #ff0000;
            }
          }
        }
      }
    }

    .chat-detail {
      padding: 1rem;
      overflow-y: auto;
      max-height: calc(100vh - 308px);

      .source {
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 1rem;
      }

      .message-bubble {
        max-width: 80%;
        padding: 1rem;
        border-radius: 1rem;
        margin-bottom: 1rem;
        position: relative;
        width: fit-content;

        &.user {
          background: #f0f0f0;
          margin-right: auto;
          border-bottom-left-radius: 0;
        }

        &.bot {
          background: #f8f8f8;
          margin-left: auto;
          border-bottom-right-radius: 0;
        }

        .message-actions {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-top: 0.75rem;
          padding-top: 0.75rem;
          border-top: 1px solid #e0e0e0;

          .confidence {
            background: #7c3aed;
            color: white;
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            font-size: 0.85rem;
          }

          .revise-button {
            background: none;
            border: none;
            color: #666;
            cursor: pointer;
            font-size: 0.85rem;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .suggestion-pills {
        display: flex;
        gap: 0.5rem;
        margin-top: 2rem;

        .pill {
          background: #000;
          color: white;
          border: none;
          padding: 0.5rem 1rem;
          border-radius: 2rem;
          font-size: 0.9rem;
          cursor: pointer;
          transition: background 0.2s;

          &:hover {
            background: #333;
          }
        }
      }
    }
  }
}
