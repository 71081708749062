@import "../normalize.scss";
@import "../extends.scss";
@import "../mixins.scss";
@import "../variables.scss";

//-------------- tooltip ----------------//
// https://codepen.io/lideo/pen/KKGeQG
.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1000;
  width: 100px;
  color: white;
  font-size: 12px;
  background-color: #5d6d7e;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}
.hover-text:hover .tooltip-text:after {
  content: " ";
  position: absolute;
  left: -15px;
  top: 35px;
  border-top: 15px solid transparent;
  border-right: 15px solid #5d6d7e;
  border-left: none;
  border-bottom: 15px solid transparent;
}

#top {
  // left: -50%;
  width: 300px;
  // margin-left: 70px;
  margin-top: -40px;
  margin-left: 10px;
}

#table {
  width: 300px;
  margin-top: -40px;
}

#left {
  top: -8px;
  right: 120%;
}

#right {
  top: -8px;
  left: 120%;
}

// .content,
// .other-content {
//   opacity: 0;
//   height: 0;
//   overflow: hidden;
//   transition: opacity 1s ease, height 1s ease;
// }

// .open {
//   opacity: 1;
//   height: auto;
// }

// .closed {
//   opacity: 0;
//   height: 0;
// }
//---------------------------2nd approach ------------------//
.content,
.other-content {
  opacity: 0;
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 103;
}

.open {
  height: auto;
  opacity: 1;
  transform: scaleY(1);
  z-index: 997;
}

.closed {
  height: 0;
  opacity: 0;
  transform: scaleY(0);
}
.rdw-editor-toolbar {
  padding: 6px 20px 0 !important;
  border-radius: 2px;
  border: 1px solid #efefef !important;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
  // box-shadow: 10px -3px 11px -5px rgba(39, 55, 60, 0.2) !important;
}
.rdw-editor-main {
  // border: 1px solid #efefef !important;
}
