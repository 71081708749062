.new-signup-wrapper {
  width: 100%;
  height: 100vh;
  background: aqua;
  background-image: url("./signup-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .signup-overlay {
    background: #00000061;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;

    .signup-card {
      width: 652px;
      min-height: 360px;
      height: fit-content;
      margin-top: 148px;
      background: #fff;
      padding: 0 114px;
      position: relative;
      padding-bottom: 46px;

      .progress-bar {
        background: #227c39 !important;
      }

      .progress {
        background: #e7f4ea !important;
      }

      .stepper-details {
        color: #3c4043;
        font-size: 16px;
        text-align: center;
        margin-top: 32px;
      }

      .signup-details {
        color: #707070;
        font-size: 16px;
      }

      .stepper-previous,
      .stepper-next {
        all: unset;
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        background: #fcfdff;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3 ease-in-out;

        &:hover {
          color: #276ef1;
        }

        &:disabled {
          opacity: 0.5;
          cursor: auto;

          &:hover {
            color: inherit;
          }
        }
      }

      .stepper-previous {
        left: 24px;
      }

      .stepper-next {
        right: 24px;
      }

      .new-signup-form {
        display: grid;
        gap: 16px;
        margin: 16px 0;

        .signup-input {
          border: 1px solid #dadce0;
          border-radius: 8px;
          padding: 16px;
          width: 100%;

          &:focus {
            border-color: rgba(var(--primary-color), 1);
          }
        }

        .password-toggle-button {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }

        .signup-search-tooltip {
          .popup-selected-value-box {
            height: 54px;

            .placeholder_text,
            .value {
              margin-top: 4px;
            }
          }
        }

        .signup-search-number-input {
          .react-international-phone-input-container {
            .react-international-phone-input,
            .react-international-phone-country-selector-button {
              height: 54px !important;
            }
          }
        }
      }

      .signup-submit-button {
        background: rgba(var(--primary-color), 1);
        border-radius: 8px;
        height: 44px;
        width: 100%;
        font-size: 16px;
        color: #ffffff;
        outline: none;
        border: none;

        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }
}
