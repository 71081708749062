@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.signup-form-wrapper {
  margin: auto;
  max-width: 364px;
  width: 100%;
}

.signup-form-wrapper__form-group .custom-signup-form-control {
  line-height: 56px;
  height: 56px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  padding: 0 50px;
  @extend %transition;
  &:focus {
    box-shadow: none;
    border-color: $theme-button-primary-color;
  }
  &::placeholder {
    color: #919191;
    font-size: 16px;
  }
  // @include mobile {
  //   height: 44px;
  // }
}
.image-icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}
.signup-form-details {
  max-width: 364px;
  width: 100%;
  margin: 0 auto;
}
.signup-title {
  @include genearteRemFontSize(22);
  font-weight: $normal-font-weight;
  margin-bottom: 24px;
}
.signup-text {
  // max-width: 330px;
  margin: 16px 0 0;
  color: $theme-primary-color;
}
.signup-text span {
  color: $theme-button-primary-color;
}

.error-text {
  color: $red-color;
  font-size: 12px;
  margin-top: 8px;
}

.signup-form-wrapper__form-group--error {
  .custom-form-control {
    border-color: $red-color;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $theme-border-color;
    }
  }
}

.custom-signup-time-zone {
  .ant-select-selector {
    padding: 24px 16px !important;
  }

  .ant-select-arrow {
    top: 75% !important;
  }
}
