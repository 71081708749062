@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.add-existing-employee {
  max-height: calc(100vh - 126px);
  height: calc(100vh - 126px);
  overflow-y: auto;
}
.add-existing-employee-wrapper__details {
  gap: 15px;
  margin-bottom: 14px;
}
.add-existing-employee-wrapper__name {
  color: var(--theme-font-heading-secondary-color);
  font-weight: $medium-font-weight;
  margin-bottom: 4px;
}
.add-existing-employee-wrapper__custom-check {
  margin-top: 5px;
}
.add-existing-employee-wrapper__text {
  color: var(--theme-grey-color);
  font-weight: $normal-font-weight;
  margin-bottom: 4px;
  @include genearteRemFontSize(14);
}
//checkbox

.default-checkbox {
  opacity: 0;
  visibility: hidden;
}
.uploaded-tick {
  display: none;
}
.default-checkbox:checked ~ .custom-checkbox {
  background-color: var(--white-color);
  border-color: var(--theme-button-primary-color);
  .uploaded-tick {
    display: block;
  }
}
.mandatory-lock {
  // margin-left: -15px;
}
.default-checkbox:disabled ~ .custom-checkbox {
  cursor: default;
  background-color: var(--white-color);
  border-color: lightgray;
  .uploaded-tick {
    display: block;
  }
}
.custom-checkbox {
  border: 1.5px solid $theme-border-color;
  height: 18px;
  width: 18px;
  border-radius: $base-border-radius;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: var(--white-color);
  display: flex;
}
.task_list-search_container{
  width: 90%;
  margin: 20px;
  .search_image{
    position: absolute;
    height: 23px;
    width: 23px;
    margin-top: 12px;
    margin-left: 10px;
  }
  .input_field{
    padding-left: 40px;
    border: 1px solid $theme-border-color;
    border-radius: $base-border-radius !important;
    background-color: rgb(245, 248, 250) !important;
    height: 44px;
    line-height: 44px;
    // padding: 0 16px;
    width: 100%;
    &::placeholder {
      @include genearteRemFontSize(16);
      color: $theme-border-color;
    }
    &:focus {
      box-shadow: none;
      background-color: white !important;
      border: 1px solid #276EF1;
    }
  }
}