.edit-popup-wrapper {
  padding: 0 0 30px;
}
.edit-footer-wrapper {
  position: sticky;
  bottom: 0;
  border-top: 0.5px solid #efefef;
  padding-top: 40px;
  color: #1a73e8;
  cursor: pointer;
  display: flex;
  float: right;
}
