@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.dropdown-account {
  font-weight: $medium-font-weight;
  color: var(--theme-font-body-color);
  @include genearteRemFontSize(20);
  line-height: 1;
  margin-bottom: 16px;
  padding-top: 16px;
  min-width: 200px;
}
.dropdown-account-wrapper {
  padding: 0 24px;
  //   margin-bottom: 16px;
}
.dropdown-list-wrapper {
  margin: 19px 0;
}
.signin {
  display: flex;
  height: 32px;
  width: 32px;
  // background-color: var(--theme-deep-yellow-color);
  background: #0b5999;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 9px;
}
.signin--initial {
  display: flex;
  height: 50px;
  width: 50px;
  background-color: var(--theme-deep-yellow-color);
  color: var(--white-color);
  border-radius: 50%;
  cursor: pointer;
}
.signin-name {
  margin: auto;
}
.signin__name {
  @include genearteRemFontSize(14);
  color: #1f1f1f;
  font-weight: $medium-font-weight;
  line-height: 1;
}
.signout-button {
  background: none !important;
  min-width: 34px !important;
  min-height: 34px !important;
  box-shadow: none !important;
}
.signin__text {
  color: #454746;
  @include genearteRemFontSize(14);
  margin: 3px 0;
}
.signin__text-span {
  color: #21c9af;
}
.dropdown-playstore {
  box-shadow: 0px 3px 6px #00000029;
  padding: 6px 24px;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 3;
  background: #f7fbff;
}
.playstore-icons {
  height: 44px;
  width: 132px;
  // padding-bottom: 6px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  max-width: 90% !important;
}
.signin-name--initial {
  margin: auto;
  @include genearteRemFontSize(20);
}
.dropdown-button {
  border: 1px solid var(--theme-border-color);
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: var(--theme-button-primary-color);
  background-color: var(--white-color);
  width: 100%;
  border-radius: $base-border-radius;
  margin-bottom: 16px;
  font-size: 14px;
  &:last-child {
    margin-bottom: 8px;
  }
  &:disabled {
    background-color: $gray-color-secondary;
    color: $gray-color;
  }
}
.dropdown-list-wrapper__list-items {
  padding: 8px 24px;
  color: $theme-font-terciary-color;
  @include genearteRemFontSize(14);
  &:hover {
    background-color: $sky-blue-color;
    color: $theme-button-primary-color;
  }
}
.dropdown-button-cta {
  padding: 11px 24px 0;
}
.dropdown-privary-policy {
  color: var(--theme-font-terciary-color);
  @include genearteRemFontSize(14);
}
.dropdown-privary-policy-wrapper {
  margin-bottom: 25px;
}

.dropdown-wrapper-company {
  // border-top: 1px solid $theme-border-color;
  padding: 16px 23px;
  // &:last-child {
  // 	border-bottom: 1px solid $theme-border-color;
  // }
  border: 1px solid $theme-border-color;
  margin-top: 8px;
  border-radius: 5px;
  &:hover {
    background-color: transparent;
  }
}
.dropdown-wrapper-company-list {
  height: 400px;
  overflow: auto;
}

.dropdown-wrapper-company_active {
  padding: 16px 23px;
  border: 1px solid $theme-border-color;
  margin-top: 8px;
  border-radius: 5px;
  &:hover {
    background-color: transparent;
  }
  border-left: 3px solid #1a73e8;
}

.dropdown-wrapper-company__name {
  color: var(--theme-font-heading-secondary-color);
  @include genearteRemFontSize(16);
  font-weight: $medium-font-weight;
}

.dropdown-wrapper-company__code {
  color: var(--theme-font-terciary-color);
  @include genearteRemFontSize(12);
  font-weight: $light-font-weight;
}
.org_logo {
  height: 35px;
  width: 65px;
  border: 1px solid #efefef;
  border-radius: 5px;
}

.account-sidepar-popup {
  .ant-drawer-body {
    padding: 0 !important;
  }
}
//new code
.account-sidepop {
  width: 400px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  height: 100vh;
  overflow: hidden;
  background: white;

  .profile-header {
    background-color: #eaeef5;
    // display: flex;
    padding: 50px 24px 24px;
    align-items: flex-start;
    position: relative;

    .close-icon-acc-dtls {
      position: absolute;
      height: 24px !important;
      width: 24px !important;
      cursor: pointer;
      background: none;
      outline: none;
      border: none;
      color: #212024;
      top: 20px;
      right: 20px;
    }

    .sign_out_button,
    .my_account_button {
      font-size: 12px;
      border-radius: 6px;
      padding: 6px 16px;
      border: none;
      outline: none;
      transition: all 0.3s ease-in-out;
    }

    .my_account_button {
      background: #c8e6fd;
      color: #061d33;

      &:hover {
        background-image: linear-gradient(
          rgb(17, 153, 95),
          rgb(17, 153, 95) 1%,
          rgb(39, 182, 121)
        );
      }
    }

    .sign_out_button {
      border: 1px solid #454746;
      background: none;
      color: #454746;

      &:hover {
        background: #fff;
        color: #000;
      }
    }
  }

  .profile-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 262px);
    overflow-y: auto;

    .pro-sub {
      padding: 16px 24px;

      .free-tag {
        background-image: url("./tag.svg");
        background-repeat: no-repeat;
        display: inline-block;
        width: 46px;
        color: #fff;
        font-size: 12px;
        display: flex;
        justify-content: center;
      }

      .profile-reset {
        background-color: #f9fafa;
        color: #55617a;
        font-size: 11px;
        letter-spacing: 0.18px;
        padding: 2px 8px;
        border: 1px solid #ecf1f3;
        border-radius: 4px;
        width: max-content;
        margin-top: 8px;
      }
    }

    .pro-sub-border-line {
      background-color: #d9e3e8;
      width: 100%;
      height: 1px;
    }

    .pro-need-help {
      padding: 16px 24px;

      .profile-need-help-button {
        color: #454746;
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
        gap: 8px;
        font-weight: 400;

        // &:hover {
        //   color: #1f1f1f;
        // }
      }
    }

    .webinar-contact {
      background: linear-gradient(109deg, #fcfcfc, #eaffff);
      margin: 16px;
      border: 1px solid #edfaf5;
      padding: 16px;
      border-radius: 6px;
      display: flex;
      gap: 12px;
      align-items: baseline;

      .webinar-register {
        color: #eb9623;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .profile-footer {
    height: 100px;
    padding: 16px 24px;
    border-top: 1px solid #d9e3e8 !important;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    width: 100%;
    background: none;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      .sidebar-footer-icon {
        fill: #039949;
      }
    }
  }
}

.app-download-modal {
  background: linear-gradient(180deg, #fbf0ff 0, #fff 100%) no-repeat
    padding-box;
  border-radius: 16px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
}

.status-switcher {
  .ant-btn {
    display: flex;
    align-items: center;
    padding: 4px 12px;

    .status-button {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.status-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profile-details-popover {
  .ant-popover-inner {
    width: 340px;
    position: relative;
    min-height: 400px;
    .profile-details-popover-content {
      .profile-details-popover-content-header {
        width: 100%;
        height: 100px;
        background: rgba(var(--primary-color), 0.2);
        border-radius: 8px 8px 0 0;
        position: absolute;
        top: 0;
        left: 0;

        .signin--initial {
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
        }
      }
    }
  }
}
