.property-location-wrapper {
  width: 100%;
  height: calc(100vh - 124px);
}

.bed_type_container {
  // background: #f6f6f6;
  border-radius: 8px;
  // padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;

  .bed_type-background {
    background-image: linear-gradient(to bottom, #00000041 30%, #000000d2 100%);
    padding: 1rem;
    height: 100%;
    border-radius: 6px;
    backdrop-filter: blur(3px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .card-text-white {
      color: #dddddd;
    }
  }

  .bed_type_details-edit {
    // background: #fff;
    background-image: url("../../../assets/svg/bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0) 0px 0px 0px 1px;
  }

  .bed_type_details {
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;

    .ant-image {
      display: block;
    }

    .room-type-image {
      width: 100%;
      height: 180px;
      border-radius: 6px 6px 0 0;
      object-fit: cover;
      width: 100%;
    }
  }

  .bed_type-add {
    background: rgba(var(--button-primary), 1);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    min-height: 160px;
  }
}

.policy-time-width {
  width: 60px;
  display: inline-block;
}

.display-inline {
  display: inline-block;
}

.tooltip-width {
  width: 200px;
}

.property-list-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  .property-card-add {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    h6 {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      h6 {
        color: rgba(var(--button-primary), 1) !important;
      }
    }
  }

  .property-card {
    // border: 1px solid var(--theme-border-color);
    border-radius: 8px;
    background-color: var(--white-color);
    text-align: start;
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;

    .property-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 6px 6px 0 0;
    }

    .property-card-edit {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 30px;
      width: 30px;
      background: #ffffff90;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      display: none;
      backdrop-filter: blur(10px);
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      .property-card-edit {
        display: flex;
      }
    }
  }
}

.progress-bar-wrapper {
  position: sticky;
  top: 1px;
  background: var(--white-color);
  padding: 1.5rem 3rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16078);
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  z-index: 1;

  .step-container {
    display: flex;
    justify-content: space-between;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: #bbd9ff;
      height: 3px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }

    &::after {
      content: "";
      position: absolute;
      background: #1a73e8;
      height: 3px;
      width: var(--progress-width);
      top: 50%;
      transition: 0.4s ease;
      transform: translateY(-50%);
      left: 0;
    }
    .step-wrapper {
      position: relative;
      z-index: 1;
    }

    .step-style {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: var(--white-color);
      border: 2px solid #bbd9ff;
      transition: 0.4s ease;
      display: flex;
      justify-content: center;
      align-items: center;

      &.completed {
        border-color: #1a73e8;
        background: #1a73e8;
      }
    }

    .step-count {
      font-size: 14px;
      color: var(--white-color);
    }

    .steps-label-container {
      position: absolute;
      top: 34px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
    margin-top: 40px;
  }

  .button-style {
    border-radius: 4px;
    border: 0;
    background: #1a73e8;
    color: var(--white-color);
    cursor: pointer;
    padding: 8px;
    width: 90px;

    &:active {
      transform: scale(0.98);
    }

    &:disabled {
      background: #bbd9ff;
      // color: #000000;
      cursor: not-allowed;
    }
  }

  .check-mark {
    font-size: 14px;
    font-weight: 600;
    color: var(--white-color);
    transform: scaleX(-1) rotate(-46deg);
  }
}

.add-room-details {
  height: calc(100vh - 184px);
  overflow-y: auto;
}

.add-box-shadow {
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  //   rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  border: 1px solid var(--theme-border-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.more-options-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .more-options-card {
    border-radius: 4px;
    border: 1px solid var(--theme-border-color);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    background: none;
  }
}

.information-container {
  background-color: #e0efff;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 0.6rem 1rem;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 0.6rem;
  width: fit-content;
}

.custom-modal {
  z-index: 1500 !important;
}

.reservation-card-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .reserversation-details-card {
    min-height: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--theme-border-color);
    border-radius: 6px;
    gap: 10px;

    .card-count {
      font-size: 30px;
      font-weight: 500;
    }
  }
}

.reservation-rightside {
  width: 300px;
}

.property-container {
  height: calc(100vh - 64px - 56px - 54px);
  overflow-y: auto;
}

.room-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.amenity_container {
  display: flex;
  flex-wrap: wrap;
  .amenity_text {
    padding: 20px;
    border: 1px solid #efefef;
    min-width: 100px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
    text-align: center;
  }
}
.property_image_container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  .property_image {
    height: 200px;
    width: 200px;
    border-radius: 5px;
    margin-right: 15px;
    border: 1px solid #efefef;
    overflow: hidden;
  }
}

.property_delete_icon {
  position: absolute;
  right: 20px;
  top: 5px;
  background-color: var(--white-color);
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.property_room_container {
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .property_room {
    border: 1px solid #efefef;
    min-height: 100px;
    width: 300px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.icon_circle {
  border: 1px solid #efefef;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
}

.count-buttons {
  background: none;
  outline: none;
  border: none;
  border-radius: 50%;

  :disabled {
    background: #aeaeae;
    cursor: not-allowed !important;
  }
}

.checkedin-tag {
  background: #b1dbfd;
  // background: rgba(var(--button-primary), 1);
  // color: var(--white-color);
  font-size: 13px;
  font-weight: 400;
  padding: 4px 10px;
  border-radius: 16px;
  margin: 0 8px;
}

.property-cover-image img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.property-image-swiper {
  height: 200px;

  .ant-image-img {
    border-radius: 16px;
    object-fit: cover;
    height: 200px;
  }

  .ant-image-mask {
    border-radius: 16px;
  }
}

.booking-tabs {
  font-family: "Superteams-font";
}

.booking-info-navigation-steps {
  .ant-steps-item-active {
    &::before {
      display: none !important;
    }
  }
}

.google-map-updation-status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 8px;
  border-radius: 6px;
}
