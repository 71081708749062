.chat-agents-container {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 24px;

  .status-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
      color: #333;
    }

    .status-indicators {
      display: flex;
      gap: 40px;

      .status-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .status-label {
          margin-bottom: 8px;
          font-size: 14px;
          color: #666;
        }

        .status-count {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }

  .agents-table-section {
    border-radius: 8px;
    overflow: hidden;

    .table-header {
      padding: 0;

      .agents-tabs {
        .ant-tabs-nav {
          margin-bottom: 0;

          .ant-tabs-tab {
            padding: 12px 16px;
            font-weight: 500;

            &.ant-tabs-tab-active {
              color: #1890ff;
              border-bottom: 2px solid #1890ff;
            }
          }
        }
      }

      .table-filters {
        display: flex;
        padding: 16px;
        background-color: #fff;
        justify-content: space-between;
        align-items: center;

        .search-input {
          width: 240px;
          border-radius: 4px;
        }

        .filter-options {
          display: flex;
          gap: 16px;

          .filter-group {
            display: flex;
            align-items: center;
            gap: 8px;

            .filter-label {
              color: #666;
              font-size: 14px;
            }

            .filter-select {
              width: 120px;
            }
          }
        }
      }
    }

    .agents-table {
      .ant-table {
        background-color: #fff;

        .ant-table-thead > tr > th {
          background-color: #f5f5f5;
          font-weight: 500;
          color: #333;
        }
      }

      .agent-name {
        display: flex;
        align-items: center;
        gap: 8px;

        .agent-avatar {
          background-color: #f0f0f0;
        }
      }
    }

    .pagination-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background-color: #fff;
      border-top: 1px solid #f0f0f0;

      .custom-pagination {
        .ant-pagination-item-active {
          background-color: #1890ff;
          border-color: #1890ff;

          a {
            color: #fff;
          }
        }

        .ant-pagination-prev,
        .ant-pagination-next {
          color: #666;
        }
      }

      .page-info {
        color: #666;
        font-size: 14px;
      }
    }
  }
}
