.orgauth_container {
  display: flex;
  justify-content: center;
}

.accept_button {
  border: none;
  background-color: green;
  border: 1px solid green;
  color: white;
  padding: 6px;
  border-radius: 5px;
}

.decline_button {
    border: none;
    background-color: white;
    border: 1px solid #dadce0;
    color: black;
    padding: 6px;
    border-radius: 5px;
}
