.input-radio {
  cursor: pointer;
  font-size: 3em;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.input-radio:checked {
  //   box-shadow: 0px 0px 0px 2px #2890be;
  cursor: pointer;
  background-color: #2890be;
  font-size: 3em;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  border: 2px solid #fff;
  border-radius: 50%;
}
