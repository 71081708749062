@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.showdiv {
  opacity: 1;
  visibility: visible;
  height: initial;
  max-height: 100vh;
  overflow: initial;
  margin-top: 16px;
}
.hidediv {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  max-height: 0;
}

.accordian-wrapper {
  transition: all 0.5s ease-in-out;
}

.pendingtaskright-wrapper__company-details {
  margin: 0 0px 16px;
  border: 1px dashed $theme-border-color;
  padding: 16px;
}

.pending-task_userinformation__name {
  color: #5f6368;
  font-weight: $medium-font-weight;
  margin-bottom: 4px;
  @include genearteRemFontSize(16);
}
.pending-task_userinformation__emails {
  color: #707070;
  margin: 0px 0 4px;
  @include genearteRemFontSize(14);
  width: 100%;
}
.pending-task_userinformation__phone {
  color: #707070;
  @include genearteRemFontSize(14);
  width: 100%;
}
.pending-task_userinformation__copy-image {
  height: 16px;
  width: 16px;
  margin-left: 8px;
}

.pending-task_userinformation__span {
  color: #1f1f1f;
  @include genearteRemFontSize(14);
}
.pending-task_userinformation__span--blue-color {
  color: $theme-button-primary-color;
}
.pending-task_more_info_text {
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #1a73e8;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.pending-task_userinformation__more-image_container {
  position: absolute;
  // right: 18px;
  right: 18px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // &:hover {
  //   background-color: #e8f0fe;
  //   border-radius: 50%;
  // }
  .pending-task_userinformation__more-image {
    position: absolute;
    right: 0px;
  }
}
.task_more-list {
  // background-color: #f7fbff;
  background-color: white;
  // display: flex;
  height: calc(100vh - 64px);
  overflow-y: auto;
  padding: 20px;
}
