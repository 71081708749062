@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

// .add-more-rolls-wrapper__header {
//   background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
//     no-repeat padding-box;
//   // padding: 20px 24px;
//   padding-left: 20px;
//   padding-right: 20px;
//   height: 80px;
// }
// .add-more-rolls-wrapper__header-title {
//   color: var(--white-color);
//   font-weight: $medium-font-weight;
//   @include genearteRemFontSize(20);
// }
// .add-more-rolls-wrapper__sub-title {
//   padding: 16px 24px;
//   color: var(--theme-font-heading-secondary-color);
//   @include genearteRemFontSize(14);
//   box-shadow: 4px 4px 10px #00000029;
//   background-color: var(--white-color);
// }
// .add-more-rolls-wrapper__close-btn {
// 	height: 36px;
// 	width: 36px;
// 	border-radius: 50%;
// 	display: flex;
// 	background-color: transparent;
// 	&:hover {
// 		background-color: #248fbd;
// 	}
// }
// .add-more-rolls-wrapper__close-icon {
// 	margin: auto;
// 	height: 22px;
// 	width: 22px;
// }
.add-more-rolls-wrapper__header-container {
  background-color: #0360a8;
  border-radius: 10px;
  p {
    color: white;
  }
}
