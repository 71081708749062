@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.advance_filter_main_container {
  // width: 100%;
  // margin: 0 auto;
  // max-height: calc(100vh - 145px);
  // overflow: auto;
  // position: relative;
  // z-index: 2;
  // overflow-y: auto;
  // height: calc(100vh - 44px);
  // max-height: calc(100vh - 44px);
  // padding: 10px;
}
.advance_filter_container {
  width: 90%;
  margin: 0 auto;
}
.field_container {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  .text {
    font-family: var(
      --uicomponents-font-family,
      "Lexend Deca",
      Helvetica,
      Arial,
      sans-serif
    );
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25em;
  }
  &.active {
    background-color: #e5f3ff;
  }
  &:hover {
    transition: all 500ms ease;
    background-color: #e5f3ff;
  }
}
.field_container_radio {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  .text {
    font-family: var(
      --uicomponents-font-family,
      "Lexend Deca",
      Helvetica,
      Arial,
      sans-serif
    );
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25em;
  }
}

.form-wrapper {
  width: 65%;
  margin: 0 auto;
}
.add-employee-wrapper__footer {
  // position: absolute;
  // bottom: 0;
  // margin-top: 30px;
  // margin-bottom: 30px;
}
.button-container {
  float: right;
  margin-top: 20px;
  margin-right: 60px;
  display: flex;
}
.deal_properties {
  margin-top: 10px;
}
.deal_properties_text_container {
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #efefef;
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  &:hover {
    background: #e5f3ff;
  }
}
.deal_properties_text {
  font-size: 16px;
  margin-left: 15px;
}
.container_1 {
}
.container_2 {
  margin-top: 20px;
  .text-header {
    font-size: 16px;
    font-weight: 500;
  }
}
.condition_view {
  border: 1px solid #dadce0;
  display: inline-block;
  width: 50px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  background-color: #f7f7f7;
}
.import_header_text {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #408dfb;
  &:hover {
    text-decoration: underline;
    transition: all 500ms ease;
  }
}
