.agent-shifts-card-container {
  height: calc(100vh - 64px);
  overflow-y: auto;

  .agent-shifts-card {
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;

    &:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
}
