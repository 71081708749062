.react-flow__node {
  // border: solid 1px #efefef;
  // border-radius: 7px;
  // border: 2px solid #fff;
  border-radius: 10px;
  // transition: all 0.2s ease-in;

  // &:hover {
  //   border: 2px solid #007bff;
  // }
}
.react-flow__node.selected {
  border: solid 1.5px #4285f4;
  border-radius: 11.5px;
}

.app-container {
  display: flex;
  height: calc(100vh - 44px);
  width: calc(100vw - 64px);
}

.flow-container {
  flex-grow: 1;
  position: relative;
}

.save-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #0056b3;
}

.settings-panel {
  padding: 20px;
  border-left: 1px solid #efefef;
  background-color: #fff;
  width: 300px;
}

.settings-panel h3 {
  margin-top: 0;
}

.settings-panel input {
  padding: 10px;
  // margin-top: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.nodes-panel {
  padding: 20px;
  width: 100%;
}

.nodes-panel h3 {
  margin-top: 0;
}

.nodes-panel {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  .chatbot_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #fff;
    outline: none;
    border: none;
    transition: all 0.2s ease-in;

    .chatbot-button-icons {
      color: white;
      border-radius: 8px;
      border: 1px solid #dadce0;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in;
    }

    &:hover {
      color: #007bff;

      .chatbot-button-icons {
        border: 1px dashed #4285f4;

        .chatbot-hover {
          fill: #4285f4 !important;
        }
      }
    }

    &:disabled {
      .chatbot-button-icons {
        background: #eee;
      }

      &:hover {
        color: #434d5f;

        .chatbot-button-icons {
          border: 1px dashed #dadce0;

          .chatbot-hover {
            fill: #aeaeae !important;
          }
        }
      }
    }
  }
}

.bot-link-container {
  .rdw-emoji-modal {
    top: 38px !important;
  }
}

.error-message {
  padding: 10px;
  border-radius: 5px;
  background-color: #fecaca;
  display: inline;
  font-weight: bold;
}

.error-center {
  display: flex;
  justify-content: center;
}

.custom-node {
  // border-radius: 5px;
  border-radius: 11px;
  width: 300px;
  font-family: Arial, sans-serif;
  // box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 8px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
  //   rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  overflow: hidden;
  transition: all 0.2s ease-in;
  background: #edf2f7;
  border: 2px solid #fff;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;

  &:hover,
  &.react-flow__node.selected {
    // background: #007bff;
    // color: #fff;
    border: 2px solid #007bff;
    .custom-node-delete {
      display: block;
    }
  }

  .custom-node-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: #666;
    font-weight: bold;
    padding: 10px;
    min-height: 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: -8px;
    border-bottom-left-radius: -8px;
    transition: all 0.2s ease-in;
    min-height: 46px;
  }

  .custom-node-delete {
    display: none;
    transition: all 0.2s ease-in;
  }

  .custom-node-icon {
    font-size: 16px;
  }

  .custom-node-body {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: #ffffff;
    padding: 15px;
    min-height: 80px;
  }

  .custom-button-body {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: #ffffff;
    padding: 24px 12px;
    max-height: 215px;
  }

  .custom-node-body-button {
    background: #ffffff;
    padding: 15px;
    min-height: 80px;
  }
}

.settings-panel-text {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.settings-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
}

input {
  padding: 5px;
  font-size: 14px;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
  transition: all 0.2s ease-in-out;
}

.edgebutton:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: red;
  color: #fff;
}

.flow_add_button {
  position: absolute;
  z-index: 999;
  border-radius: 25px;
  background-color: white;
}
.reactflow_start_button {
  border: none;
  display: flex;
  position: absolute;
  z-index: 999;
  border-radius: 25px;
  padding: 4px;
  left: 40px;
  top: 27px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  &:disabled {
    background-color: rgb(246, 246, 246);

    .rocket-wrapper {
      background-color: rgb(246, 246, 246);
    }
  }

  .rocket-wrapper {
    background-color: #e8fee8;
    border-radius: 50%;
    padding: 4px;
  }
}

.flow_add_header {
  background-color: #efefef;
  padding: 10px;
}

.react-flow__handle-right {
  right: -6px;
  /* top: 50%; */
  top: auto !important;
  transform: translate(0, -50%);
}
.react-flow__handle-left {
  top: 50%;
  left: -6px !important;
  transform: translate(0, -50%);
}
.output-item {
  border: 1px solid #dadce0;
  padding: 10px !important;
  border-radius: 5px;
  margin-bottom: 5px;
}

.reactflow_save_button {
  border: none;
  display: flex;
  position: absolute;
  z-index: 998;
  border: 1px solid #dadce0;
  border-radius: 25px;
  padding: 8px 6px;
  right: 30px;
  top: 27px;
  font-size: 16px;
  color: #4285f4;
  background-color: white;
  &:disabled {
    background-color: rgb(246, 246, 246);
  }
}
.reactflow_back_button {
  background-color: #fff;
  padding: 5px;
  color: #4285f4;
  border-radius: 25px;
  position: absolute;
  left: 30px;
  top: 25px;
  z-index: 999;
  font-size: 14px;
  &:disabled {
    background-color: rgb(246, 246, 246);
  }
}
.reactflow_test_button {
  background: transparent !important;
  position: absolute;
  right: 25px;
  bottom: 12px;
  width: 60px;
  height: 60px;
  z-index: 999;
  border-radius: 50%;
  outline: none;
  border: none;

  &:disabled {
    background-color: rgb(246, 246, 246);
  }

  .bot-profile-close {
    width: 100%;
    height: 100%;
    background: #007bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
}

.ant-popover-title {
  margin-bottom: 0 !important;
  min-width: fit-content !important;
}

.visitor_section {
  // background-color: #efefef;
  background-color: #f6f6f6;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.testbot_chatsection_left {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 10px;
  width: 70%;
  float: right;
}
.testbot_chatsection_right {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 10px;
  width: 70%;
  float: left;
}

.testbot_chatsection_option {
  border: 1px solid #efefef;
  background-color: white;
  padding: 10px;
  min-width: 200px;
  border-radius: 5px;
}
.testbot_link_option {
  border: 1px solid #efefef;
  background-color: white;
  padding: 10px;
  width: 100%;
  display: block;
}

.test-bot-container {
  height: calc(100vh - 78px);
  overflow: auto;
}
.bot_link_container {
  padding: 15px;
  border: 1px solid #00d07b;
  border-radius: 5px;
  // background-color: #f8f9fa;
}
.bot-link-container {
  overflow: auto;
  height: calc(100vh - 147px);
}
.bot_filepath {
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 5px;
}

.bot-testing-wrapper {
  position: relative;
  z-index: 2;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100vh - 76px);
}

.react-flow__controls {
  box-shadow: none !important;
  display: grid;
  gap: 12px;
}

.react-flow__controls-button {
  border-bottom: none !important;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 16px;
}

.react-flow-mini-map {
  left: 64px !important;
  width: 200px;
}

.bot-profile {
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  background: #fff;
}

.wa-bot-profile {
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  background: #4dc247;
  padding: 10px;
}

.test-modal-wrapper {
  right: 10px !important;
  bottom: 80px !important;
}

.test-modal-wrapper,
.test-bot-modal-wrapper {
  position: absolute;
  z-index: 1000 !important;
  width: 400px !important;
  overflow: hidden !important;
  background-color: #fefefe !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 10px !important;
  height: calc(100vh - 200px) !important;

  .test-bot-header,
  .test-bot-footer {
    padding: 10px;
    height: 60px;
  }

  .test-bot-header {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;

    .bot-header-button {
      background: none;
      outline: none;
      border: none;
    }

    .bot-close {
      font-size: 28px;
      transition: all 0.2 ease-in-out;

      &:hover {
        color: #aaa;
      }
    }
  }

  .test-bot-body {
    height: 100%;
  }

  .test-bot-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .powered-text {
      color: #9f9f9f;
      font-size: 12px;
    }

    .test-footer-link {
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
  }
}

.chatbot_settings_container {
  max-height: calc(100vh - 180px);
  overflow: auto;
}

.webbot_settings_container {
  max-height: calc(100vh - 116px);
  overflow: auto;
}

.react-flow__pane {
  overflow: auto !important;
}

.chatbot_share_container {
  overflow: auto;
  max-height: calc(100vh - 100px);
}
