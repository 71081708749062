.horizontal-nav {
  display: flex;
  height: 44px;
  width: 100vw;
  background: #eaf1fb;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  position: relative;
  z-index: 999;
}

[data-bs-theme="dark"] {
  .horizontal-nav {
    background-color: var(--antd-background-color);
  }

  .horizontal-nav::after,
  .horizontal-nav-open::after {
    background: var(--antd-background-color);
  }

  .sidebar-layout {
    .side-header-wrapper {
      background-color: var(--antd-background-color);
    }
  }
}

.horizontal-nav::after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -14px;
  left: 48px;
  background: #eaf1fb;
  z-index: 9999;
  mask: radial-gradient(rgba(0, 0, 0, 0) 71%, #000 72%) 10000% 10000% / 99.5%
    99.5%;
}

.horizontal-nav-open::after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -14px;
  left: 234px !important;
  background: #eaf1fb;
  z-index: 9999;
  mask: radial-gradient(rgba(0, 0, 0, 0) 71%, #000 72%) 10000% 10000% / 99.5%
    99.5%;
}

.sidebar-layout {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 44px);
  width: 100%;

  .sidebar-layout-content {
    width: calc(100vw - 64px);
  }

  .side-header-wrapper {
    display: flex;
    flex-direction: column;
    //   width: 64px;
    max-width: 64px;
    position: relative;
    justify-content: space-between;
    padding: 8px;
    background: #eaf1fb;
    z-index: 998;

    .sidebar_items__wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      // padding-left: 12px;
      align-items: flex-start;
    }

    .sidebar_items__wrapper-open {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      // padding-left: 12px;
    }

    .sidebar_items__wrapper,
    .sidebar_items__wrapper-open {
      margin: 0 4px;

      .bookmark-item {
        // border-bottom: 1px solid #e0e0e0;
        padding: 4px 0 -2px 0;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        transition: all 0.3s ease-in-out;
        border-radius: 8px;
        color: var(--theme-font-heading-secondary-color);
        gap: 10px;
        font-size: 14px;

        &:hover {
          background: rgba(var(--primary-color), 0.2);
          // #ab4fee
          // background: #c2e7ff;
          color: rgba(var(--primary-color), 1);
        }

        a {
          color: var(--theme-font-heading-secondary-color);
        }
      }
      .navbar_item {
        width: 100%;
        .sidebar-nav-link {
          height: 36px;
          display: flex;
          align-items: center;
          padding: 3px 10px;
          transition: all 0.3s ease-in-out;
          border-radius: 8px;
          color: var(--theme-font-heading-secondary-color);
          gap: 10px;
          font-size: 14px;
          position: relative;

          &:hover {
            background: rgba(var(--primary-color), 0.2);
          }
        }

        .active {
          background: rgba(var(--primary-color), 0.2);
          height: 36px;
          display: flex;
          align-items: center;
          padding: 3px 10px;
          transition: all 0.3s ease-in-out;
          border-radius: 8px;
          // color: rgba(var(--primary-color), 1) !important;
          width: 100%;
          gap: 10px;
          font-size: 14px;
          color: rgba(var(--primary-color), 1);
          // color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));

          &:hover {
            background: rgba(var(--primary-color), 0.2);
          }
          svg {
            color: rgba(var(--primary-color), 1);
          }
        }

        .whatsapp-msg-notification {
          position: absolute;
          top: 0px;
          right: 2px;
        }
      }

      .navbar_dropdown_item {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        transition: all 0.3s ease-in-out;
        border-radius: 8px;
        color: rgba(var(--primary-color), 1) !important;
        width: 100%;
      }

      .navbar_item_active {
        background: rgba(var(--primary-color), 0.2);
        height: 36px;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        transition: all 0.3s ease-in-out;
        border-radius: 8px;
        color: rgba(var(--primary-color), 1) !important;
        width: 100%;
        font-size: 14px;

        .active {
          color: rgba(var(--primary-color), 1);
        }

        &:hover {
          background: rgba(var(--primary-color), 0.2);
        }
        svg {
          color: rgba(var(--primary-color), 1);
          // fill: rgba(var(--primary-color), 1) !important;
        }
      }
      .navbar_nav__link {
        display: flex;
        gap: 10px;
        color: var(--theme-font-heading-secondary-color);
        font-size: 14px;

        svg {
          color: var(--theme-font-heading-secondary-color);
        }
      }

      .navbar_nav__link-active {
        color: rgba(var(--primary-color), 1);
        svg {
          color: rgba(var(--primary-color), 1);
        }
      }

      .navbar_item {
      }
    }
  }
}

/* Add these styles to your existing CSS */
.sidebar-layout .side-header-wrapper {
  /* ... existing styles ... */
  position: relative; /* Make sure this is present */
}

.submenu-dropdown {
  // position: absolute;
  // top: 0;
  // background: red;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  padding: 2px;
  background: #fff;

  // .ant-menu {
  //   background: #fff;
  // }

  .navbar-dropwdown-divider {
    margin: 8px 0;
  }

  .ant-menu-item-group-title {
    padding: 0px;
  }

  .ant-menu-item-group-list {
    .ant-menu-item {
      padding-inline: 0 !important;
    }

    .sidebar-submenu-active {
      background: rgba(var(--primary-color), 0.2);
      color: rgba(var(--primary-color), 1);
      font-weight: 500 !important;
      font-size: 14px !important;
      padding: 0 16px !important;

      .subnav_dropdown_item {
        color: rgba(var(--primary-color), 1) !important;
      }
    }

    .sidebar-submenu {
      padding: 0 16px !important;
    }

    .sidebar-submenu-active,
    .sidebar-submenu {
      .ant-menu-title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
}

.no-bookmark-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  max-width: 192px;
  flex-direction: column;

  .bookmark-demo-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 8px 10px;
    border-radius: 6px;
    background: rgba(var(--primary-color), 0.2);
    width: 90%;
  }

  .bookmark-demo-item-text {
    height: 12px;
    width: 60px;
    background: rgba(var(--primary-color), 0.2);
    border-radius: 4px;
  }
}

.submenu-dropdown {
  .custom-sidebar-submenu {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 0 16px;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    margin: 4px 0;
    margin-inline: 4px;
    margin-block: 4px;
    width: calc(100% - 8px);

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  .custom-sidebar-submenu-active {
    background: rgba(var(--primary-color), 0.2);
    color: rgba(var(--primary-color), 1);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 0 16px;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    margin: 4px 0;
    margin-inline: 4px;
    margin-block: 4px;
    width: calc(100% - 8px);

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .custom-sidebar-submenu,
  .custom-sidebar-submenu-active {
    .bookmark-remove-btn {
      display: none;
    }

    &:hover {
      .bookmark-remove-btn {
        display: block;
      }
    }
  }
}
