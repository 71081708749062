@import "@scss/common/_variables.scss";

.inline_dropdown_image {
  width: 24px;
}
.inline_close_image_container {
  width: 22px;
  height: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
  .inline_close_image {
    width: 18px;
    cursor: pointer;
  }
  border-radius: 50%;
  &:hover {
    transition: all 100ms ease-in;
    background-color: #c5dbff;
    border-radius: 50%;
  }
}

.inline_popup_active {
  background-color: #e8f0fe;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}
.header_text {
  color: $theme-main-text-color;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  font-family: $font-family;
}
.popup-content {
  margin: auto;
  // width: 20% !important;
}

.smaller-width-content {
  width: 100px !important;
}

.attach-pop-up-content {
  top: 0 !important;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
.reactjs-popup-content {
  transition: opacity 0.3s ease-in-out;
}

// ------- Edit Tooltip ---------- //
.inline_tooltip_edit_container {
  min-height: 5vh;
  padding: 10px !important;
  transition: all 1s ease-in-out;
  width: 400px;
}
.inline_toolto_footer_container {
  z-index: 1;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}
.edit_header-image {
  height: 18px;
}

// -------------- Tooltip (Text Header Tooltip) -------------//

.filter-select-form-inline {
  position: relative;
  color: #333;
  cursor: default;
  // width: 300px;
  border-radius: 5px;
}
.filter-select-form-inline .arrow.open {
  border-color: transparent transparent #dadce0;
  border-width: 0 5px 5px;
}
.filter-select-form-inline input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 4px !important;
  box-sizing: border-box;
  cursor: default;
  padding: 8px 69px 8px 14px;
  padding-left: 14px !important;
  transition: all 200ms ease;
  &::placeholder {
    color: #dadce0;
  }
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  // border: 1px solid #ccc;
  // padding: 8px;
  // transition: border-color 0.3s, background-color 0.3s;

  &:focus {
    border-color: #408dfb;
    background: #f2f2ff;
    box-shadow: none;
    outline: none;
  }
}
.filter-select-form-inline .options {
  display: none;
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 275px;
  // overflow-y: auto;
  position: absolute;
  top: 100%;
  z-index: 999;
  width: 415px;
  // padding: 7px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  // box-shadow: rgba(0, 0, 0, 0) 0px 4px 5px, rgba(0, 0, 0, 0.1) 0px -12px 30px,
  //   rgba(0, 0, 0, 0) 0px 4px 6px, rgba(0, 0, 0, 0.1) 0px 1px 1px,
  //   rgba(0, 0, 0, 0.09) 0px -1px 1px;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid rgb(203, 219, 247);
}
.filter-select-form-inline .options:after {
  content: " ";
  position: absolute;
  left: 30px;
  top: -12px;
  border-top: none;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid rgb(203, 219, 247);
}
.filter-select-form-inline .options.open {
  display: block;
  margin-top: 5px;
  border-radius: 5px;
}
.filter-select-form-inline .option {
  box-sizing: border-box;
  color: #33475b;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 14px;
  .option_desc {
    font-size: 12px;
    color: #516f90 !important;
  }
}
.filter-select-form-inline .option.selected {
  // background-color: #e8f0fe;
  background-color: #f2f3ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
.filter-select-form-inline .option:hover {
  background-color: #f1f2f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
    font-weight: 500;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}

//------ inline search -----//
.filter-select-form-inline-inline {
  position: relative;
  color: #333;
  cursor: default;
  // width: 300px;
  border-radius: 5px;
}
.selected_filter_box_inline {
  /* line-height: 1.5; */
  border-radius: 2px;
  box-sizing: border-box;
  /* padding: 8px 10px 8px 10px; */
  width: 200px;
  /* height: 40px; */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  p {
    color: $theme-main-text-color;
    font-weight: 500;
    // font-family: "Lexend Deca",Helvetica,Arial,sans-serif;
    font-size: 16px;
  }
  &::placeholder {
    color: #dadce0;
  }
  &:hover {
    // background-color: #eaf0f6;
  }
  .inline_dropdown {
    // height: 22px;
    width: 22px;
  }
  // transition: all 3s ease-in-out;
}

.image_hover_background {
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 50ms ease-in;
  border-radius: 50%;
  // border: 1px solid #c5dbff;
  &:hover {
    // background-color: #c5dbff;
    border-radius: 50%;
    border: 1px solid #c5dbff;
  }
}
