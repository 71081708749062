@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.create-user-form-body__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.create-user-form-body__label {
  font-size: 14px;
}

.create-user-form-body__roles-wrapper {
  margin-top: 8px;
}

.create-user-form-body__roles-wrapper-title {
  margin-bottom: 8px;
}

.create-user-form-body__add-roles-list-cta {
  color: var(--theme-button-primary-color);
  font-weight: $medium-font-weight;
  margin: 12px 0 24px;
  img {
    margin-right: 8px;
  }
}

.create-user-form-body__show-more-roles {
  color: var(--theme-blue-color);
  font-weight: $medium-font-weight;
}
.error-text {
  color: $red-color;
  font-size: 12px;
  margin-top: 8px;
}

.form-wrapper__form-group--error {
  .custom-form-control {
    border-color: $red-color;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: $theme-border-color;
    }
  }
}

.header-input-editable{
  color: #fff!important;
  &:focus{
    color: #000!important;
  }
}

.form-control-date {
  width: 100%;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250);
  background-color: transparent !important;
  // height: 44px;
  line-height: 18px;
  padding: 10px 16px;
  &::placeholder {
    @include genearteRemFontSize(14);
    color: #c0c1c3;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid #86b7fe;
    background-color: white !important;
  }
}