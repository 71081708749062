@import "@scss/common/_variables.scss";

.common_table {
  thead {
    tr {
      td {
        h6 {
          font-weight: 500;
          font-size: 16px;
        }
        p {
          font-size: 13px;
        }
        height: 45px;
        background-color: #f8f8ff;
        border: 1px solid #dadce0;
        padding-left: 20px;
        font-size: 15px;
        color: #1f1f1f;
        min-width: 200px;
        // padding-left: 3%;
        // padding-right: 3%;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        height: 45px;
        border: 1px solid #dadce0;
        padding-left: 20px;
        font-size: 15px;
        // padding-left: 3%;
        // padding-right: 3%;
        h6 {
          font-weight: 400;
          font-size: 16px;
        }
        p {
          font-size: 13px;
        }
      }
      &:hover {
        background-color: rgba(var(--primary-color), 0.06);
      }
    }
  }
}

.common_detail_table {
  width: 100%;
  overflow-x: auto;
  width: 100%;
  table-layout: fixed; // Ensure a fixed layout
  thead {
    tr {
      td {
        height: 45px;
        background-color: #fff;
        border-bottom: 1px solid #dadce0;
        padding-left: 20px;
        font-size: 15px;
        color: #1f1f1f;
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        height: 45px;
        border-bottom: 1px solid #dadce0;
        padding-left: 20px;
        font-size: 13px;
      }
      &:hover {
        background-color: rgba(var(--primary-color), 0.06);
      }
    }
  }
}

.hide-hover {
  position: relative; /* Make td a positioning context */
}

.hidden-button {
  opacity: 0; /* Hide the button initially */
  position: absolute; /* Position the button absolutely within the td */
  top: 50%; /* Adjust the vertical position */
  left: 70%; /* Adjust the horizontal position */
  transform: translate(-50%, -50%); /* Center the button */
  transition: all 1s ease;
}

.hide-hover:hover .hidden-button {
  opacity: 1; /* Show the button on hover */
}
