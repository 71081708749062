@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.dashboard-button {
  border-radius: 20px;
  // height: 40px;
  // line-height: 40px;
  // padding: 0 16px;
  padding: 8px;
  background-color: var(--white-color);
  border: 1px solid var(--theme-border-color);
  font-size: 14px;
  white-space: nowrap;
  @extend %transition;
}
.dashboard-button--terciary {
  color: var(--theme-button-primary-color);
  &:hover {
    background-color: var(--theme-button-primary-color);
    color: var(--white-color);
    border-color: var(--theme-button-primary-color);
  }
}

.dashboard-button--archive {
  color: $theme-font-terciary-color;
  &:hover {
    background-color: var(--theme-button-primary-color);
    color: var(--white-color);
    border-color: var(--theme-button-primary-color);
  }
}

.dashboard-button--primary {
  // font-weight: $light-font-weight;
  font-weight: 400;
  // background-color: var(--theme-button-primary-color);
  background-color: rgba(var(--button-primary), 1);
  color: var(--white-color);
  // &:hover {
  // 	color: var(--theme-button-primary-color);
  // 	background-color: var(--white-color);
  // 	border-color: var(--theme-button-primary-color);
  // }
  &:disabled {
    font-weight: $light-font-weight;
    color: white;
    background-color: #d9dbdf;
  }
}
.dashboard-button--smart {
  font-weight: $light-font-weight;
  color: rgba(var(--button-primary), 1);
  background-color: var(--white-color);
  // border-color: var(--theme-button-primary-color);
  &:hover {
    background-color: rgba(var(--button-primary), 1);
    color: var(--white-color);
  }
  &:disabled {
    font-weight: $light-font-weight;
    color: white;
    background-color: #d9dbdf;
  }
}
.dashboard-button--secondary {
  font-weight: $light-font-weight;
  color: var(--theme-button-primary-color);
  background-color: var(--white-color);
  // &:hover {
  // 	background-color: var(--theme-button-primary-color);
  // 	color: var(--white-color);
  // 	border-color: var(--theme-button-primary-color);
  // }
  &:disabled {
    font-weight: $light-font-weight;
    color: white;
    background-color: #d9dbdf;
  }
}

.dashboard-button--green {
  background-color: #52b67e;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #52b67e;
  }
}
.dashboard-button--green_outline {
  color: #52b67e;
  background-color: #fff;
  &:hover {
    background-color: #52b67e;
    color: var(--white-color);
  }
}

.dashboard-button--delete {
  background-color: #ff6667;
  color: #fff;
  &:hover {
    color: #ff6667;
    background-color: #fff;
  }
}
