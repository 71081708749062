.submit-button__footer {
  width: 100%;
  height: 70px;
  // z-index: 1000;
  bottom: 0;
  position: fixed;
  right: 0;
  background-color: rgb(245, 248, 250) !important;
  border-top: 1px solid rgb(203, 214, 226);
  overflow: hidden;
  .submit-button__container {
    position: absolute;
    right: 30px;
  }
}

// .ant-modal .cancel__button {
//   // color: #ffffff !important; // White text color
//   // border: 1px solid #ff6667;
//   span {
//     // color: #ff6667;
//   }
//   &:hover {
//     border: 1px solid #d11a2a !important;
//   }
// }

// .ant-modal .submit__button {
//   // background-color: #1890ff !important; // Blue background
//   color: #ffffff !important; // White text color
//   border: none !important; // Remove border
//   span {
//     color: white;
//   }
//   &:hover {
//     background-color: #1077e3 !important; // Darker blue on hover
//   }
// }
