.products-support-card {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  width: 100%;
  background: #f5f7f9;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.reverted-button {
  all: unset;

  &:disabled {
    color: #aeaeae;
  }
}

.reverted-button:focus {
  outline: revert;
}

.products-list-card {
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
