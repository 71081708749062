@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";
.manage-field__navigation {
  max-width: 257px;
  min-width: 257px;
  width: 100%;
  height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  // padding: 24px 25px;
  border-right: 1px solid #dadce0;
}
.manage-field__checks {
  margin-bottom: 9px;
}
.manage-field__checks-text {
  color: #707070;
  margin-left: 11px;
  @include genearteRemFontSize(14);
  font-weight: $normal-font-weight;
}
label.manage-field__form-group-label {
  color: var(--theme-font-heading-secondary-color);
  margin-bottom: 8px;
  @include genearteRemFontSize(16);
  font-weight: $medium-font-weight;
}
.manage-field__form-group {
  margin-bottom: 8px;
}
.manage-field__text {
  color: var(--theme-grey-color);
  @include genearteRemFontSize(14);
  margin-bottom: 19px;
}
.input_container {
  .input_search {
    left: 24px;
    top: 22px;
  }
}
