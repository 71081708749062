.config-container {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 24px;

  background: white;
  margin-top: 24px;

  .avatar-selector-wrapper .ai-chatbot-avatar {
    max-width: 500px !important;
  }

  .sidebar {
    border-right: 1px solid #eee;
    padding-right: 24px;
    min-height: calc(100vh - 200px);
    .menu-items {
      .menu-item {
        display: flex;
        align-items: center;
        padding: 12px 20px;
        cursor: pointer;
        color: #353535;
        height: 32px;
        line-height: 32px;
        border-radius: 24px;
        font-size: 14px;
        margin-bottom: 4px;

        &:hover,
        &.active {
          background: #0000000f;
        }

        svg {
          margin-right: 8px;
        }

        &.active {
          color: #000;
          background: #d4e4fc;
        }
      }
    }
  }

  .main-content {
    flex: 1;
    // padding: 24px 32px;
    width: 500px;

    // height: calc(100vh - 200px);
    // overflow: auto;
    margin-bottom: 24px;

    .embed-options {
      margin-top: 24px;

      .option-card {
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;

        .option-content {
          margin-left: 8px;
          display: grid;

          .option-header {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 4px;

            .recommended {
              font-size: 12px;
              color: #6941c6;
              background: #f5f0ff;
              padding: 2px 8px;
              border-radius: 12px;
            }
          }
        }
      }
    }

    .configuration-section {
      margin-top: 24px;

      .code-block {
        background: #f9fafb;
        border-radius: 8px;
        margin: 16px 0;

        .code-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          border-bottom: 1px solid #eee;

          .line-numbers {
            color: #666;

            span {
              margin-right: 16px;
            }
          }
        }

        .code-content {
          padding: 16px;
          margin: 0;
          white-space: pre-wrap;
        }
      }

      .verification-title {
        margin-top: 40px;
      }

      .secret-key {
        margin: 16px 0;
      }

      .helper-text {
        display: block;
        margin: 16px 0;
      }

      .warning-message {
        display: flex;
        gap: 12px;
        padding: 16px;
        background: #fffbeb;
        border-radius: 8px;
        margin-top: 24px;

        .anticon {
          color: #d97706;
        }
      }
    }
  }
}

.share-container {
  .website-url {
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .helper-text {
    display: block;
    color: #666;
    margin-bottom: 16px;
  }

  .url-display {
    background: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
    font-family: monospace;
    font-size: 14px;
    margin-bottom: 16px;
    word-break: break-all;
  }

  .action-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    .action-button {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      padding: 4px 12px;

      .anticon {
        font-size: 16px;
      }
    }
  }
}
