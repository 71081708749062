.template-sample-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
  overflow-y: auto;
  height: calc(100vh - 272px);

  .template-sample-card {
    padding: 1rem;
    background: #f0f2f67a;
    border-radius: 8px;

    .template-car-top-section {
      margin-bottom: 0.6rem;

      .sample-category {
        color: var(--rt-color-info);
        background: #00adff1f;
        display: flex;
        align-items: center;
        padding: 0.3rem 1rem;
        border-radius: 6px;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }

    .template-car-bottom-section {
      .sample-message {
        font-size: 0.9rem;
      }

      .use-template-button {
        font-weight: 500;
      }
    }
  }
}

.tab-headers {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
}

.tab-button.active {
  border-bottom: 2px solid #1890ff;
  color: #1890ff;
  font-weight: bold;
}

.tab-content {
  // padding: 20px;
  // border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  // border-top: none;
}

.d-flex {
  display: flex;
}

.gap-4 {
  gap: 16px;
}

.p-4 {
  padding: 16px;
}

.w-50 {
  width: 50%;
}

.mt-2,
.my-2 {
  margin-top: 8px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}
