.task_detail_right-container {
  flex-grow: 1;
  width: 75%;
  border-right: 1px solid #dadce0;
  height: 100vh;
}
.task_detail_right-header_section {
  height: 90px;
  border-bottom: 1px solid #dadce0;
}
.task_detail_right-header_section_button {
  border: 1px solid #dadce0;
  padding: 7px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_detail_right-left_section_container {
  display: flex;
}
.task_detail_right-left_section1_container {
  display: grid;
  grid-template-columns: 200px 1fr; /* Adjust the fixed left side width (200px in this example) */
  grid-gap: 30px;
  // display: flex;
  // align-items: center;
  // justify-content: space-around;
  margin: 0 auto;
  .task_detail_right-left_section1_left {
    font-size: 14px;
    font-weight: 500;
    color: #1f1f1f;
    width: 50%;
    text-align: right;
  }
  .task_detail_right-left_section1_right {
    font-size: 14px;
    color: #5f6368;
    width: 50%;
    text-align: left;
  }
}

.task_detail_right-left_section {
  flex-grow: 1;
  width: 70%;
  border-right: 1px solid #dadce0;
  height: calc(100vh - 64px - 90px);
  overflow: auto;
  background-color: #edf0f4;
}
.task_detail_right-left_section_update_section {
  flex-grow: 1;
  width: 70%;
  border-right: 1px solid #dadce0;
  height: calc(100vh - 64px - 90px - 56px);
  overflow: auto;
  background-color: #edf0f4;
}
.task_detail_right-left_section1 {
  min-height: 30vh;
  background-color: white;
  border-radius: 10px;
  margin: 2%;
  position: relative;
}

.task_detail_right-left_tags {
  min-height: 10vh;
  background-color: white;
  border-radius: 10px;
  margin: 2%;
  position: relative;
}
.task_detail_right-right_section {
  flex-grow: 1;
  width: 30%;
  height: calc(100vh - 64px - 90px);
  overflow: auto;
}
.task_detail_right-right_section_update_section {
  flex-grow: 1;
  width: 30%;
  height: calc(100vh - 64px - 90px - 56px);
  overflow: auto;
}
.task_close_button {
  // color: white;
  // border-radius: 6px;
  // box-sizing: border-box;
  // padding: 8px 20px;
  // box-shadow: inset 0 -2px 0 #0061ca;
  // background: transparent linear-gradient(0deg, #0279ff 0%, #00a3f3 100%);
  // font-size: 14px;
  // border: 0;
  // outline: 0;
  // cursor: pointer;
  // text-align: center;
  // margin-right: 8px;
  // white-space: nowrap;
  position: absolute;
  right: 12px;
  top: 16px;
  // &:hover {
  //   transition: all 100ms ease;
  //   background: transparent linear-gradient(0deg, #2383f2 0%, #13a8f2 100%);
  // }
}

.tasks_notes_container {
  .tasks_notes-textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    border: 1px solid #dadce0;
    // height: 45px;
    padding: 8px 10px;
    &::placeholder {
      color: #c6c8cb;
      font-size: 14px;
    }
    &:focus {
      // min-height: 85px;
      // max-height: 200px;
      border: 1px solid #0279ff;
      border-radius: 5px;
    }
  }
  .tasks_notes_footer {
    text-align: right;
    margin-top: 10px;
  }
  .tasks_notes_footer {
  }
}

.tag_task_container {
  min-height: 35px;
}

.detail_header_container {
  min-height: 35px;
  // background-color:#f2f4fa;
  // border: 0.5px solid #cbd6e2;
  padding: 5px;
  border-radius: 5px;
  .pending-wrapper-hover_edit_section {
    opacity: 0;
  }
}

.detail_header_container:hover .pending-wrapper-hover_edit_section {
  opacity: 1;
  transition: all 1s ease-in;
}

.task_note_main-container {
  cursor: pointer;
  transition: 0.4s all ease-in-out;
  &:hover {
    opacity: 1;
  }
  .task_notes_action_container {
    opacity: 0;
    transition: opacity 0.4s ease-in-out; /* Optional transition for smoother effect */
  }
  &:hover .task_notes_action_container {
    opacity: 1;
  }
}
