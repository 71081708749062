@import "@scss/common/_variables.scss";

.rdw-editor-toolbar {
  padding: 6px 20px 0 !important;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
  // position: absolute;
  // bottom: 50px;
  z-index: 2;
  height: 45px;
}
.public-DraftStyleDefault-block {
  margin: 0.3rem 0;
}

.DraftEditor-root {
  position: relative;
  // height: 5vh;
  margin-bottom: 3vh;
  max-height: 64vh;
}
.public-DraftEditor-content[contenteditable="true"] {
  font-size: 16px !important;
  font-family: $font-family !important;
  font-weight: 350 !important;
}
.email_draft_container {
  overflow: auto;
  // max-height: calc(50vh);
  width: 100%;
}
.notes_editor_container {
  overflow: auto;
  max-height: calc(50vh - 45px);
  height: calc(50vh - 45px);
}

.email-message-body {
  &::placeholder {
    color: #cfcfcf;
    font-size: 13px;
    margin-bottom: 4px;
    font-weight: 300;
  }
}

.public-DraftEditor-content[contenteditable="true"] {
  color: #cfcfcf;
  font-size: 13px;
  margin-bottom: 4px;
  font-weight: 300;
}

.emailToolbar {
  // position: absolute;
  // bottom: 55px;
  // left: 0;
  // width: 100%;
  // position: absolute;
  display: none; /* Initially hidden */
}
.emailToolbarActive {
  display: flex; /* Initially hidden */
}
.wrapperClassName {
  // display: flex;
  // flex-direction: column-reverse;
}

.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: -180px;
  left: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.editor-container {
  position: relative;
  box-sizing: border-box;
  flex: 1 1 auto;
  width: inherit;
  min-width: 0;
  min-height: 20px;
  padding: 9px 12px;
  margin: 5px 8px;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  background-color: white;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  outline: none;
  will-change: width;
  display: flex;
}

.editor-input {
  outline: none;
  min-height: 20px;
}

.editor-placeholder {
  color: #aaa;
  position: absolute;
  pointer-events: none;
  top: 10px;
}
.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  overflow: auto;
  height: auto;
  /* height: 36px; */
}
.character-style-popup {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  z-index: 10;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  height: auto;
  /* height: 35px; */
}

.ContentEditable__root {
  border: 0;
  resize: none;
  cursor: text;
  font-size: 15px;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 10px;
  overflow: auto;
  resize: vertical;
  min-height: 10px !important;
}

.Placeholder__root {
  font-size: 15px !important;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 20px !important;
  left: 25px !important;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}
.PlaygroundEditorTheme__listItem {
  margin: 0px 0px !important;
}

.editorClassName {
  min-height: 10px !important;
}

.bot-editorClassName {
  min-height: 150px !important;
}

.broadcast-editorClassName {
  min-height: 200px !important;
}
