$toolbar-bg: #ffffff;
$toolbar-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$toolbar-border: #e5e7eb;

// .floating-toolbar {
//   position: fixed;
//   transform: translateX(-50%);
//   background: $toolbar-bg;
//   border: 1px solid $toolbar-border;
//   border-radius: 0.375rem;
//   box-shadow: $toolbar-shadow;
//   padding: 0.25rem;
//   display: flex;
//   gap: 0.25rem;
//   z-index: 1000;
//   animation: fadeIn 0.2s ease-out;

//   button {
//     padding: 0.375rem;
//     border: none;
//     background: none;
//     border-radius: 0.25rem;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     color: #4b5563;
//     transition: all 0.2s;

//     &:hover {
//       background-color: #f3f4f6;
//       color: #111827;
//     }

//     svg {
//       width: 1rem;
//       height: 1rem;
//     }
//   }
// }
$gray-color: #606060;
$hover-bg: #f5f5f5;

.format-toolbar {
  display: flex;
  gap: 8px;

  button {
    padding: 8px;
    border: none;
    background: none;
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: $hover-bg;
    }

    .icon {
      width: 20px;
      height: 20px;
      color: $gray-color;
    }
  }
}

$toolbar-bg: #ffffff;
$toolbar-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$toolbar-border: #e5e7eb;
$toolbar-hover: #f3f4f6;
$toolbar-text: #4b5563;
$toolbar-text-hover: #111827;

.floating-toolbar {
  position: fixed;
  transform: translateX(-50%);
  background: $toolbar-bg;
  border: 1px solid $toolbar-border;
  border-radius: 0.375rem;
  box-shadow: $toolbar-shadow;
  padding: 0.25rem;
  display: flex;
  gap: 0.25rem;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;

  button {
    padding: 0.375rem;
    border: none;
    background: none;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $toolbar-text;
    transition: all 0.2s;

    &:hover {
      background-color: $toolbar-hover;
      color: $toolbar-text-hover;
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}