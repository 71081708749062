@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';

.thankyou-wrapper {
  margin: 54px auto;
  text-align: center;
}
.get-started {
  max-width: 364px;
  margin: 0 auto;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    margin: 16px auto;
  }
}
.thankyou-wrapper__title {
  color: $theme-font-heading-primary-color;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    @include genearteRemFontSize(24);
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    @include genearteRemFontSize(30);
  }
}
.thankyou-wrapper__sub-title {
  margin: 16px 0 54px;
  max-width: 796px;
  line-height: 1.2;
  font-weight: $normal-font-weight;
  color: $theme-primary-color;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    @include genearteRemFontSize(18);
    margin: 8px 0;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    @include genearteRemFontSize(18);
    margin: 8px 0;
  }
}
