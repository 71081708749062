@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';
@import '@scss/common/_extends.scss';

.pending-wrapper__main-section-title {
	color: var(--theme-font-heading-secondary-color);
	font-weight: $medium-font-weight;
	@include genearteRemFontSize(16);
}

.pending-task-commontitle__link {
	display: flex;
	height: 36px;
	width: 36px;
	border-radius: 50%;
	background-color: #f7fbff;
	cursor: pointer;
}
.pending-task-commontitle__link-add {
	margin: auto;
}

.pending-task-commontitle__text {
	@include genearteRemFontSize(12);
	color: var(--theme-button-primary-color);
	font-weight: $light-font-weight;
	cursor: pointer;
}
.pending-wrapper__main-section-title-count {
	margin-left: 4px;
}

.active {
	// transform: rotate(-180deg);
}
.pending-wrapper__downarrow-icon {
	transition: 0.3s all ease-in-out;
}
