.loader-container {
  width: 100%;
  //  height: calc(80vh - 135px);
  height: calc(100vh - 135px);
  cursor: pointer;
  justify-content: center;
  display: flex;
}
.loader-container-small {
  width: 100%;
  //  height: calc(80vh - 135px);
  // height: calc(100vh - 135px);
  cursor: pointer;
  justify-content: center;
  display: flex;
}
.loading-wrapper__details {
  margin: auto;
}
.loader-wrapper__image {
  height: 500px;
  width: 500px;
  // text-align: center;
}

.loader-container-list {
  width: 100%;
  //  height: calc(80vh - 135px);
  height: 10vh;
  cursor: pointer;
  justify-content: center;
  display: flex;
}
.loading-wrapper__details {
  margin: auto;
}
.loader-wrapper__image {
  height: 500px;
  width: 500px;
  // text-align: center;
}

.lottie-container {
  width: 100%;
  height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center; /* This centers it vertically */
  cursor: pointer;
}

.noaccess-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; /* This centers it vertically */
  cursor: pointer;
}