// .modal {
//   font-size: 12px;
// }
// .modal > .header {
//   width: 100%;
//   border-bottom: 1px solid gray;
//   font-size: 18px;
//   text-align: center;
//   padding: 5px;
// }
// .modal > .content {
//   width: 100%;
//   padding: 10px 5px;
// }
// .modal > .actions {
//   width: 100%;
//   padding: 10px 5px;
//   margin: auto;
//   text-align: center;
// }
// .modal > .close {
//   cursor: pointer;
//   position: absolute;
//   display: block;
//   padding: 2px 5px;
//   line-height: 20px;
//   right: -10px;
//   top: -10px;
//   font-size: 24px;
//   background: #ffffff;
//   border-radius: 18px;
//   border: 1px solid #cfcece;
// }
.filter-image {
  height: 30px;
  cursor: pointer;
}
.import-contact-wrapper-content {
  margin: 7% 10% 4% 10% !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 10px;
  width: 100%;

  .gradient-strip {
    background: linear-gradient(
      to right,
      rgba(0, 168, 134, 0),
      #e57300,
      rgba(0, 168, 134, 0)
    );
    position: absolute;
    top: 0;
    height: 1.5%;
    left: 0;
    width: 100%;
  }

  .import-contact-close {
    border: none;
    background: none;
    outline: none;
    // width: 18px;
    top: 20px;
    right: 20px;
    position: absolute;
  }

  .contact-bottom-buttons {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.4);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
  }

  .import-contact-container {
    // background: white;
    margin: 0;
    // margin-top: 3px;
    padding-top: 0.8%;
    height: -webkit-fill-available;
  }

  .import-contact-body {
    height: calc(100% - 55px);
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;

    h3 {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      padding-top: 50px;
    }

    p {
      font-size: 14px;
      text-align: center;
      // padding-top: 10px;
      color: #333;
    }

    .options-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      margin-top: 20px;

      .company-logo {
        width: 100%;
        max-width: 35px;
        max-height: 35px;
        height: 100%;
      }
    }

    .contact-type {
      display: flex;
      align-items: center;
      flex-direction: column;

      .radio-wrapper {
        label {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 20px;
          height: 200px;

          p {
            color: #666;
            font-size: 12px;
          }
        }
      }

      .link-to-help {
        display: flex;
        font-size: 13px;
        align-items: center;
        gap: 6px;
        color: #333;
        margin-top: 50px;
      }

      .helper-text {
        font-size: 13px;
      }

      .mapping-description {
        color: #384551;
      }
    }
  }
}

.radio-wrapper {
  input[type="radio"] {
    display: none;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    cursor: pointer;
    color: #333;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    max-width: 260px;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    padding: 13px 20px;
    border: 1px solid#90c6fe;
    margin: 0;
    border-radius: 8px;

    img {
      margin-right: 10px;
    }
  }

  label:hover {
    background: #e5f2fd;
  }

  input[type="radio"]:checked + label {
    border: 1px solid #2c5cc5;
    box-shadow: 0 0 0 1px #2c5cc5;
    background: #e5f2fd;
  }

  input[type="radio"]:disabled + label {
    background: #f1f1f1;
    border: 1px solid #bebebe;
    cursor: default;
  }
}

// .contact-import-progress-bar {
//   display: flex;
//   flex-direction: row;
//   background: #e5f2fd;
//   position: relative;
//   width: 100%;
//   margin: 20px 0 40px 0;
//   max-width: 800px;

//   .progress-step {
//     flex: 1;
//     position: relative;
//     height: 8px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 5px;
//     margin-right: 10px;
//   }

//   .progress-step.completed::after {
//     content: "✔";
//     position: absolute;
//     top: 50%;
//     left: 0%;
//     transform: translate(5%, -50%);
//     color: white;
//   }

//   .progress-step.current {
//     background-color: #3498db;
//     color: white;
//   }

//   .step-name {
//     padding: 5px;
//     position: absolute;
//     top: -32px;
//     left: -30px;
//     white-space: nowrap;
//     font-size: 14px;
//     font-weight: 500;

//     &:nth-child(4) {
//       left: 25% !important;
//     }
//     &:nth-child(6) {
//       left: 60% !important;
//     }
//     &:nth-child(8) {
//       left: 95% !important;
//     }
//   }
// }

/* ProgressBar.css */
.progress-bar-container {
  position: relative;
  height: 50px;
}

.progress-step {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #333;
}

.progress-step.current {
  background-color: #4caf50;
  color: white;
}

.progress-step.completed {
  background-color: #4caf50;
  color: white;
}

.next-button {
  background: linear-gradient(180deg, #264966 0, #12344d 100%);
  border: 1px solid #12344d;
  border-radius: 4px;
  height: -webkit-fill-available;
  padding: 8px 12px;
  min-width: 80px;
  outline: 0;
  font-size: 13px;
  color: #fff;
  &:disabled {
    background: linear-gradient(180deg, #ced6dc 0, #8ea0ac 100%);
    border: 1px solid #b4c4cd;
  }
}

.cancel-button {
  background: linear-gradient(180deg, #fff 0, #f5f7f9 100%);
  border: 1px solid #cfd7df;
  padding: 8px 15px;
  border-radius: 4px;
  outline: 0;
  font-size: 13px;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 18px;
  color: #333;

  &:active {
    box-shadow: inset 0 0 4px 0 rgba(39, 49, 58, 0.26);
    background: #f5f7f9;
  }
}
.confirmation-message-wrapper-cancel {
  background: #fff;
  border-radius: 6px;
  max-width: 500px;
  width: 100%;
  padding: 0 !important;

  .confirmation-message-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    h3 {
      color: #333;
      font-size: 17px;
    }
    p {
      color: #333;
      font-size: 13px;
    }
  }
}
.confirmation-message-wrapper-content {
  background: #fff;
  border-radius: 6px;
  max-width: 600px;
  width: 100%;
  padding: 0 !important;

  .confirmation-message-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    h3 {
      color: #333;
      font-size: 17px;
    }
    p {
      color: #333;
      font-size: 13px;
    }
  }

  .button-wrapper {
    background-color: #f5f7f9;
    padding: 0;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: end;
    padding: 13px 20px;
    gap: 10px;
  }
}

.imported-file-details {
  max-width: 1000px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 15px 0px rgba(39, 49, 58, 0.15);
  margin-top: 30px;

  th {
    background: #ebeff3;
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 18px;
    // border: none;
    // border-radius: 10px;

    // padding: 11px 20px 11px 20px;
  }

  th:nth-child(1) {
    width: 125px;
    text-align: center;
    border-radius: 10px 0 0 0;
    padding: 12px 0 12px 0;
  }
  th:nth-child(2) {
    max-width: 125px;
    width: 100%;
    text-align: left;
    padding: 12px 0 12px 0;
  }
  th:nth-child(3) {
    width: 125px;
    text-align: center;
  }
  th:nth-child(4) {
    max-width: 125px;
    width: 100%;
    text-align: left;
  }
  th:nth-child(5) {
    width: 250px;
    width: 100%;
    border-radius: 0 10px 0 0;
    text-align: left;
  }

  tr {
    // height: 54px;
    // max-width: 250px;
    border-bottom: 1px solid #ebeff3;
    // padding-right: 20px;

    td {
      height: 64px;
    }

    td:nth-child(1) {
      text-align: center;
    }

    // td:nth-child(2) {
    //   text-align: left!important;
    // }

    td:nth-child(3) {
      text-align: center;
    }

    .imported-name-tag {
      color: #333;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      text-align: left !important;
    }
    .text-left {
      text-align: left !important;
    }

    .imported-user-name {
      color: #999;
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .import-text-field {
      color: #666;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-left: 20px;
      text-align: center;
    }
  }
}

.import-date-format {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 15px 0;

  .radio-wrapper {
    .radio-label {
      padding: 20px 30px !important;
      height: fit-content !important;
      font-size: 13px !important;
      gap: 5px !important;

      .span {
        font-size: 14px;
        color: #92a2b1;
      }
    }
  }
  div {
    display: flex;
    flex-direction: column;
  }
}

.more-settings {
  padding: 40px 80px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(39, 49, 58, 0.2);
  margin: 50px auto 80px;
  width: 520px;
  span {
    font-size: 13px;
    color: #333;
    font-weight: 500;
  }
}

.grey-border {
  border-bottom: 1px solid #ebeff3;
}

.accordion-container {
  margin-top: 30px;
  .accordion-heading {
    text-align: left !important;
    font-size: 16px;
    color: #2c5cc5 !important;
    font-weight: 500;

    &:hover {
      color: #2f80eb !important;
    }
  }

  .accordion-subheading {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #666;
  }
}
.import_error_container {
  border: 1px solid #ffd0d6;
  background-color: #ffecf0;
  border-radius: 5px;
  .text {
    color: #666666;
    text-align: left !important;
    font-size: 13px !important;
  }
}
.import_comming_soon {
  position: absolute;
  font-size: 10px !important;
  padding: 5px !important;
  right: 0px;
  background: linear-gradient(to right, #e1903f, rgba(0, 168, 134, 0));
  border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
}
