@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.ticketPendingLayout-wrapper {
  background-color: var(--white-color);
  // border-left: 1px solid var(--theme-border-color);
  // border-bottom: 1px solid var(--theme-border-color);
  // padding: 24px;
  padding: 18px;

  &:last-child {
    padding-bottom: 32px;
  }
}
