@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.showdiv {
  opacity: 1;
  visibility: visible;
  height: initial;
  max-height: 100vh;
  overflow: initial;
  margin-top: 16px;
}
.hidediv {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  max-height: 0;
}

.accordian-wrapper {
  transition: all 0.5s ease-in-out;
}
.pending-task_more_info_text {
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #1a73e8;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

//list UI
.task_more-list {
  // background-color: #f7fbff;
  background-color: white;
  // display: flex;
  height: calc(100vh - 64px);
  overflow-y: auto;
  padding: 20px;
}

.pending-task_userinformation-wrapper {
	border: 1px dashed var(--theme-border-color);
	padding: 10px;
	border-radius: $base-border-radius;
}
.pending-task_userinformation__name {
	color: var(--theme-font-terciary-color);
	font-weight: $medium-font-weight;
	margin-bottom: 4px;
	@include genearteRemFontSize(16);
}
.pending-task_userinformation__details {
	color: var(--theme-grey-color);
	max-width: 215px;
	@include genearteRemFontSize(14);
}
.pending-task_userinformation__emails {
	color: var(--theme-grey-color);
	margin: 4px 0;
	@include genearteRemFontSize(14);
	width: 100%;
}
.pending-task_userinformation__phone {
	color: var(--theme-grey-color);
	@include genearteRemFontSize(14);
	width: 100%;
}
.pending-task_userinformation__copy-image {
	height: 16px;
	width: 16px;
	margin-left: 8px;
}
