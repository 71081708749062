@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

// .tabs-padding {
//   padding: 16px 0 0 16px;
//   border-bottom: 1px solid var(--theme-border-color);
// }

// //users
// .organization-text {
//   color: var(--theme-grey-color);
//   padding: 24px;
//   @include genearteRemFontSize(16);
// }
// .manageFieldRight {
//   max-height: calc(100vh - 60px - 64px);
//   height: calc(100vh - 60px - 64px);
//   overflow-y: hidden;
//   border-left: 1px solid $theme-border-color;
// }

// /*** Non-demo CSS ***/

// .wrapper {
//   max-width: 100%;
//   // margin: 2rem auto 0;
// }

// .table_footer_container {
//   width: 100%;
//   height: 50px;
//   z-index: 1;
//   bottom: 0;
//   right: 0;
//   // background-color: #f5f8fa !important;
//   // background-color: red;
//   background-color: white;
//   overflow: hidden;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // margin-top: 10px;
//   // position: fixed;
//   position: sticky;
// }

.subheader_filter_section {
  transition: all 300ms ease;
  position: relative;
  // z-index: 1001;
}

.kanban-view-wrapper {
  display: grid;
  padding: 2rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  background: #ebeff3;
  height: 100vh;

  .kanban-container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    .kanban-header {
      border: 1px solid #cfd7df;
      border-radius: 10px;
      padding: 0.8rem;
      background: #fff;

      .header-container {
        background: #ffd6b1;
        padding: 0.2rem 0.6rem;
        border-radius: 16px;
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }

      .heading {
        color: #000000;
        font-weight: 500;
      }

      .count {
        color: #fff;
        background: #e67e22;
        padding: 0.1rem 0.5rem;
        border-radius: 0.6rem;
        font-size: 12px;
      }
    }

    .kanban-body {
      border: 1px solid #cfd7df;
      border-radius: 10px;
      padding: 0.8rem;
      background: #fff;
      margin: 0 0.5rem;

      .kanban-top-section {
        display: flex;
        gap: 0.8rem;
        align-items: center;

        div {
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #271a1a;
          border-radius: 50%;
          color: #fff;
          font-weight: 500;
        }

        p {
          font-size: 14px;
          color: #2c5cc5;
          font-weight: 500;
        }
      }

      .kanban-middle-section {
        padding: 0.5rem 0;

        span {
          font-size: 12px;
          font-weight: 500;
        }
      }

      .kanban-bottom-section {
        display: flex;
        justify-content: space-between;

        .more-options {
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.2s ease-in;
          position: relative;

          &:hover {
            cursor: pointer;
          }

          .options {
            display: none;
            position: absolute;
            top: 10px;
            left: 0;
            border: 1px solid #cfd7df;
            border-radius: 6px;
            padding: 0.6rem;
            background: #fff;
            font-size: 13px;

            .options-data {
              display: grid;
              gap: 0.4rem;
              grid-template-columns: repeat(3, 1fr);
              li {
                white-space: nowrap;
              }
            }
          }

          &:hover .options {
            display: block;
          }
        }
      }
    }
  }
}
