@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';

.switch {
	position: relative;
	display: inline-block;
	width: 34px;
	height: 10px;
}
.switch__image {
	z-index: 2;
	margin: auto;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	background-image: url(../../../../public/images/icons/dash-white.svg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	height: 18px;
	width: 18px;
	left: 0px;
	bottom: -4px;
	background-color: var(--theme-font-terciary-color);
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #93b6f8;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
	background-color: var(--theme-blue-primary-color);
	background-image: url(../../../../public/images/icons/tick-white.svg);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
//switch module
.switch {
	position: relative;
	display: inline-block;
	width: 34px;
	height: 12px;
}
.switch__image {
	z-index: 2;
	margin: auto;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	left: 0px;
	bottom: -3px;
	background-color: var(--theme-font-terciary-color);
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #93b6f8;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
	background-color: var(--theme-blue-primary-color);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
