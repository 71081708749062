@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.task-detail_container {
  display: flex;
  max-height: calc(100vh - 64px);
  overflow: hidden;
}
.task-detail_container_update_section {
  display: flex;
  max-height: calc(100vh - 64px - 56px);
  overflow: hidden;
}
