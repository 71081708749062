@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.create-header {
  position: sticky;
  top: 65px;
  background: var(--white-color);
  box-shadow: 0px 3px 6px #00000029;
  // padding: 8px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-header__cta-wrapper button {
  min-width: 100px;
}

.create-header__cta-wrapper button:first-child {
  margin-right: 8px;
}

.create-header__title {
  margin-left: 8px;
}

.create-header__cancel-button {
  padding: 5.5px;
  border-radius: 50%;
  @extend %transition;
  &:hover {
    background: transparent;
  }
}
