@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.app-category-buttons {
  border: none;
  outline: none;
  padding: 0.3rem 1rem;
  font-size: 0.8rem;
  color: var(--theme-font-heading-secondary-color);
  transition: all 0.3s ease-in-out;
  border-radius: 0.3rem;
}

.category-active {
  background: rgba(var(--primary-color), 1);
  color: #fff;
}

.app-category-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;

  .app-category-card {
    border: 1px solid #bae6ff;
    border-radius: 0.4rem;
    padding: 1rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      border: 1px solid #6cc9ff;
    }
  }
}
