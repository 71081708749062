@import "@scss/common/_variables.scss";

.popup-selected-value-box {
  line-height: 1.5;
  font-size: 1rem;
  // background-color: rgb(245, 248, 250);
  background-color: transparent;
  border: 1px solid #dadce0;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  transition: all 200ms ease;
  // width: 415px;
  height: 44px;
  border-radius: 5px;
  padding-top: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background: #fff;
  .value {
    text-align: left;
    position: absolute;
    //   top: -8px;
    color: black;
    font-size: 14px;
  }
  .inline_select_value {
    text-align: left;
    position: absolute;
    //   top: -8px;
    color: lightgray;
    font-size: 14px;
  }
  .placeholder {
    text-align: left;
    position: absolute;
    top: -8px;
    color: #dcdcdc;
  }
  .right_container {
    display: flex;
    align-items: center;
    background: transparent;
    z-index: 1;
    .arrow_image_container {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      .arrow_image {
        height: 28px;
        width: 28px;
      }
    }
    .close_image_container {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      border-radius: 50%;

      .close_image {
        // margin-right: 6px;
        height: 18px;
        width: 18px;
      }
      &:hover {
        background-color: #d4e6f1;
        border-radius: 50%;
        // transition: 0.2s ease;
      }
    }
  }

  &::placeholder {
    color: #dadce0;
  }
  &:hover {
    // background-color: #eaf0f6;
  }
}
.popup-selected-value-box__inline {
  line-height: 1.5;
  font-size: 1rem;
  background-color: transparent;
  /* border: 1px solid #dadce0; */
  border-radius: 2px;
  box-sizing: border-box;
  /* padding: 8px 10px 8px 10px; */
  transition: all 200ms ease;
  height: 38px;
  border-radius: 5px;
  padding-top: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .value {
    text-align: left;
    position: absolute;
    //   top: -8px;
    color: black;
    font-size: 14px;
  }
  .right_container {
    display: flex;
    align-items: center;
    background: #fff;
    z-index: 1;
    .arrow_image_container {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      .arrow_image {
        height: 28px;
        width: 28px;
      }
    }
    .close_image_container {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      border-radius: 50%;

      .close_image {
        // margin-right: 6px;
        height: 18px;
        width: 18px;
      }
      &:hover {
        background-color: #d4e6f1;
        border-radius: 50%;
        // transition: 0.2s ease;
      }
    }
  }
}
.popup-selected-value-box.active {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid rgba(var(--primary-color), 1);
  // background: #f2f2ff;
  // outline: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  transition: all 200ms ease;
  padding-top: 12px;
  // width: 415px;

  height: 44px;
  border-radius: 5px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  .value {
    text-align: left;
    position: absolute;
    font-size: 14px;
    //   top: -8px;
    color: black;
  }
  .inline_select_value {
    text-align: left;
    position: absolute;
    //   top: -8px;
    color: lightgray;
    font-size: 14px;
  }
  .placeholder {
    text-align: left;
    position: absolute;
    top: -8px;
    color: #dcdcdc;
  }
  &::placeholder {
    color: #dadce0;
  }
  .right_container {
    display: flex;
    align-items: center;
    background: #fff;
    z-index: 1;
    .arrow_image_container {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      .arrow_image {
        height: 28px;
        width: 28px;
      }
    }
    .close_image_container {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      border-radius: 50%;

      .close_image {
        // margin-right: 6px;
        height: 18px;
        width: 18px;
      }
      &:hover {
        background-color: #d4e6f1;
        border-radius: 50%;
        // transition: 0.2s ease;
      }
    }
  }
}
.placeholder_text {
  text-align: left;
  position: absolute;
  // top: -8px;
  color: #c3c3c3;
  font-size: 14px;
}

.dropdown-select-form .arrow.open {
  border-color: transparent transparent #dadce0;
  border-width: 0 5px 5px;
}

.dropdown-select-form input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  padding: 8px 69px 8px 10px;
  transition: all 200ms ease;
  &::placeholder {
    color: #dadce0;
  }
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  // border: 1px solid #ccc;
  // padding: 8px;
  // transition: border-color 0.3s, background-color 0.3s;

  &:focus {
    border-color: #408dfb;
    background: #f2f2ff;
    box-shadow: none;
    outline: none;
  }
}

.dropdown-select-form .popup_options.open {
  display: block;
  margin-top: 5px;
  border-radius: 5px;
}
.dropdown-select-form .popup_options {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  margin: 6px;
  .selected_text {
    color: black;
    font-size: 14px;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
.dropdown-select-form .popup_options.selected {
  // background-color: #e8f0fe;
  background-color: #f2f3ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
    font-size: 14px;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
.dropdown-select-form .popup_options:hover {
  // background-color: #e8f0fe;
  // background-color: #408dfb;
  background-color: #f1f2f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
    font-weight: 500;
    font-size: 14px;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
.loader {
  height: 40px;
}
.nodata {
  color: rgba(51, 51, 51, 0.8);
  font-weight: 400;
  text-align: center;
  padding: 10px;
}
.popup_search_container {
  position: sticky;
  top: 0;
  z-index: 100;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  .search_input {
    padding-left: 30px;
    border-radius: 3px;
  }
  .search_image {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 15px;
    left: 15px;
  }
}

.button_container {
  cursor: pointer;
  position: sticky;
  bottom: 0;
  z-index: 100;
  padding: 15px;
  // background-color: #F7FAFF;
  display: flex;
  align-items: center;
  border-top: 1px solid #efefef;
  // justify-content: center;
  .button_input {
    color: #276ef1;
    font-weight: 500;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
  .button_image {
    position: absolute;
    height: 20px;
    width: 20px;
    //   top: 12px;
    //   left: 14px;
    // left: 37%;
  }
}

.input_close_image_container {
  transition: ease 0.5s;
  .input_close_image {
    cursor: pointer;
    position: absolute;
    right: 19px;
    height: 18px;
    width: 18px;
    top: 17px;
  }
  &:hover {
    background-color: #f2f2ff;
    border-radius: 50%;
  }
}

.popup_option_container {
  // // padding: 10px;
  // overflow: auto;
  // max-height: 176px;
  height: 200px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

// ------------------- inline edit popup css -------------------//
.popup-selected-value-box_inline {
  line-height: 1.5;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  transition: all 200ms ease;
  // width: 415px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &::placeholder {
    color: #dadce0;
  }
  &:hover {
    background-color: #f2f2ff !important;
    border: 1px solid #408dfb !important;
    border-radius: 5px !important;
  }
  .inline_popup_arrow_image {
    height: 22px;
    width: 22px;
  }
}
.popup-selected-value-box_inline-options {
  width: 100%;
}
.popup-selected-value-box_inline.active {
  // line-height: 1.5;
  font-size: 1rem;
  box-sizing: border-box;
  border-bottom: 1px solid #408dfb;
  // padding: 8px 10px 8px 10px;
  transition: all 200ms ease;
  // width: 415px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &::placeholder {
    color: #dadce0;
  }
  &:hover {
    // background-color: #eaf0f6;
  }
  .inline_popup_arrow_image {
    height: 22px;
    width: 22px;
  }
  background-color: #f2f2ff !important;
  border: 1px solid #408dfb !important;
  border-radius: 5px !important;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px) !important;
    opacity: 0 !important;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0) !important;
  }
  1% {
    transform: scale(0.96) translateY(10px) !important;
    opacity: 0 !important;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0) !important;
  }
  100% {
    transform: scale(1) translateY(0px) !important;
    opacity: 1 !important;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0) !important;
  }
}
.popup-content {
  -webkit-animation: anvil 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
}

//------------ multi-select -----------------//
.multi_select-main_container {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.multi_select-text_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  background-color: #d2e6f1;
  padding: 5px;
  border-radius: 15px;
  flex-wrap: wrap;
  p {
    font-size: 13px;
  }
}

.multi_select-check_box_container {
  height: 18px;
  width: 18px;
  cursor: pointer;
  .multi_select-check_box {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.multi_select-option_text {
  font-size: 16px;
}

.dropdown-select-form .multi_select_popup_options {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  margin: 6px;
  .selected_text {
    color: black;
    font-size: 14px;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
.dropdown-select-form .multi_select_popup_options.selected {
  // background-color: #e8f0fe;
  background-color: #f2f3ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
    font-size: 14px;
  }
}

.multiselect_checkbox {
  display: none;
}

/* Style for Checkbox 1 with ID */
.multiselect_checkbox + label::before {
  /* Add your custom styles for Checkbox 1 here */
}

/* Style for Checkbox 2 with Class */
.multiselect_checkbox + label::before {
  /* Add your custom styles for Checkboxes with class "custom-checkbox" here */
}

//---------- Tooltip Text Header ---------------//

.selected-value-box {
  line-height: 1.5;
  font-size: 1rem;
  // background-color: rgb(245, 248, 250);
  background-color: transparent;
  border: 1px solid #dadce0;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  transition: all 200ms ease;
  // width: 415px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .value {
    text-align: left;
    position: absolute;
    //   top: -8px;
    color: black;
    font-size: 14px;
  }
  .placeholder {
    text-align: left;
    position: absolute;
    top: -8px;
    color: #dcdcdc;
  }
  .right_container {
    display: flex;
    align-items: center;
    background: transparent;
    z-index: 1;
    .arrow_image_container {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      .arrow_image {
        height: 28px;
        width: 28px;
      }
    }
    .close_image_container {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      border-radius: 50%;

      .close_image {
        // margin-right: 6px;
        height: 18px;
        width: 18px;
      }
      &:hover {
        background-color: #d4e6f1;
        border-radius: 50%;
        // transition: 0.2s ease;
      }
    }
  }

  &::placeholder {
    color: #dadce0;
  }
  &:hover {
    // background-color: #eaf0f6;
  }
}
.selected-value-box.active {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #408dfb;
  // background: #f2f2ff;
  // outline: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  transition: all 200ms ease;
  // width: 415px;

  height: 40px;
  border-radius: 5px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  .value {
    text-align: left;
    position: absolute;
    font-size: 14px;
    //   top: -8px;
    color: black;
  }
  .placeholder {
    text-align: left;
    position: absolute;
    top: -8px;
    color: #dcdcdc;
  }
  &::placeholder {
    color: #dadce0;
  }
}
.placeholder_text {
  text-align: left;
  position: absolute;
  // top: -8px;
  color: #c3c3c3;
  font-size: 14px;
}

.dropdown-select-form {
  position: relative;
  color: #333;
  cursor: default;
  // width: 300px;
  border-radius: 5px;
}

.dropdown-select-form .options {
  display: none;
  background-color: #fff;
  // border: 1px solid #dadce0;
  // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 275px;
  // overflow-y: auto;
  position: absolute;
  top: 100%;
  z-index: 999;
  // width: 415px;
  width: 100%;
  // padding: 7px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.dropdown-select-form .options.open {
  display: block;
  margin-top: 5px;
  border-radius: 5px;
}

.dropdown-select-form .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.dropdown-select-form .option.selected {
  // background-color: #e8f0fe;
  background-color: #f2f3ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
.dropdown-select-form .option:hover {
  // background-color: #e8f0fe;
  // background-color: #408dfb;
  background-color: #f1f2f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
    font-weight: 500;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
#sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  bottom: 0;
  border-top: 0.5px solid #efefef;
  // padding: 10px;
  color: #1a73e8;
  background-color: #e8f0fe;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  height: 40px;
}
.nodata {
  color: rgba(51, 51, 51, 0.8);
  font-weight: 400;
  text-align: center;
  padding: 10px;
}
.search_container {
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  // background-color: #fff;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  .search_input {
    padding-left: 30px;
  }
  .search_image {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 17px;
    left: 17px;
  }
}

.button_container {
  cursor: pointer;
  position: sticky;
  bottom: 0;
  z-index: 100;
  padding: 15px;
  // background-color: #F7FAFF;
  display: flex;
  align-items: center;
  border-top: 1px solid #efefef;
  // justify-content: center;
  .button_input {
    color: #276ef1;
    font-weight: 500;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
  .button_image {
    position: absolute;
    height: 20px;
    width: 20px;
    //   top: 12px;
    //   left: 14px;
    // left: 37%;
  }
}

.input_close_image_container {
  transition: ease 0.5s;
  .input_close_image {
    cursor: pointer;
    position: absolute;
    right: 19px;
    height: 18px;
    width: 18px;
    top: 17px;
  }
  &:hover {
    background-color: #f2f2ff;
    border-radius: 50%;
  }
}

.option_container {
  padding: 10px;
  overflow-y: auto;
  max-height: 176px;
}

.ant-popover-content {
  .option_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  .option_container::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }
  .option_container::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }
}

//------------------------- Filter Search ------------------------//

.selected_filter_box {
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;

  // width: 300px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  p {
    color: $theme-main-text-color;
    font-weight: 500;
    // font-family: "Lexend Deca",Helvetica,Arial,sans-serif;
    font-size: 14px;
  }
  .right_container {
    display: flex;
    align-items: center;
    background: transparent;
    z-index: 1;
    .arrow_image_container {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      .arrow_image {
        height: 24px;
        width: 24px;
      }
    }
    .close_filter_image_container {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      border-radius: 50%;

      .close_image {
        margin-right: 6px;
        height: 18px;
        width: 18px;
      }
      &:hover {
        background-color: #d4e6f1;
        border-radius: 50%;
        // transition: 0.2s ease;
      }
    }
  }
  &::placeholder {
    color: #dadce0;
  }
  &:hover {
    // background-color: #eaf0f6;
  }
  // transition: all 3s ease-in-out;
}
.selected_filter_box.active {
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  background-color: transparent;
  // width: 300px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  p {
    color: #5183a4;
    font-weight: 600;
  }
  .right_container {
    display: flex;
    align-items: center;
    background: #fff !important;
    z-index: 1;
    .arrow_image_container {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      .arrow_image {
        height: 24px;
        width: 24px;
      }
    }
    .close_image_container {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.5s;
      border-radius: 50%;

      .close_image {
        // margin-right: 6px;
        height: 18px;
        width: 18px;
      }
      &:hover {
        background-color: #d4e6f1;
        border-radius: 50%;
        // transition: 0.2s ease;
      }
    }
  }
  &::placeholder {
    color: #dadce0;
  }
  &:hover {
    // background-color: #eaf0f6;
  }
  // transition: all 100ms ease;
}
.selected_filter_box.selected {
  background-color: transparent !important;
}

.filter-select-form {
  position: relative;
  color: #333;
  cursor: default;
  // width: 300px;
  border-radius: 5px;
}
.filter-select-form .arrow.open {
  border-color: transparent transparent #dadce0;
  border-width: 0 5px 5px;
}
.filter-select-form input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  padding: 8px 69px 8px 10px;
  padding-left: 38px !important;
  transition: all 200ms ease;
  &::placeholder {
    color: #dadce0;
  }
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  // border: 1px solid #ccc;
  // padding: 8px;
  // transition: border-color 0.3s, background-color 0.3s;

  &:focus {
    border-color: #408dfb;
    background: #f2f2ff;
    box-shadow: none;
    outline: none;
  }
}
.filter-select-form .options {
  display: none;
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 275px;
  // overflow-y: auto;
  position: absolute;
  top: 100%;
  z-index: 999;
  // width: 415px;
  // padding: 7px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  // box-shadow: rgba(0, 0, 0, 0) 0px 4px 5px, rgba(0, 0, 0, 0.1) 0px -12px 30px,
  //   rgba(0, 0, 0, 0) 0px 4px 6px, rgba(0, 0, 0, 0.1) 0px 1px 1px,
  //   rgba(0, 0, 0, 0.09) 0px -1px 1px;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid rgb(203, 219, 247);
}
.filter-select-form .options:after {
  content: " ";
  position: absolute;
  left: 30px;
  top: -12px;
  border-top: none;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid rgb(203, 219, 247);
}
.filter-select-form .options.open {
  display: block;
  margin-top: 5px;
  border-radius: 5px;
}
.filter-select-form .option {
  box-sizing: border-box;
  color: #33475b;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 14px;
  .option_desc {
    font-size: 12px;
    color: #516f90 !important;
  }
}
.filter-select-form .option.selected {
  // background-color: #e8f0fe;
  background-color: #f2f3ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}
.filter-select-form .option:hover {
  background-color: #f1f2f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected_text {
    color: black;
    font-weight: 500;
  }
  .done_image {
    height: 18px;
    width: 18px;
  }
}

.hexagon_multi {
  min-width: 80px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #dadce0;
  margin-bottom: 5px;
  // padding: 6px 6px;
  padding: 3px;
  padding-left: 10px;
  .close_icon {
    right: 3px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1px;
    transition: all 500ms ease;
    &:hover {
      background-color: white;
      border-radius: 50%;
    }
  }
}

.team-selection-button {
  background: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  color: #17b169;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px dashed #408dfb !important;
  }
}
