@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.module-wrapper {
  // overflow: auto;
  // max-height: calc(100vh - 120px);
  // height: calc(100vh - 120px);
}
.module-list_wrapper {
  overflow: auto;
  max-height: calc(100vh - 178px);
  // height: calc(100vh - 170px);
}
.serviceSettings-wrapper {
  padding: 16px 18px;
  width: 100%;
}
.serviceSettings-wrapper__title {
  color: var(--theme-font-heading-secondary-color);
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(16);
  margin: 16px 0 8px;
}
.serviceSettings-wrapper__email {
  border: 1px solid $theme-border-color;
  width: 100%;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: $base-border-radius;
}
.serviceSettings-wrapper__text {
  color: var(--theme-grey-color);
  @include genearteRemFontSize(14);
}

.module-tab-wrapper {
  padding: 24px 24px 0;
}
.module-tab-wrapper__filter {
  border: 1px solid #f7f7f7;
  padding: 9px 24px;
  border-radius: $base-border-radius;
  margin-top: 24px;
}
.module-tab-wrapper__text {
  color: var(--theme-grey-color);
  @include genearteRemFontSize(14);
}

//modules and fields

.admin-wrapper {
  margin: 0 16px;
}
.admin-wrapper__table {
  border: 1px solid #efefef;
  thead {
    td {
      padding: 12px 0px 12px 0px;
      // border-bottom: 1px solid #efefef;
      background-color: #f7fbff;
    }
    td {
      color: var(--theme-font-heading-primary-color);
      @include genearteRemFontSize(16);
      font-weight: $medium-font-weight;
    }
    td:first-child {
      padding-left: 20px;
    }
  }

  td {
    color: var(--theme-font-terciary-color);
    font-weight: $medium-font-weight;
    @include genearteRemFontSize(16);
    padding: 12px 0px 12px 32px;
    //   border-bottom: 1px solid #f7f7f7;
    //   border-right: 1px solid #efefef;
  }

  td:first-child {
    padding-left: 20px;
    width: 60px;
  }
  tbody tr {
    @extend %transition;
  }
  tbody tr:hover {
    background-color: rgba(var(--primary-color), 0.06);
    cursor: pointer;
    td {
      border-color: var(--white-color);
    }
  }
}
.admin-wrapper__icon-image {
  height: 32px;
  width: 32px;
}
span.dot {
  height: 5px;
  width: 5px;
  background-color: #f7f7f7;
  border-radius: 50%;
  display: block;
  margin-right: 4px;
}
.admin-wrapper__drag-icon {
  margin-right: 12px;
  display: flex;
  cursor: pointer;
  &:hover {
    span.dot {
      background-color: $theme-border-color;
    }
  }
}
.dot-margin {
  margin: 3px 0;
}

.popup-container {
  min-height: 30vh;
  // margin: 20px;
}
.popup-container_stage {
  min-height: 30vh;
  // margin: 20px;
}

.edit-info-popup-container {
  min-height: 150px;
}
