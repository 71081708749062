.solutions-wrapper {
  padding: 2rem;
  overflow-y: auto;
  height: calc(100vh - 188px);
  background: #f5f7f9;

  .solutions-card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    .solutions-card-container {
      padding: 24px;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;
      background: #fff;

      .solution-edit-dropdown {
        display: none;
      }

      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .solution-edit-dropdown {
          display: block;
        }
      }

      .folder-card {
        border-radius: 4px;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: #f5f7f9;
        }
      }
    }
  }
}

.solutions-accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
}

.article-content-viewer {
  overflow-y: auto;
  padding: 24px 0;
  border-radius: 4px;
  // height: calc(100vh - 124px);
  // min-height: 300px;
  padding-bottom: 280px;

  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }

  .ce-toolbar__content {
    max-width: 82% !important;
  }
  .ce-block__content {
    max-width: 80% !important;
  }
}

.content-inputs-wrapper {
  width: 82%;
  margin: 20px auto 0;
}

.article-heading-wrapper {
  position: relative;
  transition: all 0.3s ease-in-out;

  .article-heading-input,
  .article-description-input {
    border: none;
    width: 100%;
    margin: 0 auto;

    &:hover,
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  .article-tags-wrapper {
    display: none;
  }

  &:hover {
    .article-tags-wrapper {
      display: block;
      position: absolute;
      top: -12px;
      left: 8px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
}

.article-heading-input {
  font-size: 40px;
  font-weight: 500;
}

.article-nav-buttons-wrapper {
  display: grid;
  gap: 24px;
  width: 82%;
  margin: 20px auto;

  .article-nav-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: 1px solid #dee2e6a6;
    padding: 16px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border: 1px solid #fff;
    }
  }
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.solution-folder-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 8px 16px;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;

  .more-options {
    display: none;
  }

  &:hover {
    background: #ececec;

    .more-options {
      display: flex;
    }
  }
}

.solution-folder-card--selected {
  background: var(--theme-button-primary-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:hover {
    background: var(--theme-button-primary-color);
  }
}

.solution-folder-card__articles {
  margin: 8px 16px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}
.solution-folder-card__articles_selected {
  // background: rgba(var(--theme-button-rgba-primary-color), 0.1);
}

.folder-articles,
.folder-articles--selected {
  transition: all 0.3s ease-in-out;
  padding: 6px 16px;
  margin-left: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  min-height: 30px;
  font-size: 14px;
  // border-radius: 24px;
  // border-left: 1px solid #000;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px; /* Thickness of the line */
    height: 100%; /* Line spans the full height */
    background-color: #18181b1a; /* Line color */
  }

  .article-checkbox {
    min-width: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .more-options {
    display: none;
  }

  &:hover {
    // color: rgba(var(--theme-button-rgba-primary-color), 1);
    // background-color: rgba(0, 0, 0, 0.06);
    color: #000;

    .more-options {
      display: flex;
    }
  }
}

.folder-articles--selected {
  // background: #d4e4fc ;
  color: #000;
  font-weight: 500;

  // &:hover{
  //   background: #d4e4fc;
  // }

  &::before {
    width: 2px;
    background-color: #10b981; /* Line color */
  }
}

.add-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
  min-height: 36px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
    color: #000;
  }
}

.articles-leftside {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.solutions-main-wrapper {
  font-family: "SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui,
    BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
    "Kohinoor Devanagari", sans-serif;
}

.category-details-leftside {
  width: 360px;
  min-width: 360px;
  height: calc(100vh - 104px);
  overflow: hidden;
  background: #f6f8fc;
}

.category-creation-input {
  display: flex;
  align-items: center;
  gap: 6px;
}

.category-header {
  .more-options {
    display: none;
  }

  &:hover {
    .more-options {
      display: flex;
    }
  }
}

.content-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
}

.editor-scrollable {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.category-inifinte-scroller {
  height: calc(100vh - 168px) !important;
  overflow: hidden !important;
}

.category-top-container {
  height: calc(100vh - 168px);
  overflow-y: auto;
}

.category-bottom-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  width: 360px;
  min-width: 360px;
  padding: 4px;
}
