.backend-template-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  height: calc(100vh - 306px);
  overflow-y: auto;

  .backend-template-container {
    width: 100%;
    background: #edf2f7 !important;
    border-radius: 8px;
    border: 2px solid #d9d9d9;
    height: fit-content;

    .template-message-container {
      height: 260px;
      overflow-y: auto;
    }
  }
}
