.hover-component {
  display: flex;
  justify-content: center;
  cursor: pointer;
  .checkbox {
    display: none;
  }
  .image-container {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #E8F0FE;
    justify-content: center;
    display: flex;
    align-items: center;
    .header-text {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &:hover {
    .checkbox {
      display: block;
      height: 30px;
      width: 30px;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .image-container {
      display: none;
    }
    object-fit: cover;
  }
}

.hover-component-checked {
  display: flex;
  justify-content: center;
  cursor: pointer;
  .checkbox {
    display: block;
    height: 30px;
    width: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .image-container {
    display: none;
  }
}
