.phone-settings {
    padding: 20px;
  
    .phone-card {
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  
    .phone-header {
      margin-bottom: 24px;
    }
  
    .phone-info {
      display: flex;
      align-items: flex-start;
      gap: 16px;
    }
  
    .phone-icon {
      background-color: #f0f2f5;
      padding: 12px;
      border-radius: 8px;
    }
  
    .phone-text {
      h2 {
        margin: 0 0 8px;
        font-size: 20px;
        font-weight: 600;
      }
  
      p {
        margin: 0 0 8px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  
    .native-telephony {
      h3 {
        margin: 0 0 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  
    .freshcaller-section {
      background-color: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .provider-info {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  
    .provider-icon {
      background-color: #e6f7ff;
      padding: 12px;
      border-radius: 8px;
    }
  
    .provider-text {
      h4 {
        margin: 0 0 4px;
        font-size: 16px;
        font-weight: 500;
      }
  
      p {
        margin: 0;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  
  .phone-settings-form {
    .error-message {
      color: #ff4d4f;
      font-size: 12px;
      margin-top: 4px;
    }
  
    .ant-form-item {
      margin-bottom: 16px;
    }
  }