.generate_whatspp-main_wrapper {
  width: 100%;
  height: calc(100vh - 65px);
  overflow-y: auto;

  .generate_whatspp-main_container {
    width: 80%;
    margin: 0 auto;

    .account-circle {
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 50%;
      padding: 0;
      margin: 0;
      object-fit: none;
    }

    .generate_whatspp-header_container {
      background-color: #efefef;
      margin: 0 auto;
      margin-top: 5vh;
      padding: 2rem;
      border-radius: 5px;
      .header {
        font-size: 20px;
        color: #4dc247;
      }
      .desc {
        margin-top: 2vh;
        font-size: 14px;
        color: #6e6e6e;
      }
    }
    .generate_whatspp-phone_container {
      .header {
        font-size: 16px;
        color: rgb(32, 30, 30);
      }
      .desc {
        margin-top: 1vh;
        font-size: 14px;
        color: #6e6e6e;
      }
    }
    .generate_whatspp-message_container {
      .header {
        font-size: 16px;
        color: rgb(32, 30, 30);
      }
      .desc {
        margin-top: 1vh;
        font-size: 14px;
        color: #6e6e6e;
      }
    }
  }
}
