.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  max-width: 1200px;
  margin: 0 auto;

  .logo {
    font-size: 24px;
    font-weight: bold;
  }

  .compare-btn {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 16px;

    .anticon {
      font-size: 18px;
    }
  }
}
.chat-container {
  // overflow: auto;
  // height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 230px);
  // background-image: radial-gradient(
  //   circle at center,
  //   rgb(198, 198, 198) 1px,
  //   transparent 1px
  // );
  background-size: 14px 14px;
  // border: 1px solid #efefef;
  // background-color: #fafafa;
  margin-left: 100px;
  margin-right: 100px;
  // padding: 24px 0;

  iframe {
    height: 100% !important;
    border: 1px solid #efefef;
    border-radius: 8px;
    margin-top: 48px;
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-width: 1200px;
    margin: 0 auto;

    .logo {
      font-size: 24px;
      font-weight: bold;
    }

    .compare-btn {
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 16px;

      .anticon {
        font-size: 18px;
      }
    }
  }

  .chat-window {
    max-width: 450px;
    margin: 20px auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;

    .chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
      font-size: 16px;
    }

    .messages {
      flex: 1;
      padding: 20px 0;

      .message {
        background: #f5f5f5;
        padding: 12px 16px;
        border-radius: 16px;
        max-width: 80%;
        margin-bottom: 12px;
        display: inline-block;
      }
    }

    .input-area {
      padding: 20px 0;
      border-top: 1px solid #eee;

      .message-input {
        border-radius: 8px;
        padding: 8px 12px;

        .anticon {
          color: #1890ff;
          cursor: pointer;
        }
      }
    }

    .powered-by {
      text-align: center;
      color: #666;
      font-size: 14px;
      margin-top: 12px;
    }
  }
}
