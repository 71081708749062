@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.add-more-rolls-wrapper {
  position: relative;
  z-index: 2;
  overflow-y: auto;
  height: calc(100vh - 146px);
  max-height: calc(100vh - 146px);
  padding: 10px;
  // padding-bottom: 70px;
}
.task_wrapper {
  position: relative;
  z-index: 2;
  overflow-y: auto;
  height: calc(100vh - 146px);
  max-height: calc(100vh - 146px);
  padding: 10px;
  // padding-bottom: 70px;
}
.subpipeline-wrapper {
  position: relative;
  z-index: 2;
  overflow-y: auto;
  // height: calc(100vh - 107px);
  height: calc(100vh - 146px);
  max-height: calc(100vh - 146px);
  padding: 10px;
  // padding-bottom: 70px;
}
.subpipeline-wrapper_no_footer {
  position: relative;
  z-index: 2;
  overflow-y: auto;
  // height: calc(100vh - 107px);
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  padding: 10px;
  // padding-bottom: 70px;
}
.add-more-rolls-wrapper__header {
  background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
    no-repeat padding-box;
  padding: 20px 24px;
}
.add-more-rolls-wrapper__header-title {
  color: var(--white-color);
  font-weight: $normal-font-weight;
  @include genearteRemFontSize(20);
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease-in;

  .header-edit-option {
    transition: all 0.2s ease-in-out;
    width: 30px;
    height: 30px;
    background: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #ffffff54;
    }
  }

  // &:hover{
  //   cursor: pointer;
  //   .header-edit-option{
  //     display: block;
  //   }
  // }
}
.add-more-rolls-wrapper__sub-title {
  padding: 16px 24px;
  color: var(--theme-font-heading-secondary-color);
  @include genearteRemFontSize(14);
  box-shadow: 4px 4px 10px #00000029;
  background-color: var(--white-color);
}
.add-more-rolls-wrapper__rolls-list {
  padding: 16.5px 0;
}
.add-more-rolls-wrapper__rolls-wrapper {
  padding: 0 24px;
}
.add-more-rolls-wrapper__rolls-list-item {
  margin-bottom: 16px;
}
.add-more-rolls-wrapper__rolls-list-title {
  color: var(--theme-font-heading-secondary-color);
  margin-bottom: 4px;
  @include genearteRemFontSize(16);
  font-weight: $normal-font-weight;
}
.add-more-rolls-wrapper__rolls-list-text {
  color: var(--theme-grey-color);
  @include genearteRemFontSize(14);
  font-weight: $light-font-weight;
}
.add-more-rolls-wrapper__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 8px 24px;
  background-color: #f7fbff;
  width: 100%;
}
.add-more-rolls-wrapper__close-btn {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  background-color: transparent;
  &:hover {
    background-color: #248fbd;
  }
}
.add-more-rolls-wrapper__close-icon {
  margin: auto;
  height: 22px;
  width: 22px;
}
.form-wrapper {
  width: 65%;
  margin: 0 auto;
}
.add-employee-wrapper__footer {
  // margin-top: 30px;
  // margin-bottom: 30px;
  // margin-right: 110px;
}
.edit_header {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  // position: absolute;
  color: $theme-main-text-color;
  font-size: 16px;
  width: 100%;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.association_container {
  width: 85%;
  margin: 0 auto;
  position: relative;
}
.product-management-antd {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .controls-section {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-details {
    .product-name {
      margin-bottom: 8px;
    }

    .details-divider {
      margin: 12px 0;
    }

    .product-info {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .info-item {
        display: flex;
        flex-direction: column;
        min-width: 60px;
      }
    }
  }

  // Override some antd styles
  .ant-table-wrapper {
    .ant-table {
      .ant-table-thead > tr > th {
        background-color: #fafafa;
      }

      .ant-table-summary {
        .ant-table-cell {
          background-color: #fafafa;

          &:first-child {
            text-align: right;
          }
        }
      }
    }
  }

  // Make inputs more compact
  .ant-input-number {
    width: 100%;
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    padding: 16px;

    .controls-section {
      flex-direction: column;
      gap: 16px;
      align-items: stretch;

      .ant-input-affix-wrapper {
        width: 100%;
      }

      .ant-btn {
        width: 100%;
      }
    }

    .product-info {
      flex-direction: column;
    }
  }
}
