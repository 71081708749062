@import "@scss/common/_variables.scss";
@import "@scss/common/_extends.scss";

.slide-from-right-popup {
  position: fixed;
  top: 0;
  height: 100vh;
  max-width: calc(100% - 250px);
  right: 0;
  background-color: var(--white-color);
  z-index: 1000;
  transform: translateX(120%);
  visibility: hidden;
  transition: all 300ms ease-in-out;
  // @extend %transition;
  // border-left: 1px solid var(--theme-border-color);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  max-height: 100vh;
}

.slide-from-right-popup--active {
  visibility: visible;
  transform: translateX(0);
}
.slide-from-right-popup--rolls-popup {
  // max-width: 455px;
  width: 640px;
}

/* Add this to your existing CSS file */
body.popup-visible {
  overflow: hidden; /* Prevent scrolling of the body content */
  pointer-events: none; /* Disable pointer events for the body */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* The desired overlay background color and opacity */
  z-index: 1100; /* Higher z-index to cover the entire body */
  pointer-events: all; /* Enable pointer events for the overlay so it captures clicks */
}
