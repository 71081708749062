
.company-profile-details-wrapper__main-section-wrapper {
	// max-width: calc(100% - 257px);
	width: 100%;
	// max-height: calc(100vh - 116px);
	// height: calc(100vh - 116px);
	max-height: calc(100vh - 120px);	
	height: calc(100vh - 120px);
	overflow: auto !important;
}
.company-profile-details-wrapper__main-section {
	// max-width: 753px;
	// margin: 24px auto;
}
