.automations-card-wrapper {
  display: grid;
  gap: 1rem;

  .automations-card {
    transition: all 0.3s ease-in-out;
    gap: 1rem;
    border-radius: 8px;
    border: 1px solid #dee2e6;
    transition: all 0.3s ease-in-out;

    .more-options {
      display: none;
    }

    &:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .more-options {
        display: block;
      }
    }

    .card-bottom-section {
      padding: 1rem 1.5rem;
      border-radius: 0 0 8px 8px;
      background-color: #f5f7f9;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}

.summary-conditions {
  div {
    display: inline;
  }

  .summarykey,
  .summaryvalue {
    font-weight: 500;
  }

  .summaryvalue {
    color: #007958;
  }
}

.subject-placeholder-button {
  position: absolute;
  right: 10px;
  bottom: 14px;
  cursor: pointer;
}

.automation-email-editor {
  background: #fff;
  padding: 12px 24px;
  min-height: 300px;
}

.automation-template-card-wrapper {
  background: #f5f7f9;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;

  .automation-template-card-header {
    padding: 0.5rem 1rem;
    background: #fff;
    border-radius: 8px 8px 0 0;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-timeline-item-head {
    background: inherit !important;
  }

  .ant-timeline-item-last {
    padding-bottom: 0 !important;
  }
}
