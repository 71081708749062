.react-tagsinput {
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  border: none;
  &__focused {
    border: #dadce0;
  }
  width: 50vw;
}
.react-tagsinput-tag {
  background-color: #e7f2ff;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #434d5f;
  font-size: 14px;
  font-weight: 400;
  padding: 7px;
  border-radius: 7px;
}
.react-tagsinput-remove {
  font-size: 18px;
  cursor: pointer;
  color: #434d5f;
  padding: 5px;
  &:hover {
    color: rgb(222, 91, 91);
  }
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #363636;
  font-size: 14px;
  font-weight: 400;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: auto;
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.tag {
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0.5em;
  background-color: #007bff;
  color: #fff;
  border-radius: 3px;
}

.email_labels {
  color: #999;
  font-size: 13px;
  // margin-bottom: 4px;
}


.email-checkbox {
  display: flex;
  font-size: 13px;
  font-weight: 400 !important;
  letter-spacing: 0;
  line-height: 20px;
  color: #333;
  margin-left: 10px;

  .checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    margin-top: 0 !important;

    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin-top: 2px;
      font: inherit;
      color: #ffffff;
      width: 19px;
      height: 19px;
      border: 0.15em solid #dadada;
      border-radius: 4px;
      transform: translateY(-0.075em);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
    }

    input[type="checkbox"]:disabled {
      /* Add styles for the disabled state */
      background-color: #f2f2f2;
      border-color: #d9d9d9;
      color: #999999;
      cursor: not-allowed;
    }

    input[type="checkbox"]::before {
      content: "\2714";
      width: 15px;
      height: 15px;
      transform: scale(0);
      margin: 0;
      font-size: 10px;
      border-radius: 2px;
      vertical-align: middle;
      line-height: 1.6;
      display: inline-block;
      padding-left: 3px;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1.2);
      background: #2c5cc5;
    }

    input[type="checkbox"]:disabled:checked::before {
      /* Add styles for the disabled and checked state */
      background: #999999;
    }
  }
}



.header_text__14_new {
  color: #999 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-bottom: 4px;
}