.profile_circle_section {
  height: 150px !important;
  width: 150px !important;
  border-radius: 50%;
  background-color: #ff9932 !important;
  h6 {
    color: white;
    font-size: 40px;
  }
}
.profile-form-container {
  width: 70%;
  margin: 0 auto;
}
.theme-color-box {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
}

.theme-color-box-selected {
  opacity: 0.5;

  // ::after {
  //   content: "✔";
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   color: white;
  //   z-index: 1;
  //   font-size: 12px;
  // }
}
